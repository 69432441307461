import React, { useState } from 'react';
import {
  Shield,
  Clock,
  AlertCircle,
  Settings,
  Mail,
  MessageSquare,
  CalendarCheck,
  User,
  FileText,
  CheckCircle,
  XCircle,
  Edit2,
  Plus,
  ToggleLeft,
  Link,
  Key,
  Database,
  Save,
  RefreshCw
} from 'lucide-react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent
} from '../components/ui/card';

const InsuranceVerificationSettings = () => {
  const [verificationProvider, setVerificationProvider] = useState({
    provider: 'availity',
    environment: 'test',
    status: 'configured',
    lastSync: '2024-04-23 14:30:00'
  });

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Automation Rules */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <Settings className="mr-2" size={20} />
            Verification Automation Settings
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Trigger Settings */}
            <div className="space-y-4">
              <h3 className="font-medium mb-2">Verification Triggers</h3>
              <div className="space-y-2">
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">New Appointment Scheduled</span>
                    <p className="text-sm text-gray-600">Verify when appointment is booked</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Regular Re-verification</span>
                    <p className="text-sm text-gray-600">Re-verify active patients monthly</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">New Calendar Year</span>
                    <p className="text-sm text-gray-600">Verify all active patients in January</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Insurance Card Update</span>
                    <p className="text-sm text-gray-600">Verify when new card is uploaded</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
              </div>
            </div>

            {/* Timing Settings */}
            <div className="space-y-4">
              <h3 className="font-medium mb-2">Verification Timing</h3>
              <div className="space-y-2">
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <span>Days Before Appointment</span>
                  <select className="border rounded p-1">
                    <option>7 days</option>
                    <option>5 days</option>
                    <option>3 days</option>
                    <option>1 day</option>
                  </select>
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <span>Re-verification Frequency</span>
                  <select className="border rounded p-1">
                    <option>Monthly</option>
                    <option>Quarterly</option>
                    <option>Bi-annually</option>
                    <option>Annually</option>
                  </select>
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <span>Verification Window</span>
                  <select className="border rounded p-1">
                    <option>Business Hours</option>
                    <option>24/7</option>
                    <option>Custom</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Response Actions */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <CheckCircle className="mr-2" size={20} />
            Verification Response Actions
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Verified Insurance Actions */}
            <div className="space-y-4">
              <h3 className="font-medium mb-2">When Insurance Verified</h3>
              <div className="space-y-2">
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Send Patient Notification</span>
                    <p className="text-sm text-gray-600">Coverage and copay details</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Update Calendar Status</span>
                    <p className="text-sm text-gray-600">Mark appointment as verified</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Generate Verification Document</span>
                    <p className="text-sm text-gray-600">Save to patient record</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
              </div>
            </div>

            {/* Failed Verification Actions */}
            <div className="space-y-4">
              <h3 className="font-medium mb-2">When Verification Fails</h3>
              <div className="space-y-2">
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Create Staff Alert</span>
                    <p className="text-sm text-gray-600">Notify for manual follow-up</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Contact Patient</span>
                    <p className="text-sm text-gray-600">Request updated information</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
                <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">Flag Appointment</span>
                    <p className="text-sm text-gray-600">Mark for financial counseling</p>
                  </div>
                  <ToggleLeft className="text-blue-600" size={20} />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Communication Templates */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <Mail className="mr-2" size={20} />
            Verification Communication Templates
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="font-medium">Patient Communications</h3>
              <button className="text-blue-600 hover:text-blue-700 flex items-center">
                <Plus size={16} className="mr-1" />
                Add Template
              </button>
            </div>
            <div className="space-y-2">
              <div className="border rounded p-4">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-medium">Verification Success</h4>
                  <button className="text-blue-600 hover:text-blue-700">
                    <Edit2 size={16} />
                  </button>
                </div>
                <p className="text-sm text-gray-600">Sent when insurance is successfully verified</p>
                <div className="mt-2 text-sm">
                  <span className="text-blue-600">Variables:</span> {'{PatientName}'}, {'{AppointmentDate}'}, {'{CopayAmount}'}
                </div>
              </div>
              <div className="border rounded p-4">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-medium">Verification Failed</h4>
                  <button className="text-blue-600 hover:text-blue-700">
                    <Edit2 size={16} />
                  </button>
                </div>
                <p className="text-sm text-gray-600">Sent when verification fails or coverage issues found</p>
                <div className="mt-2 text-sm">
                  <span className="text-blue-600">Variables:</span> {'{PatientName}'}, {'{VerificationIssue}'}, {'{NextSteps}'}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Required Information */}
      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <FileText className="mr-2" size={20} />
            Required Verification Information
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-medium mb-2">Minimum Required Fields</h3>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" checked readOnly />
                    <span>Member ID</span>
                  </div>
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" checked readOnly />
                    <span>Group Number</span>
                  </div>
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" checked readOnly />
                    <span>Insurance Card Images</span>
                  </div>
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Subscriber Date of Birth</span>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="font-medium mb-2">Service-Specific Requirements</h3>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Prior Authorization</span>
                  </div>
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Referral Information</span>
                  </div>
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <span>Secondary Insurance</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Verification Provider Configuration */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <Link className="mr-2" size={20} />
            Verification Provider Configuration
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Provider Selection */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <h3 className="font-medium mb-2">Provider Settings</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">Select Provider</label>
                    <select className="w-full border rounded p-2" defaultValue="availity">
                      <option value="availity">Availity</option>
                      <option value="other" disabled>Other Providers (Coming Soon)</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Environment</label>
                    <select className="w-full border rounded p-2">
                      <option value="test">Test</option>
                      <option value="production">Production</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <h3 className="font-medium mb-2">Connection Status</h3>
                <div className="bg-green-50 border border-green-200 rounded p-4">
                  <div className="flex items-center mb-2">
                    <CheckCircle className="text-green-500 mr-2" size={20} />
                    <span className="font-medium text-green-700">Connected to Availity</span>
                  </div>
                  <p className="text-sm text-green-600">Last sync: {verificationProvider.lastSync}</p>
                </div>
              </div>
            </div>

            {/* Availity Specific Configuration */}
            <div className="border-t pt-6">
              <h3 className="font-medium mb-4">Availity Configuration</h3>
              {/* Credentials */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">API Key</label>
                    <div className="flex">
                      <input 
                        type="password" 
                        className="flex-1 border rounded-l p-2" 
                        value="••••••••••••••••"
                        readOnly
                      />
                      <button className="bg-blue-600 text-white px-4 rounded-r hover:bg-blue-700">
                        Update
                      </button>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Organization ID</label>
                    <input 
                      type="text" 
                      className="w-full border rounded p-2"
                      placeholder="Enter Availity Organization ID"
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">Provider NPI</label>
                    <input 
                      type="text" 
                      className="w-full border rounded p-2"
                      placeholder="Enter Provider NPI"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Provider Tax ID</label>
                    <input 
                      type="text" 
                      className="w-full border rounded p-2"
                      placeholder="Enter Provider Tax ID"
                    />
                  </div>
                </div>
              </div>

              {/* API Settings */}
              <div className="space-y-4">
                <h4 className="font-medium">API Configuration</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                    <div>
                      <span className="font-medium">Batch Processing</span>
                      <p className="text-sm text-gray-600">Enable bulk verification requests</p>
                    </div>
                    <ToggleLeft className="text-blue-600" size={20} />
                  </div>
                  <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                    <div>
                      <span className="font-medium">Real-time Verification</span>
                      <p className="text-sm text-gray-600">Enable instant verification checks</p>
                    </div>
                    <ToggleLeft className="text-blue-600" size={20} />
                  </div>
                  <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                    <div>
                      <span className="font-medium">Automatic Retry</span>
                      <p className="text-sm text-gray-600">Retry failed verifications</p>
                    </div>
                    <ToggleLeft className="text-blue-600" size={20} />
                  </div>
                  <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                    <div>
                      <span className="font-medium">Extended Response Data</span>
                      <p className="text-sm text-gray-600">Store detailed verification data</p>
                    </div>
                    <ToggleLeft className="text-blue-600" size={20} />
                  </div>
                </div>
              </div>

              {/* Testing Tools */}
              <div className="mt-6 p-4 bg-gray-50 rounded">
                <h4 className="font-medium mb-4">Connection Testing</h4>
                <div className="space-x-4">
                  <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center">
                    <RefreshCw size={16} className="mr-2" />
                    Test Connection
                  </button>
                  <button className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 flex items-center inline-flex">
                    <FileText size={16} className="mr-2" />
                    View API Logs
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default InsuranceVerificationSettings;
