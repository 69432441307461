import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { FileText, Book, ClipboardList } from "lucide-react";
import { Link } from 'react-router-dom';
import DashboardTile from './DashboardTile';
import { Mail, Settings, LineChart } from "lucide-react";

export const LibrariesTile = () => (
  <DashboardTile title="Libraries">
    <div className="space-y-2">
      <Link to="/forms-library" className="text-sm text-blue-500 hover:underline flex items-center">
        <FileText className="h-4 w-4 mr-2" />
        Forms
      </Link>
      <Link to="/email-templates" className="text-sm text-blue-500 hover:underline flex items-center">
        <Mail className="h-4 w-4 mr-2" />
        Email Templates
      </Link>
      <Link to="/services" className="text-sm text-blue-500 hover:underline flex items-center">
        <Settings className="h-4 w-4 mr-2" />
        Services
      </Link>
      <Link to="/measures" className="text-sm text-blue-500 hover:underline flex items-center">
        <LineChart className="h-4 w-4 mr-2" />
        Measures
      </Link>
    </div>
  </DashboardTile>
);