import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Avatar, AvatarFallback } from "../components/ui/avatar";
import { ScrollArea } from "../components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { 
  Send, 
  Paperclip, 
  File, 
  FileText, 
  CreditCard, 
  MessageSquare,
  User,
  Building2,
  ChevronDown,
  Search
} from 'lucide-react';

// Mock data
const patients = [
  { id: 1, name: 'John Smith', avatar: '/avatars/john.jpg', lastMessage: '2 days ago' },
  { id: 2, name: 'Sarah Johnson', avatar: '/avatars/sarah.jpg', lastMessage: '1 week ago' },
  { id: 3, name: 'Mike Williams', avatar: '/avatars/mike.jpg', lastMessage: 'Just now' },
];

const stakeholders = [
  { id: 1, name: 'Dr. Jane Wilson', role: 'Psychiatrist', avatar: '/avatars/jane.jpg' },
  { id: 2, name: 'Bob Taylor', role: 'Social Worker', avatar: '/avatars/bob.jpg' },
  { id: 3, name: 'Lisa Brown', role: 'School Counselor', avatar: '/avatars/lisa.jpg' },
];

const messageTemplates = [
  { id: 1, title: 'Appointment Reminder', content: 'This is a reminder of your upcoming appointment...' },
  { id: 2, title: 'Form Request', content: 'Please complete the following forms...' },
  { id: 3, title: 'Payment Due', content: 'This is a reminder that payment is due...' },
];

const messageHistory = [
  {
    id: 1,
    patientId: 1,
    patientName: 'John Smith',
    avatar: '/avatars/john.jpg',
    messages: [
      {
        id: 1,
        sender: 'Dr Barnes',
        content: 'Hi John, please complete the intake forms I sent.',
        timestamp: '2024-03-15 10:30',
        attachments: ['Intake Form.pdf']
      },
      {
        id: 2,
        sender: 'John Smith',
        content: 'I will complete them today.',
        timestamp: '2024-03-15 11:00'
      }
    ]
  },
  // Add more message threads...
];

const messageCategories = {
  inbox: {
    unread: [
      {
        id: 'm1',
        from: 'John Smith',
        subject: 'Question about medication',
        preview: 'I wanted to ask about the side effects...',
        timestamp: '2024-03-18 09:30',
        priority: 'high',
        status: 'unread',
        assignedTo: null
      },
      // More unread messages...
    ],
    read: [
      {
        id: 'm2',
        from: 'Sarah Johnson',
        subject: 'Appointment confirmation',
        preview: 'Thank you for scheduling...',
        timestamp: '2024-03-17 14:20',
        priority: 'normal',
        status: 'read',
        assignedTo: 'Office'
      },
      // More read messages...
    ]
  },
  sent: [
    {
      id: 'm3',
      to: 'Mike Williams',
      subject: 'Your test results',
      preview: 'Ive reviewed your recent test results...',
      timestamp: '2024-03-16 11:45',
      status: 'delivered'
    },
    // More sent messages...
  ],
  drafts: [
    {
      id: 'm4',
      to: 'Lisa Brown',
      subject: 'Treatment plan update',
      preview: 'Based on our last session...',
      timestamp: '2024-03-15 16:00',
      status: 'draft'
    }
  ],
  assigned: [
    {
      id: 'm5',
      from: 'Bob Taylor',
      subject: 'Insurance question',
      preview: 'Need clarification about coverage...',
      timestamp: '2024-03-14 10:15',
      assignedTo: 'Office',
      assignedBy: 'Dr Barnes',
      status: 'pending'
    }
  ]
};

// Add this component before MessageHistory
const MessageListItem = ({ message, onSelect }) => {
  const getPriorityIndicator = () => {
    if (message.priority === 'high') {
      return <span className="w-2 h-2 bg-red-500 rounded-full absolute -left-1" />;
    }
    return null;
  };

  return (
    <div
      onClick={() => onSelect(message)}
      className="relative flex items-start space-x-3 p-2 hover:bg-gray-100 rounded-lg cursor-pointer"
    >
      {getPriorityIndicator()}
      <Avatar className="h-8 w-8 mt-1">
        <AvatarFallback>
          {message.from ? message.from.charAt(0) : message.to.charAt(0)}
        </AvatarFallback>
      </Avatar>
      <div className="flex-1 min-w-0">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-gray-900">
            {message.from || message.to}
          </p>
          <p className="text-xs text-gray-500">
            {message.timestamp}
          </p>
        </div>
        <p className="text-sm font-medium text-gray-900 truncate">
          {message.subject}
        </p>
        <p className="text-sm text-gray-500 truncate">
          {message.preview || message.content}
        </p>
        {message.assignedTo && (
          <div className="mt-1 flex items-center text-xs text-blue-500">
            <Building2 className="h-3 w-3 mr-1" />
            Assigned to: {message.assignedTo}
          </div>
        )}
      </div>
    </div>
  );
};

const MessageComposer = () => {
  const [sender, setSender] = useState('doctor');
  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Card className="flex-grow mr-4">
      <CardHeader>
        <CardTitle>New Message</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="text-sm font-medium mb-2 block">Send as:</label>
            <Select value={sender} onValueChange={setSender}>
              <SelectTrigger>
                <SelectValue>
                  {sender === 'doctor' ? (
                    <div className="flex items-center">
                      <User className="w-4 h-4 mr-2" />
                      Dr Barnes
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <Building2 className="w-4 h-4 mr-2" />
                      Office
                    </div>
                  )}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="doctor">
                  <div className="flex items-center">
                    <User className="w-4 h-4 mr-2" />
                    Dr Barnes
                  </div>
                </SelectItem>
                <SelectItem value="office">
                  <div className="flex items-center">
                    <Building2 className="w-4 h-4 mr-2" />
                    Office
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="w-1/2">
            <label className="text-sm font-medium mb-2 block">To:</label>
            <Select value={selectedRecipient} onValueChange={setSelectedRecipient}>
              <SelectTrigger>
                <SelectValue placeholder="Select recipient" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="header-patients" disabled>
                  Patients
                </SelectItem>
                {patients.map(patient => (
                  <SelectItem key={patient.id} value={`patient-${patient.id}`}>
                    <div className="flex items-center">
                      <Avatar className="h-6 w-6 mr-2">
                        <AvatarFallback>{patient.name.charAt(0)}</AvatarFallback>
                      </Avatar>
                      {patient.name}
                    </div>
                  </SelectItem>
                ))}
                <SelectItem value="header-stakeholders" disabled>
                  Stakeholders
                </SelectItem>
                {stakeholders.map(stakeholder => (
                  <SelectItem key={stakeholder.id} value={`stakeholder-${stakeholder.id}`}>
                    <div className="flex items-center">
                      <Avatar className="h-6 w-6 mr-2">
                        <AvatarFallback>{stakeholder.name.charAt(0)}</AvatarFallback>
                      </Avatar>
                      {stakeholder.name} ({stakeholder.role})
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <Input
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />

        <Textarea
          placeholder="Type your message here..."
          className="min-h-[200px]"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <div className="flex justify-between items-center">
          <div className="flex space-x-2">
            <Select>
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Insert template" />
              </SelectTrigger>
              <SelectContent>
                {messageTemplates.map(template => (
                  <SelectItem key={template.id} value={template.id.toString()}>
                    {template.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            
            <Button variant="outline">
              <File className="w-4 h-4 mr-2" />
              Form
            </Button>
            <Button variant="outline">
              <FileText className="w-4 h-4 mr-2" />
              Report
            </Button>
            <Button variant="outline">
              <Paperclip className="w-4 h-4 mr-2" />
              Attach
            </Button>
            <Button variant="outline">
              <CreditCard className="w-4 h-4 mr-2" />
              Billing
            </Button>
          </div>

          <Button>
            <Send className="w-4 h-4 mr-2" />
            Send Message
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const MessageHistory = ({ onSelectMessage }) => {
  const [selectedCategory, setSelectedCategory] = useState('inbox');

  return (
    <Card className="w-full h-full bg-white">
      <CardHeader className="space-y-2 p-4">
        <div className="flex justify-between items-center">
          <CardTitle>Messages</CardTitle>
          <Button variant="outline" size="sm">
            Mark All Read
          </Button>
        </div>
        <div className="relative">
          <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          <Input className="pl-8" placeholder="Search messages..." />
        </div>
        <Tabs defaultValue="inbox" className="w-full">
          <TabsList className="grid grid-cols-4 gap-1 bg-gray-100 p-1">
            <TabsTrigger value="inbox" className="bg-white data-[state=active]:bg-primary data-[state=active]:text-white">
              Inbox
              {messageCategories.inbox.unread.length > 0 && (
                <span className="ml-1 px-2 py-0.5 bg-red-500 text-white rounded-full text-xs">
                  {messageCategories.inbox.unread.length}
                </span>
              )}
            </TabsTrigger>
            <TabsTrigger value="sent" className="bg-white data-[state=active]:bg-primary data-[state=active]:text-white">
              Sent
            </TabsTrigger>
            <TabsTrigger value="drafts" className="bg-white data-[state=active]:bg-primary data-[state=active]:text-white">
              Drafts
            </TabsTrigger>
            <TabsTrigger value="assigned" className="bg-white data-[state=active]:bg-primary data-[state=active]:text-white">
              Assigned
            </TabsTrigger>
          </TabsList>
          
          <div className="mt-2">
            <ScrollArea className="h-[calc(100vh-280px)]">
              <TabsContent value="inbox" className="m-0">
                {messageCategories.inbox.unread.length > 0 && (
                  <div className="mb-4">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">Unread</h3>
                    {messageCategories.inbox.unread.map(message => (
                      <MessageListItem 
                        key={message.id} 
                        message={message} 
                        onSelect={() => onSelectMessage(message)}
                      />
                    ))}
                  </div>
                )}
                <div>
                  <h3 className="text-sm font-medium text-gray-500 mb-2">Read</h3>
                  {messageCategories.inbox.read.map(message => (
                    <MessageListItem 
                      key={message.id} 
                      message={message} 
                      onSelect={() => onSelectMessage(message)}
                    />
                  ))}
                </div>
              </TabsContent>

              <TabsContent value="sent">
                <div className="space-y-2">
                  {messageCategories.sent.map(message => (
                    <MessageListItem 
                      key={message.id} 
                      message={message} 
                      onSelect={() => onSelectMessage(message)}
                    />
                  ))}
                </div>
              </TabsContent>

              <TabsContent value="drafts">
                <div className="space-y-2">
                  {messageCategories.drafts.map(message => (
                    <MessageListItem 
                      key={message.id} 
                      message={message} 
                      onSelect={() => onSelectMessage(message)}
                    />
                  ))}
                </div>
              </TabsContent>

              <TabsContent value="assigned">
                <div className="space-y-2">
                  {messageCategories.assigned.map(message => (
                    <MessageListItem 
                      key={message.id} 
                      message={message} 
                      onSelect={() => onSelectMessage(message)}
                    />
                  ))}
                </div>
              </TabsContent>
            </ScrollArea>
          </div>
        </Tabs>
      </CardHeader>
    </Card>
  );
};

// Add this new component for the client preview pane
const ClientPreviewPane = ({ client }) => {
  return (
    <Card className="w-72 bg-white">
      <CardHeader className="border-b">
        <div className="flex items-center space-x-3">
          <Avatar className="h-10 w-10">
            <AvatarFallback>{client?.name?.charAt(0)}</AvatarFallback>
          </Avatar>
          <div>
            <CardTitle className="text-lg">{client?.name}</CardTitle>
            <p className="text-sm text-gray-500">{client?.type}</p>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-4 space-y-4">
        <div>
          <h3 className="text-sm font-semibold mb-2">Quick Info</h3>
          <div className="space-y-2 text-sm">
            <div className="flex justify-between">
              <span className="text-gray-500">Last Visit</span>
              <span>Mar 15, 2024</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-500">Next Appointment</span>
              <span>Mar 22, 2024</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-500">Status</span>
              <span className="text-green-600">Active</span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-semibold mb-2">Recent Forms</h3>
          <div className="space-y-1 text-sm">
            <div className="flex justify-between">
              <span>Intake Form</span>
              <span className="text-green-600">Completed</span>
            </div>
            <div className="flex justify-between">
              <span>PHQ-9</span>
              <span className="text-yellow-600">Pending</span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-semibold mb-2">Notes</h3>
          <p className="text-sm text-gray-600">
            Prefers email communication. Allergic to latex.
          </p>
        </div>

        <Button variant="outline" className="w-full">
          View Full Profile
        </Button>
      </CardContent>
    </Card>
  );
};

// Update MessageDetail to include reply functionality
const MessageDetail = ({ message, onClose, onAssign, onAIResponse }) => {
  const [showAssignMenu, setShowAssignMenu] = useState(false);
  const [reply, setReply] = useState('');

  return (
    <Card className="flex-grow mx-4 bg-white">
      <CardHeader className="border-b">
        <div className="flex justify-between items-center">
          <div>
            <CardTitle>{message.subject}</CardTitle>
            <div className="text-sm text-gray-500">
              From: {message.from}
              {message.assignedTo && (
                <span className="ml-2 text-blue-500">
                  Assigned to: {message.assignedTo}
                </span>
              )}
            </div>
          </div>
          <div className="flex space-x-2">
            <Button variant="outline" onClick={() => setShowAssignMenu(!showAssignMenu)}>
              Assign
            </Button>
            <Button variant="outline" onClick={onAIResponse}>
              AI Draft Response
            </Button>
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-4">
        <ScrollArea className="h-[calc(100vh-400px)]">
          <div className="prose max-w-none mb-6">
            {message.content || message.preview}
          </div>
          
          {showAssignMenu && (
            <Card className="mb-4 p-4 border">
              <h3 className="font-medium mb-2">Assign to:</h3>
              <div className="space-y-2">
                <Button 
                  variant="outline" 
                  className="w-full justify-start"
                  onClick={() => onAssign('Office')}
                >
                  <Building2 className="w-4 h-4 mr-2" />
                  Office
                </Button>
                {stakeholders.map(stakeholder => (
                  <Button
                    key={stakeholder.id}
                    variant="outline"
                    className="w-full justify-start"
                    onClick={() => onAssign(stakeholder.name)}
                  >
                    <User className="w-4 h-4 mr-2" />
                    {stakeholder.name}
                  </Button>
                ))}
              </div>
            </Card>
          )}
        </ScrollArea>

        <div className="border-t pt-4 mt-4">
          <Textarea
            placeholder="Type your reply..."
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            className="min-h-[100px] mb-4"
          />
          <div className="flex justify-between items-center">
            <div className="flex space-x-2">
              <Button variant="outline">
                <Paperclip className="w-4 h-4 mr-2" />
                Attach
              </Button>
              <Button variant="outline">
                <File className="w-4 h-4 mr-2" />
                Form
              </Button>
            </div>
            <Button>
              <Send className="w-4 h-4 mr-2" />
              Send Reply
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

// Update the main Messaging component
const Messaging = () => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showCompose, setShowCompose] = useState(false);

  const handleAssign = (assignee) => {
    console.log(`Assigning message to ${assignee}`);
  };

  const handleAIResponse = () => {
    console.log('Generating AI response...');
  };

  return (
    <div className="h-full p-4 bg-gray-50">
      <div className="mb-4">
        <Button onClick={() => setShowCompose(!showCompose)}>
          {showCompose ? 'Show Messages' : 'Compose New'}
        </Button>
      </div>
      
      <div className="flex gap-4 h-[calc(100vh-120px)]">
        {showCompose ? (
          <MessageComposer />
        ) : (
          <>
            {/* Message List */}
            <div className="w-80 flex-shrink-0">
              <MessageHistory 
                onSelectMessage={setSelectedMessage}
              />
            </div>

            {/* Message Detail and Client Preview */}
            {selectedMessage ? (
              <>
                {/* Message Detail */}
                <div className="flex-grow">
                  <MessageDetail
                    message={selectedMessage}
                    onClose={() => setSelectedMessage(null)}
                    onAssign={handleAssign}
                    onAIResponse={handleAIResponse}
                  />
                </div>

                {/* Client Preview */}
                <div className="w-72 flex-shrink-0">
                  <ClientPreviewPane 
                    client={{
                      name: selectedMessage.from || selectedMessage.to,
                      type: 'Patient',
                    }} 
                  />
                </div>
              </>
            ) : (
              <div className="flex-grow flex items-center justify-center text-gray-500">
                Select a message to view
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Messaging; 