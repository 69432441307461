import React, { useState, useEffect } from 'react';
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Label } from "./ui/label";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";

const patients = [
  { id: '1', name: 'Ava Taylor', currentStep: 'Initial Consultation' },
  { id: '2', name: 'Michael Johnson', currentStep: 'Assessment' },
  { id: '3', name: 'Andrew Robinson', currentStep: 'Treatment Plan' },
];

const steps = [
  'Initial Consultation',
  'Assessment',
  'Diagnosis',
  'Treatment Plan',
  'Follow-up',
];

export function StartSessionModal({ isOpen, onClose, onStartSession }) {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setSelectedPatient(null);
      setSelectedStep(null);
    }
  }, [isOpen]);

  const handlePatientChange = (patientId) => {
    const patient = patients.find(p => p.id === patientId);
    setSelectedPatient(patient || null);
    setSelectedStep(patient?.currentStep || null);
  };

  const handleStepChange = (step) => {
    setSelectedStep(step);
  };

  const handleStartSession = () => {
    if (selectedPatient && selectedStep) {
      onStartSession(selectedPatient, selectedStep);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Start New Session</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="patient" className="text-right">
              Patient
            </Label>
            <Select onValueChange={handlePatientChange}>
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select patient" />
              </SelectTrigger>
              <SelectContent>
                {patients.map((patient) => (
                  <SelectItem key={patient.id} value={patient.id}>{patient.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {selectedPatient && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Step</Label>
              <RadioGroup
                defaultValue={selectedPatient.currentStep}
                onValueChange={handleStepChange}
                className="col-span-3"
              >
                {steps.map((step) => (
                  <div key={step} className="flex items-center space-x-2">
                    <RadioGroupItem value={step} id={step} />
                    <Label htmlFor={step}>{step}</Label>
                  </div>
                ))}
              </RadioGroup>
            </div>
          )}
        </div>
        <div className="flex justify-end space-x-2">
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button onClick={handleStartSession} disabled={!selectedPatient || !selectedStep}>
            Start Session
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}