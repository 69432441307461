import React, { useState } from 'react';
import { 
  Search, ChevronRight, Plus, Edit2, ExternalLink, BookOpen, Users, 
  Smartphone, Brain, Link, DollarSign, MapPin, Star, Bold, Italic, 
  List, Heading, Link as LinkIcon, X, Eye, EyeOff, Phone, Mail, 
  Globe, ChevronLeft, Pill, Lightbulb, Gamepad2, Wand2 
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { ToggleGroup, ToggleGroupItem } from "../components/ui/toggle-group";
import { Checkbox } from "../components/ui/checkbox";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../components/ui/dialog";
import { ScrollArea } from "../components/ui/scroll-area";

const PrototypeBanner = () => (
  <div className="bg-blue-50 border-y border-black p-2 text-sm text-gray-700 shadow-sm">
    <div className="container mx-auto flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <span className="font-semibold">PROTOTYPE PREVIEW</span>
        <span className="text-gray-400">|</span>
        <span>Module: Patient Resources</span>
        <span className="text-gray-400">|</span>
        <span>Stage: Design Concept</span>
        <span className="text-gray-400">|</span>
        <span>Version: 0.1.2</span>
        <span className="text-gray-400">|</span>
        <span>Status: Gathering Feedback</span>
      </div>
      <div className="text-xs bg-yellow-100 text-yellow-800 px-2 py-1 rounded border border-yellow-200">
        Demo Only - Not Production Data
      </div>
    </div>
  </div>
);

const ResourceLibraryModal = ({ isOpen, onClose, onSelect, category }) => {
  const libraryItems = {
    'Reading Materials': [
      { 
        name: 'The ADHD Effect on Marriage',
        description: 'Understanding and managing ADHD in relationships',
        isFree: false,
        cost: '$14.99'
      },
      { 
        name: 'Executive Function Skills Workbook',
        description: 'Practical exercises for improving organization and planning',
        isFree: true
      },
      // Add more reading materials...
    ],
    'Apps & Technology': [
      { 
        name: 'Mindfulness Coach',
        description: 'Guided meditation app for ADHD',
        isFree: true
      },
      { 
        name: 'Time Timer',
        description: 'Visual time management tool',
        isFree: false,
        cost: '$2.99'
      },
      // Add more apps...
    ],
    'Support Groups': [
      { 
        name: 'ADHD Parent Support Network',
        description: 'Weekly online meetings for parents',
        isFree: true
      },
      { 
        name: 'Adult ADHD Support Circle',
        description: 'Moderated support group with professional guidance',
        isFree: false,
        cost: '$10/month'
      },
      // Add more support groups...
    ],
    'Therapeutic Resources': [
      { 
        name: 'Behavioral Strategies Guide',
        description: 'Evidence-based interventions for ADHD',
        isFree: true
      },
      { 
        name: 'Parent-Child Interaction Training',
        description: 'Online course for improving family dynamics',
        isFree: false,
        cost: '$49.99'
      },
      // Add more therapeutic resources...
    ]
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Add {category} Resource</DialogTitle>
          <DialogDescription>
            Select from our curated library of resources
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <ScrollArea className="h-[400px] pr-4">
            {libraryItems[category]?.map((item, index) => (
              <div
                key={index}
                className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer mb-2"
                onClick={() => onSelect(item)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium">{item.name}</h3>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                  {item.isFree ? (
                    <span className="text-green-600 text-sm">Free</span>
                  ) : (
                    <span className="text-gray-600 text-sm">{item.cost}</span>
                  )}
                </div>
              </div>
            ))}
          </ScrollArea>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const diagnoses = [
  { 
    id: 'adhd', 
    name: 'ADHD', 
    category: 'Neurodevelopmental',
    description: `
      You've been diagnosed with Attention-Deficit/Hyperactivity Disorder (ADHD). This isn't just a childhood condition - it affects adults too, impacting how we focus, organize, and manage time. But here's the good news: ADHD is highly manageable with the right support and strategies.

      Think of ADHD as having a Ferrari engine for a brain with bicycle brakes. It's not about lacking ability - it's about harnessing your unique strengths while developing strategies for the challenges. Many successful entrepreneurs, creatives, and innovators have ADHD.

      We've curated these resources to help you understand and work with your ADHD, not against it. From practical tools to supportive communities, everything here is chosen to help you thrive.
    `
  },
  { id: 'anxiety', name: 'Anxiety Disorders', category: 'Anxiety' },
  { id: 'depression', name: 'Depression', category: 'Mood' },
  { id: 'asd', name: 'Autism Spectrum Disorder', category: 'Neurodevelopmental' },
  { id: 'learning', name: 'Learning Disorders', category: 'Educational' },
  { id: 'ocd', name: 'OCD', category: 'Anxiety' },
  { id: 'ptsd', name: 'PTSD', category: 'Trauma' },
  { id: 'bipolar', name: 'Bipolar Disorder', category: 'Mood' }
];

const resourceCategories = [
  {
    icon: <BookOpen className="text-blue-500" />,
    title: 'Reading Materials',
    items: [
      { 
        name: 'Driven to Distraction',
        description: 'The gold standard in understanding adult ADHD',
        url: '#',
        isFree: false,
        cost: '$15.99'
      },
      { 
        name: 'ADHD Survival Guide PDF',
        description: 'Ourclinics comprehensive guide to managing ADHD',
        url: '#',
        isFree: true
      },
      { 
        name: 'Understanding Executive Function',
        description: 'Free guide from CHADD organization',
        url: '#',
        isFree: true
      }
    ]
  },
  {
    icon: <Smartphone className="text-purple-500" />,
    title: 'Apps & Technology',
    items: [
      { 
        name: 'Focus@Will',
        description: 'Science-based music to help you concentrate',
        url: '#',
        isFree: false,
        cost: '$7.99/mo'
      },
      { 
        name: 'Forest App',
        description: 'Gamified focus timer that plants real trees',
        url: '#',
        isFree: false,
        cost: '$1.99'
      },
      { 
        name: 'Microsoft To-Do',
        description: 'Free task management with ADHD-friendly features',
        url: '#',
        isFree: true
      }
    ]
  },
  {
    icon: <Users className="text-green-500" />,
    title: 'Support Groups',
    items: [
      { 
        name: 'Local CHADD Chapter',
        description: 'Monthly meetings at Community Center',
        url: '#',
        isFree: true
      },
      { 
        name: 'ADHD Adults Online Community',
        description: 'Moderated Discord server with 24/7 support',
        url: '#',
        isFree: true
      },
      { 
        name: 'Focused Minds Workshop',
        description: 'Weekly virtual group sessions',
        url: '#',
        isFree: false,
        cost: '$25/session'
      }
    ]
  },
  {
    icon: <Brain className="text-orange-500" />,
    title: 'Therapeutic Resources',
    items: [
      { 
        name: 'CBT Workbook for ADHD',
        description: 'Interactive PDF with exercises',
        url: '#',
        isFree: true
      },
      { 
        name: 'Time Management Templates',
        description: 'Customizable planning sheets',
        url: '#',
        isFree: true
      },
      { 
        name: 'ADHD Coaching Session',
        description: 'One-on-one virtual coaching',
        url: '#',
        isFree: false,
        cost: '$75/session'
      }
    ]
  }
];

const localReferrals = [
  {
    name: 'Dr. Sarah Johnson',
    specialty: 'ADHD Specialist & Psychiatrist',
    location: '123 Main St, Suite 4',
    distance: '0.8 miles',
    accepting: true,
    insurance: ['Blue Cross', 'Aetna', 'Medicare'],
    rating: 4.8,
    reviews: 124
  },
  {
    name: 'Mindful ADHD Clinic',
    specialty: 'ADHD Coaching & Therapy',
    location: '456 Oak Ave',
    distance: '2.3 miles',
    accepting: true,
    insurance: ['United', 'Cigna'],
    rating: 4.6,
    reviews: 89
  },
  {
    name: 'Focus Forward Center',
    specialty: 'Behavioral Therapy & ADHD Management',
    location: '789 Pine St',
    distance: '3.1 miles',
    accepting: false,
    insurance: ['Blue Cross', 'Aetna'],
    rating: 4.9,
    reviews: 156
  }
];

const EditorToolbar = ({ onAction }) => {
  return (
    <ToggleGroup type="multiple" className="mb-4">
      <ToggleGroupItem value="heading" onClick={() => onAction('heading')}>
        <Heading className="h-4 w-4" />
      </ToggleGroupItem>
      <ToggleGroupItem value="bold" onClick={() => onAction('bold')}>
        <Bold className="h-4 w-4" />
      </ToggleGroupItem>
      <ToggleGroupItem value="italic" onClick={() => onAction('italic')}>
        <Italic className="h-4 w-4" />
      </ToggleGroupItem>
      <ToggleGroupItem value="bullet" onClick={() => onAction('bullet')}>
        <List className="h-4 w-4" />
      </ToggleGroupItem>
      <ToggleGroupItem value="link" onClick={() => onAction('link')}>
        <LinkIcon className="h-4 w-4" />
      </ToggleGroupItem>
    </ToggleGroup>
  );
};

const ResourceItem = ({ item }) => (
  <li className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
    <div className="flex-1">
      <div className="flex items-center justify-between">
        <span className="font-medium">{item.name}</span>
        {item.isFree ? (
          <span className="text-green-600 text-sm">Free</span>
        ) : (
          <span className="text-gray-600 text-sm flex items-center">
            <DollarSign className="h-3 w-3 mr-1" />
            {item.cost}
          </span>
        )}
      </div>
      <p className="text-sm text-gray-600">{item.description}</p>
    </div>
    <Button variant="ghost" size="sm" className="ml-2">
      <ExternalLink className="h-4 w-4" />
    </Button>
  </li>
);

const LocalReferrals = () => (
  <Card className="mt-6">
    <CardHeader>
      <CardTitle className="flex items-center">
        <MapPin className="text-red-500 mr-2" />
        Local Referrals (Zip: 90036)
      </CardTitle>
    </CardHeader>
    <CardContent>
      <p className="text-sm text-gray-600 mb-4">
        These providers specialize in post-diagnosis ADHD care. While I'm available for ongoing care,
        I encourage you to explore all options to find the best fit for your needs.
      </p>
      <div className="space-y-4">
        {localReferrals.map((provider, index) => (
          <div key={index} className="border rounded-lg p-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-medium">{provider.name}</h3>
                <p className="text-sm text-gray-600">{provider.specialty}</p>
                <p className="text-sm text-gray-500">
                  <MapPin className="h-3 w-3 inline mr-1" />
                  {provider.location} ({provider.distance})
                </p>
              </div>
              <div className="text-right">
                <div className="flex items-center">
                  <Star className="h-4 w-4 text-yellow-400 mr-1" />
                  <span className="font-medium">{provider.rating}</span>
                  <span className="text-sm text-gray-500 ml-1">({provider.reviews})</span>
                </div>
                <span className={`text-sm ${provider.accepting ? 'text-green-600' : 'text-red-600'}`}>
                  {provider.accepting ? 'Accepting Patients' : 'Waitlist Only'}
                </span>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-600">Insurance accepted:</p>
              <div className="flex flex-wrap gap-1 mt-1">
                {provider.insurance.map((ins, i) => (
                  <span key={i} className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded">
                    {ins}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </CardContent>
  </Card>
);

const EditableResourceItem = ({ item, onEdit, onRemove }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState(item);

  if (isEditing) {
    return (
      <div className="p-2 border rounded">
        <Input
          value={editedItem.name}
          onChange={(e) => setEditedItem({ ...editedItem, name: e.target.value })}
          className="mb-2"
        />
        <Input
          value={editedItem.description}
          onChange={(e) => setEditedItem({ ...editedItem, description: e.target.value })}
          className="mb-2"
        />
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Checkbox 
              checked={editedItem.isFree}
              onCheckedChange={(checked) => setEditedItem({ ...editedItem, isFree: checked })}
            />
            <span>Free</span>
          </div>
          {!editedItem.isFree && (
            <Input
              value={editedItem.cost}
              onChange={(e) => setEditedItem({ ...editedItem, cost: e.target.value })}
              className="w-24"
            />
          )}
        </div>
        <div className="flex justify-end space-x-2 mt-2">
          <Button variant="outline" size="sm" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
          <Button size="sm" onClick={() => {
            onEdit(editedItem);
            setIsEditing(false);
          }}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  return (
    <li className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <span className="font-medium">{item.name}</span>
          {item.isFree ? (
            <span className="text-green-600 text-sm">Free</span>
          ) : (
            <span className="text-gray-600 text-sm flex items-center">
              <DollarSign className="h-3 w-3 mr-1" />
              {item.cost}
            </span>
          )}
        </div>
        <p className="text-sm text-gray-600">{item.description}</p>
      </div>
      <div className="flex space-x-2 ml-2">
        <Button variant="ghost" size="sm" onClick={() => setIsEditing(true)}>
          <Edit2 className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="sm" onClick={() => onRemove(item)}>
          <X className="h-4 w-4" />
        </Button>
      </div>
    </li>
  );
};

const ContactButtons = ({ phone, email, website }) => {
  const handleCall = () => window.location.href = `tel:${phone}`;
  const handleEmail = () => window.location.href = `mailto:${email}`;
  const handleWebsite = () => window.open(website, '_blank');

  return (
    <div className="flex flex-col gap-2 mt-3 border-t pt-3">
      {phone && (
        <Button 
          variant="outline" 
          className="w-full justify-start" 
          onClick={handleCall}
        >
          <Phone className="h-4 w-4 mr-2 text-green-600" />
          <span className="text-sm">{phone}</span>
        </Button>
      )}
      {email && (
        <Button 
          variant="outline" 
          className="w-full justify-start"
          onClick={handleEmail}
        >
          <Mail className="h-4 w-4 mr-2 text-blue-600" />
          <span className="text-sm">{email}</span>
        </Button>
      )}
      {website && (
        <Button 
          variant="outline" 
          className="w-full justify-start"
          onClick={handleWebsite}
        >
          <Globe className="h-4 w-4 mr-2 text-purple-600" />
          <span className="text-sm">Visit Website</span>
        </Button>
      )}
    </div>
  );
};

const EditableReferral = ({ referral, onEdit, onRemove }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedReferral, setEditedReferral] = useState(referral);

  if (isEditing) {
    return (
      <Card className="shadow-sm border border-gray-200">
        <CardContent className="p-6">
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Provider Name</label>
                <Input
                  value={editedReferral.name}
                  onChange={(e) => setEditedReferral({ ...editedReferral, name: e.target.value })}
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Specialty</label>
                <Input
                  value={editedReferral.specialty}
                  onChange={(e) => setEditedReferral({ ...editedReferral, specialty: e.target.value })}
                />
              </div>
            </div>
            
            <div className="space-y-2">
              <label className="text-sm font-medium">Location</label>
              <Input
                value={editedReferral.location}
                onChange={(e) => setEditedReferral({ ...editedReferral, location: e.target.value })}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Phone</label>
                <Input
                  value={editedReferral.phone || ''}
                  onChange={(e) => setEditedReferral({ ...editedReferral, phone: e.target.value })}
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Email</label>
                <Input
                  value={editedReferral.email || ''}
                  onChange={(e) => setEditedReferral({ ...editedReferral, email: e.target.value })}
                />
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Website</label>
              <Input
                value={editedReferral.website || ''}
                onChange={(e) => setEditedReferral({ ...editedReferral, website: e.target.value })}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox 
                checked={editedReferral.accepting}
                onCheckedChange={(checked) => setEditedReferral({ ...editedReferral, accepting: checked })}
              />
              <span className="text-sm">Currently Accepting Patients</span>
            </div>

            <div className="flex justify-end space-x-2 pt-4">
              <Button variant="outline" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
              <Button onClick={() => {
                onEdit(editedReferral);
                setIsEditing(false);
              }}>
                Save Changes
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="bg-white shadow-sm hover:shadow-md transition-shadow duration-200">
      <CardContent className="p-4">
        {/* Header Section */}
        <div className="flex justify-between items-start mb-3">
          <div>
            <h3 className="font-medium text-gray-900">{referral.name}</h3>
            <p className="text-sm text-gray-600">{referral.specialty}</p>
            <p className="text-sm text-gray-500 flex items-center mt-1">
              <MapPin className="h-3 w-3 mr-1" />
              {referral.location}
            </p>
          </div>
          <div className="flex space-x-1">
            <Button variant="ghost" size="sm" onClick={() => setIsEditing(true)}>
              <Edit2 className="h-4 w-4" />
            </Button>
            <Button variant="ghost" size="sm" onClick={() => onRemove(referral)}>
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>

        {/* Contact Buttons */}
        <div className="flex gap-2 mt-2">
          {referral.phone && (
            <Button 
              variant="outline" 
              size="sm"
              className="flex-1"
              onClick={() => window.location.href = `tel:${referral.phone}`}
            >
              <Phone className="h-4 w-4 mr-2 text-green-600" />
              Call
            </Button>
          )}
          {referral.email && (
            <Button 
              variant="outline" 
              size="sm"
              className="flex-1"
              onClick={() => window.location.href = `mailto:${referral.email}`}
            >
              <Mail className="h-4 w-4 mr-2 text-blue-600" />
              Email
            </Button>
          )}
          {referral.website && (
            <Button 
              variant="outline" 
              size="sm"
              className="flex-1"
              onClick={() => window.open(referral.website, '_blank')}
            >
              <Globe className="h-4 w-4 mr-2 text-purple-600" />
              Website
            </Button>
          )}
        </div>

        {/* Status and Insurance */}
        <div className="flex items-center justify-between mt-3 pt-3 border-t">
          <span className={`text-xs px-2 py-1 rounded-full font-medium ${
            referral.accepting 
              ? 'bg-green-50 text-green-700' 
              : 'bg-yellow-50 text-yellow-700'
          }`}>
            {referral.accepting ? 'Accepting Patients' : 'Waitlist Only'}
          </span>
          {referral.insurance && (
            <div className="flex gap-1">
              {referral.insurance.map((ins, i) => (
                <span 
                  key={i} 
                  className="text-xs bg-blue-50 text-blue-700 px-2 py-1 rounded-full"
                >
                  {ins}
                </span>
              ))}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const ResourceCategoryCard = ({ category, isVisible, onToggleVisibility, onEditItem, onRemoveItem }) => (
  <Card className="bg-white shadow-sm hover:shadow-md transition-shadow duration-200">
    <CardHeader className="border-b bg-gray-50/50">
      <div className="flex items-center justify-between">
        <CardTitle className="flex items-center text-lg">
          {category.icon}
          <span className="ml-3">{category.title}</span>
        </CardTitle>
        <div className="flex items-center space-x-2">
          <Button variant="ghost" size="sm" onClick={onToggleVisibility}>
            {isVisible ? <Eye className="h-4 w-4" /> : <EyeOff className="h-4 w-4" />}
          </Button>
        </div>
      </div>
    </CardHeader>
    {isVisible && (
      <CardContent className="p-4">
        <ul className="space-y-3">
          {category.items.map((item, index) => (
            <EditableResourceItem
              key={index}
              item={item}
              onEdit={(editedItem) => onEditItem(index, editedItem)}
              onRemove={() => onRemoveItem(index)}
            />
          ))}
        </ul>
      </CardContent>
    )}
  </Card>
);

const AddResourceModal = ({ isOpen, onClose, onAdd }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const additionalCategories = [
    {
      icon: <Pill className="text-pink-500" />,
      title: 'Medication Information',
      description: 'Educational resources about ADHD medications. Please consult with your healthcare provider before making any medication decisions.',
      items: [
        {
          name: 'Understanding ADHD Medications',
          description: 'Comprehensive guide to different types of ADHD medications',
          url: '#',
          isFree: true,
          disclaimer: 'For informational purposes only. Consult your doctor for medical advice.'
        },
        {
          name: 'Medication Myths and Facts',
          description: 'Common misconceptions about ADHD medications',
          url: '#',
          isFree: true
        }
      ]
    },
    {
      icon: <Lightbulb className="text-yellow-500" />,
      title: 'Daily Mantras & Mindfulness',
      items: [
        {
          name: 'Morning Focus Ritual',
          description: 'Start your day with these mindfulness exercises',
          url: '#',
          isFree: true
        },
        {
          name: 'ADHD-Friendly Meditation Guide',
          description: 'Meditation techniques designed for ADHD minds',
          url: '#',
          isFree: true
        }
      ]
    },
    {
      icon: <Gamepad2 className="text-indigo-500" />,
      title: 'Brain Training Games',
      items: [
        {
          name: 'Cognitive Challenge Games',
          description: 'Games that help improve executive function',
          url: '#',
          isFree: false,
          cost: '$4.99'
        },
        {
          name: 'Memory Master',
          description: 'Memory enhancement exercises and games',
          url: '#',
          isFree: true
        }
      ]
    }
  ];

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Add Resources</DialogTitle>
          <DialogDescription>
            Select a category to add resources
          </DialogDescription>
        </DialogHeader>
        
        {!selectedCategory ? (
          <div className="grid grid-cols-2 gap-4">
            {additionalCategories.map((category, index) => (
              <Card 
                key={index} 
                className="cursor-pointer hover:bg-gray-50 transition-colors"
                onClick={() => setSelectedCategory(category)}
              >
                <CardHeader>
                  <div className="flex items-center space-x-2">
                    {category.icon}
                    <CardTitle className="text-lg">{category.title}</CardTitle>
                  </div>
                </CardHeader>
                <CardContent>
                  <p className="text-sm text-gray-600">
                    {category.description || `Add new ${category.title.toLowerCase()}`}
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <div className="space-y-4">
            <div className="flex items-center mb-6">
              <Button 
                variant="ghost" 
                onClick={() => setSelectedCategory(null)}
                className="mr-auto"
              >
                <ChevronLeft className="h-4 w-4 mr-2" />
                Back to Categories
              </Button>
              <span className="text-sm text-gray-500">
                {selectedCategory.title}
              </span>
            </div>

            {selectedCategory.description && (
              <Alert>
                <AlertTitle>Important Note</AlertTitle>
                <AlertDescription>
                  {selectedCategory.description}
                </AlertDescription>
              </Alert>
            )}

            <div className="space-y-2">
              {selectedCategory.items.map((item, index) => (
                <div 
                  key={index}
                  className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer"
                  onClick={() => {
                    onAdd(selectedCategory.title, item);
                    onClose();
                  }}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium">{item.name}</h3>
                      <p className="text-sm text-gray-600">{item.description}</p>
                      {item.disclaimer && (
                        <p className="text-xs text-amber-600 mt-1">{item.disclaimer}</p>
                      )}
                    </div>
                    {item.isFree ? (
                      <span className="text-green-600 text-sm">Free</span>
                    ) : (
                      <span className="text-gray-600 text-sm">{item.cost}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const Alert = ({ children, className }) => (
  <div className={`bg-amber-50 border border-amber-200 rounded-lg p-4 ${className}`}>
    {children}
  </div>
);

const AlertTitle = ({ children }) => (
  <h5 className="text-amber-800 font-medium mb-1">{children}</h5>
);

const AlertDescription = ({ children }) => (
  <p className="text-amber-700 text-sm">{children}</p>
);

export default function Resources() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
  const [customRecommendations, setCustomRecommendations] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  
  const [visibleSections, setVisibleSections] = useState(
    resourceCategories.reduce((acc, cat) => ({ ...acc, [cat.title]: true }), {})
  );
  const [resources, setResources] = useState(resourceCategories);
  const [referrals, setReferrals] = useState(localReferrals);
  const [showReferrals, setShowReferrals] = useState(true);
  const [resourceLibraryOpen, setResourceLibraryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addResourceModalOpen, setAddResourceModalOpen] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);

  const filteredDiagnoses = diagnoses.filter(diagnosis => 
    diagnosis.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    diagnosis.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleSectionVisibility = (sectionTitle) => {
    setVisibleSections(prev => ({
      ...prev,
      [sectionTitle]: !prev[sectionTitle]
    }));
  };

  const handleEditResource = (categoryIndex, itemIndex, editedItem) => {
    const newResources = [...resources];
    newResources[categoryIndex].items[itemIndex] = editedItem;
    setResources(newResources);
  };

  const handleRemoveResource = (categoryIndex, itemIndex) => {
    const newResources = [...resources];
    newResources[categoryIndex].items.splice(itemIndex, 1);
    setResources(newResources);
  };

  const handleAddResource = (categoryTitle, newResource) => {
    const categoryIndex = resources.findIndex(cat => cat.title === categoryTitle);
    if (categoryIndex !== -1) {
      const newResources = [...resources];
      newResources[categoryIndex].items.push(newResource);
      setResources(newResources);
    }
    setAddResourceModalOpen(false);
  };

  const handleSelectLibraryItem = (item) => {
    const categoryIndex = resources.findIndex(cat => cat.title === selectedCategory);
    if (categoryIndex !== -1) {
      const newResources = [...resources];
      newResources[categoryIndex].items.push(item);
      setResources(newResources);
    }
    setResourceLibraryOpen(false);
  };

  const handleAddReferral = () => {
    const newReferral = {
      name: '',
      specialty: '',
      location: '',
      accepting: true,
      phone: '',
      email: '',
      website: ''
    };
    setReferrals([...referrals, newReferral]);
  };

  const handleEditReferral = (index, editedReferral) => {
    const newReferrals = [...referrals];
    newReferrals[index] = editedReferral;
    setReferrals(newReferrals);
  };

  const handleRemoveReferral = (index) => {
    setReferrals(referrals.filter((_, i) => i !== index));
  };

  return (
    <>
      <PrototypeBanner />
      <div className="flex h-screen bg-[#f8fafc]">
        {/* Left Sidebar - Diagnoses */}
        <div className="w-72 bg-white border-r border-gray-200 overflow-y-auto">
          <div className="p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Conditions</h2>
            <div className="mb-6">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                <Input
                  placeholder="Search conditions..."
                  className="pl-9 bg-white border-gray-200 focus:border-gray-300 rounded-lg"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="space-y-2">
              {filteredDiagnoses.map((diagnosis) => (
                <button
                  key={diagnosis.id}
                  onClick={() => setSelectedDiagnosis(diagnosis)}
                  className={`w-full text-left p-4 rounded-xl flex items-center justify-between transition-all border ${
                    selectedDiagnosis?.id === diagnosis.id 
                      ? 'bg-white border-gray-200 shadow-sm' 
                      : 'border-transparent hover:border-gray-200 hover:bg-white'
                  }`}
                >
                  <div className="flex flex-col">
                    <span className={`font-medium ${
                      selectedDiagnosis?.id === diagnosis.id 
                        ? 'text-gray-900' 
                        : 'text-gray-700'
                    }`}>{diagnosis.name}</span>
                    <span className="text-xs text-gray-500">{diagnosis.category}</span>
                  </div>
                  <ChevronRight className={`h-4 w-4 ${
                    selectedDiagnosis?.id === diagnosis.id 
                      ? 'text-gray-500' 
                      : 'text-gray-400'
                  }`} />
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-y-auto">
          <Button 
            variant="ghost" 
            size="sm" 
            className="fixed top-4 left-4 z-50 bg-white shadow-sm hover:bg-gray-100"
            onClick={() => {
              const sidebar = document.querySelector('.bg-gray-800');  // Target the sidebar by its background color class
              if (sidebar) {
                sidebar.style.display = hideSidebar ? 'block' : 'none';
                setHideSidebar(!hideSidebar);
              }
            }}
          >
            {hideSidebar ? (
              <ChevronRight className="h-4 w-4" />
            ) : (
              <ChevronLeft className="h-4 w-4" />
            )}
          </Button>

          {selectedDiagnosis ? (
            <div className="max-w-6xl mx-auto p-8 space-y-8">
              {/* Header Section */}
              <div className="space-y-6">
                <div className="flex items-center justify-between">
                  <h1 className="text-3xl font-bold text-gray-900">
                    {selectedDiagnosis.name} Resources
                  </h1>
                  <Button 
                    variant="outline"
                    onClick={() => setAddResourceModalOpen(true)}
                    className="border border-gray-200 hover:bg-gray-50 text-gray-700"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Resources
                  </Button>
                </div>
                
                <Card className="bg-white shadow-sm">
                  <CardContent className="prose max-w-none p-6">
                    <p className="text-gray-600 leading-relaxed whitespace-pre-line">
                      {selectedDiagnosis.description}
                    </p>
                  </CardContent>
                </Card>
              </div>

              {/* Resource Categories Grid */}
              <div className="grid grid-cols-2 gap-6">
                {resources.map((category, categoryIndex) => (
                  <ResourceCategoryCard
                    key={categoryIndex}
                    category={category}
                    isVisible={visibleSections[category.title]}
                    onToggleVisibility={() => toggleSectionVisibility(category.title)}
                    onEditItem={(itemIndex, editedItem) => 
                      handleEditResource(categoryIndex, itemIndex, editedItem)
                    }
                    onRemoveItem={(itemIndex) => 
                      handleRemoveResource(categoryIndex, itemIndex)
                    }
                    onAddItem={() => handleAddResource(category.title)}
                  />
                ))}
              </div>

              {/* Custom Recommendations Section */}
              <Card className="shadow-sm">
                <CardHeader className="border-b bg-gray-50/50">
                  <div className="flex items-center justify-between">
                    <CardTitle className="flex items-center text-lg">
                      <Edit2 className="text-blue-500 mr-3" />
                      Recommendations From Dr. Barnes
                    </CardTitle>
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => setIsEditing(!isEditing)}
                      className="border border-gray-200"
                    >
                      <Edit2 className="h-4 w-4" />
                    </Button>
                  </div>
                </CardHeader>
                <CardContent className="p-6">
                  {isEditing ? (
                    <div className="space-y-4">
                      <EditorToolbar 
                        onAction={(action) => {
                          console.log(`Editor action: ${action}`);
                          // Implement rich text editing actions
                          switch(action) {
                            case 'heading':
                              // Add heading formatting
                              break;
                            case 'bold':
                              // Add bold formatting
                              break;
                            case 'italic':
                              // Add italic formatting
                              break;
                            case 'bullet':
                              // Add bullet list
                              break;
                            case 'link':
                              // Add link
                              break;
                            default:
                              break;
                          }
                        }} 
                      />
                      <Textarea
                        value={customRecommendations}
                        onChange={(e) => setCustomRecommendations(e.target.value)}
                        rows={6}
                        placeholder="Add your custom recommendations here..."
                        className="min-h-[200px] font-mono"
                      />
                      <div className="flex justify-between items-center">
                        {customRecommendations.length > 0 && (
                          <Button 
                            variant="outline"
                            onClick={() => {
                              setCustomRecommendations(`Based on the assessment results and clinical presentation, medication management may be beneficial as part of a comprehensive treatment approach. I recommend scheduling a follow-up appointment with your Primary Care Physician to:

- Discuss medication options that could help manage your ADHD symptoms
- Review potential benefits and considerations of pharmacological intervention
- Develop an appropriate monitoring plan if medication is initiated
- Establish ongoing coordination between our offices to optimize your care

It's important to note that medication is just one component of ADHD management. Continue utilizing the behavioral strategies and accommodations we discussed, as these work synergistically with any potential medication treatment.

Please keep me updated on your medication journey so we can adjust other interventions accordingly and ensure you're receiving optimal support across all treatment modalities.`);
                            }}
                            className="border border-blue-200 text-blue-600 hover:bg-blue-50"
                          >
                            <Wand2 className="h-4 w-4 mr-2" />
                            AI Assist
                          </Button>
                        )}
                        <div className="flex space-x-2">
                          <Button variant="outline" onClick={() => setIsEditing(false)}>
                            Cancel
                          </Button>
                          <Button onClick={() => setIsEditing(false)}>
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="prose max-w-none">
                      {customRecommendations ? (
                        <div className="whitespace-pre-wrap">{customRecommendations}</div>
                      ) : (
                        <span className="text-gray-400 italic">
                          No custom recommendations added yet. Click the edit button to add your recommendations.
                        </span>
                      )}
                    </div>
                  )}
                </CardContent>
              </Card>

              {/* Local Referrals Section */}
              <Card className="shadow-sm">
                <CardHeader className="border-b bg-gray-50/50">
                  <div className="flex items-center justify-between">
                    <CardTitle className="flex items-center text-lg">
                      <MapPin className="text-red-500 mr-3" />
                      Local Referrals
                    </CardTitle>
                    <div className="flex items-center space-x-2">
                      <Button variant="outline" onClick={handleAddReferral}>
                        <Plus className="h-4 w-4 mr-2" />
                        Add Referral
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setShowReferrals(!showReferrals)}
                      >
                        {showReferrals ? <Eye className="h-4 w-4" /> : <EyeOff className="h-4 w-4" />}
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                {showReferrals && (
                  <CardContent className="p-6">
                    <div className="grid gap-6">
                      {referrals.map((referral, index) => (
                        <EditableReferral
                          key={index}
                          referral={referral}
                          onEdit={(editedReferral) => handleEditReferral(index, editedReferral)}
                          onRemove={() => handleRemoveReferral(index)}
                        />
                      ))}
                    </div>
                  </CardContent>
                )}
              </Card>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full text-gray-500">
              Select a diagnosis to view resources
            </div>
          )}
        </div>

        {/* Add the modal */}
        <AddResourceModal 
          isOpen={addResourceModalOpen}
          onClose={() => setAddResourceModalOpen(false)}
          onAdd={handleAddResource}
        />
      </div>
    </>
  );
} 