import React from 'react';
import { CheckCircle, ChevronRight } from "lucide-react";
import { Button } from "../components/ui/button";

function OnboardingItem({ isDone, text }) {
  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center">
        <CheckCircle className={`h-5 w-5 ${isDone ? 'text-green-500' : 'text-gray-400'} mr-3`} />
        <span className="text-sm text-gray-600 dark:text-gray-400">{text}</span>
      </div>
      {!isDone && (
        <Button variant="ghost" size="sm" className="px-2">
          <ChevronRight className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
}

export default OnboardingItem;