import React from 'react';
import { ChevronRight, Settings, FileText, Mail, Upload, ExternalLink, FileQuestion, LineChart } from "lucide-react";
import QuickLaunchBar from '../components/QuickLaunchBar';
import DashboardTile from '../components/DashboardTile';
import OnboardingItem from '../components/OnboardingItem';
import InfoTile from '../components/InfoTile';
import { LibrariesTile } from '../components/LibrariesTile';
import { PatientsTile } from '../components/PatientsTile';
import { Button } from "../components/ui/button";
import { Link } from "react-router-dom";

// Updated DocumentQueueTile component
const DocumentQueueTile = () => {
  const pendingDocuments = [
    { id: 1, timestamp: '1326 - Oct 24', name: 'Intake Processing', source: 'Patient Portal', patient: 'John Smith', type: 'Intake' },
    { id: 2, timestamp: '1351 - Oct 24', name: 'Inbound Fax 117', source: 'efax', patient: '<unknown>', type: 'fax' },
    { id: 3, timestamp: '1418 - Oct 24', name: 'Email In 118', source: 'Email In', patient: 'Unknown', type: 'Insurance' },
    // Add more documents as needed
  ];

  const getSourceIcon = (source) => {
    switch (source.toLowerCase()) {
      case 'patient portal':
        return <Upload className="h-4 w-4" />;
      case 'efax':
        return <FileText className="h-4 w-4" />;
      case 'email in':
        return <Mail className="h-4 w-4" />;
      default:
        return <FileQuestion className="h-4 w-4" />;
    }
  };

  return (
    <DashboardTile title="Documents">
      <div className="flex flex-col">
        <div className="mb-4">
          <div className="text-4xl font-bold text-blue-600">{pendingDocuments.length}</div>
          <div className="text-sm text-gray-500">Pending Documents</div>
          <Button className="mt-2" size="sm">Process</Button>
        </div>
        <div className="flex-grow">
          <ul className="space-y-2">
            {pendingDocuments.map((doc) => (
              <li key={doc.id} className="text-sm border-b pb-2">
                <div className="flex justify-between items-center">
                  <span className="font-semibold">{doc.timestamp}</span>
                  <span>{doc.name}</span>
                </div>
                <div className="flex items-center mt-1">
                  {getSourceIcon(doc.source)}
                  <span className="ml-1">{doc.source}</span>
                  <span className="mx-2">|</span>
                  <span>{doc.patient}</span>
                  <span className="mx-2">|</span>
                  <span>{doc.type}</span>
                </div>
              </li>
            ))}
          </ul>
          <Link to="/document-manager" className="text-blue-500 hover:underline text-sm flex items-center mt-2">
            View All <ExternalLink className="h-4 w-4 ml-1" />
          </Link>
        </div>
      </div>
    </DashboardTile>
  );
};

function Dashboard() {
  return (
    <main className="flex-1 overflow-y-auto p-8">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Kalamazoo ADHD Clinic</h1>
      <QuickLaunchBar />
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <DashboardTile 
          title="Onboarding" 
          icon={<ChevronRight className="h-6 w-6" />}
        >
          <div className="space-y-2">
            <OnboardingItem isDone={true} text="Add Service" />
            <OnboardingItem isDone={false} text="Patient Record" />
            <OnboardingItem isDone={false} text="Configure Calendar" />
            <OnboardingItem 
              isDone={false} 
              text="Remove Test Data"
              action={
                <Button variant="destructive" size="sm">
                  Remove
                </Button>
              }
            />
          </div>
        </DashboardTile>
        <DashboardTile title="Appointments">
          <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
          <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
        </DashboardTile>
        <PatientsTile />
        <LibrariesTile />
        <DocumentQueueTile />
        <DashboardTile title="Billing">
          <div className="flex flex-col items-start space-y-4">
            <div>
              <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
              <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
            </div>
            <Button variant="outline" size="sm" asChild>
              <Link to="/settings/billing">
                Complete Insurance & Billing Config
              </Link>
            </Button>
          </div>
        </DashboardTile>
        <DashboardTile title="Notifications">
          <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
          <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
        </DashboardTile>
        <DashboardTile title="Analytics">
          <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
          <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
        </DashboardTile>
        <InfoTile
          title="Clinic Information"
          icon={<Settings className="h-6 w-6" />}
          settingsIcon={true}
          settingsLink="/settings/communications/externalConfig"
        >
          <div className="space-y-2">
            <p className="text-sm">Patient Portal: https://mypsychaccess.com/m/myclinic</p>
            <p className="text-sm">Outbound email: yourclinic@mypsychaccess.com</p>
            <p className="text-sm">Document email: inbound-1871@inbound.mypsychaccess.com</p>
            <p className="text-sm flex items-center justify-between">
              Fax Number: Not set up
              <Button variant="outline" size="sm" asChild>
                <Link to="/settings/communications/external-config">Set up eFaxing</Link>
              </Button>
            </p>
          </div>
        </InfoTile>
      </div>
    </main>
  );
}
export default Dashboard;

