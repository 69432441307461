import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { Button } from "../components/ui/button"
import { Progress } from "../components/ui/progress"
import { MoreHorizontal, FileText, Calendar, Users } from "lucide-react"
import { Avatar, AvatarFallback } from "../components/ui/avatar"

const patients = [
  {
    initial: "A",
    name: "Ava Taylor",
    evaluationType: "Autism Spectrum",
    progress: 90,
  },
  {
    initial: "M",
    name: "Michael Johnson",
    evaluationType: "Strengths Based",
    progress: 10,
  },
  {
    initial: "A",
    name: "Andrew Robinson",
    evaluationType: "FAA Clearance",
    progress: 50,
  }
]

function PatientCard({ patient }) {
  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center">
        <Avatar className="h-8 w-8 mr-2">
          <AvatarFallback className="bg-gradient-to-br from-blue-400 to-blue-600 text-white text-xs">
            {patient.initial}
          </AvatarFallback>
        </Avatar>
        <div>
          <h3 className="font-semibold text-sm text-gray-900 dark:text-gray-100">
            {patient.name}
          </h3>
          <p className="text-xs text-gray-500 dark:text-gray-400">{patient.evaluationType}</p>
        </div>
      </div>
      <Progress value={patient.progress} className="w-16 h-1" />
    </div>
  )
}

export function PatientsTile() {
  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-3">
        <CardTitle className="text-lg font-bold flex items-center">
          <Users className="mr-2 h-5 w-5" />
          Patients Overview
        </CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        <div className="space-y-2">
          {patients.map((patient, index) => (
            <PatientCard key={index} patient={patient} />
          ))}
        </div>
        <Button variant="outline" size="sm" className="w-full mt-4 text-blue-600 border-blue-600 hover:bg-blue-50 dark:text-blue-400 dark:border-blue-400 dark:hover:bg-blue-900/20">
          View All Patients
        </Button>
      </CardContent>
    </Card>
  )
}