import React, { useState } from 'react';
import {
  DollarSign,
  Settings,
  Plus,
  AlertCircle,
  Edit2,
  Trash2,
  Check,
  X,
  FileText,
  Clock,
  Clipboard,
  ToggleLeft,
  CreditCard
} from 'lucide-react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent
} from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';

const InsuranceSettings = () => {
  const [activeTab, setActiveTab] = useState('general');
  const [showInsuranceQuestions, setShowInsuranceQuestions] = useState(false);
  const [stripeKey, setStripeKey] = useState('');

  // Sample insurance plans data
  const [insurancePlans] = useState([
    {
      id: 1,
      name: "Blue Cross Blue Shield",
      type: "Commercial",
      status: "active",
      contractEffective: "2024-01-01",
      contractExpiration: "2024-12-31",
      rates: {
        "90791": 175.00,
        "96130": 150.00,
        "96131": 150.00,
        "96136": 125.00,
        "96137": 125.00
      }
    },
    {
      id: 2,
      name: "Aetna",
      type: "Commercial",
      status: "active",
      contractEffective: "2024-01-01",
      contractExpiration: "2024-12-31"
    }
  ]);

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Payments Accepted */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <DollarSign className="mr-2" size={20} />
            Payments Accepted
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span>Credit Cards (inc. HSA)</span>
              <div className="flex space-x-2">
                <Input
                  placeholder="Enter Stripe Merchant Key"
                  value={stripeKey}
                  onChange={(e) => setStripeKey(e.target.value)}
                  className="w-64"
                />
                <Button>Setup Platform Processing</Button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span>Cash</span>
              <Check className="text-green-600" size={20} />
            </div>
            <div className="flex items-center justify-between">
              <span>Insurance</span>
              <Button variant="outline" onClick={() => setShowInsuranceQuestions(!showInsuranceQuestions)}>
                {showInsuranceQuestions ? 'Hide' : 'Show'} Insurance Questions
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {showInsuranceQuestions && (
        <>
          {/* General Insurance Settings */}
          <Card className="mb-6">
            <CardHeader>
              <CardTitle className="text-xl font-semibold flex items-center">
                <Settings className="mr-2" size={20} />
                General Insurance Settings
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Practice Information */}
                <div className="space-y-4">
                  <h3 className="font-medium mb-2">Practice Information</h3>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span>NPI (Type 2)</span>
                      <button className="text-blue-600 hover:text-blue-700">
                        <Edit2 size={16} />
                      </button>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Tax ID</span>
                      <button className="text-blue-600 hover:text-blue-700">
                        <Edit2 size={16} />
                      </button>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Medicare PTAN</span>
                      <button className="text-blue-600 hover:text-blue-700">
                        <Edit2 size={16} />
                      </button>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Medicaid Provider Number</span>
                      <button className="text-blue-600 hover:text-blue-700">
                        <Edit2 size={16} />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Default Billing Settings */}
                <div className="space-y-4">
                  <h3 className="font-medium mb-2">Default Billing Settings</h3>
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <span>Default to Insurance Billing</span>
                      <ToggleLeft className="text-blue-600" size={20} />
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Collect Copay at Time of Service</span>
                      <ToggleLeft className="text-blue-600" size={20} />
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Auto-verify Benefits</span>
                      <ToggleLeft className="text-blue-600" size={20} />
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Secondary Insurance Allowed</span>
                      <ToggleLeft className="text-blue-600" size={20} />
                    </div>
                  </div>
                </div>

                {/* Clearinghouse Settings */}
                <div className="space-y-4">
                  <h3 className="font-medium mb-2">Clearinghouse Integration</h3>
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <span>Office Ally</span>
                      <button className="text-blue-600 hover:text-blue-700">Configure</button>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Claim Submission Format</span>
                      <select className="border rounded p-1">
                        <option>CMS-1500</option>
                        <option>Electronic 837P</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Document Settings */}
                <div className="space-y-4">
                  <h3 className="font-medium mb-2">Document Settings</h3>
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <span>Auto-generate Superbills</span>
                      <ToggleLeft className="text-blue-600" size={20} />
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Default Statement Format</span>
                      <select className="border rounded p-1">
                        <option>Detailed</option>
                        <option>Summary</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Insurance Plans */}
          <Card className="mb-6">
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle className="text-xl font-semibold flex items-center">
                  <CreditCard className="mr-2" size={20} />
                  Contracted Insurance Plans
                </CardTitle>
                <button className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center">
                  <Plus size={16} className="mr-1" />
                  Add Insurance Plan
                </button>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {insurancePlans.map((plan) => (
                  <div key={plan.id} className="border rounded-lg p-4">
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <h3 className="font-medium">{plan.name}</h3>
                        <span className="text-sm text-gray-600">
                          Contract: {plan.contractEffective} - {plan.contractExpiration}
                        </span>
                      </div>
                      <div className="space-x-2">
                        <button className="text-blue-600 hover:text-blue-700">
                          <Edit2 size={16} />
                        </button>
                        <button className="text-red-600 hover:text-red-700">
                          <Trash2 size={16} />
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                      <div>
                        <span className="text-sm text-gray-600">Status</span>
                        <p className="font-medium">{plan.status}</p>
                      </div>
                      <div>
                        <span className="text-sm text-gray-600">Type</span>
                        <p className="font-medium">{plan.type}</p>
                      </div>
                      <div>
                        <span className="text-sm text-gray-600">Fee Schedule</span>
                        <button className="text-blue-600 hover:text-blue-700 text-sm">
                          View Rates
                        </button>
                      </div>
                      <div>
                        <span className="text-sm text-gray-600">Documents</span>
                        <button className="text-blue-600 hover:text-blue-700 text-sm">
                          View Contract
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* CPT Code Configuration */}
          <Card>
            <CardHeader>
              <CardTitle className="text-xl font-semibold flex items-center">
                <FileText className="mr-2" size={20} />
                CPT Code Configuration
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-medium">Assessment CPT Codes</h3>
                  <button className="text-blue-600 hover:text-blue-700 flex items-center">
                    <Plus size={16} className="mr-1" />
                    Add Code
                  </button>
                </div>
                <table className="w-full">
                  <thead>
                    <tr className="text-left">
                      <th className="pb-2">CPT Code</th>
                      <th className="pb-2">Description</th>
                      <th className="pb-2">Default Rate</th>
                      <th className="pb-2">Status</th>
                      <th className="pb-2"></th>
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    <tr>
                      <td className="py-2">90791</td>
                      <td>Psychiatric Diagnostic Evaluation</td>
                      <td>$200.00</td>
                      <td>Active</td>
                      <td>
                        <button className="text-blue-600 hover:text-blue-700">
                          <Edit2 size={16} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">96130</td>
                      <td>Psychological Testing - First Hour</td>
                      <td>$175.00</td>
                      <td>Active</td>
                      <td>
                        <button className="text-blue-600 hover:text-blue-700">
                          <Edit2 size={16} />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

export default InsuranceSettings; // Ensure this is a default export
