import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Progress } from "../components/ui/progress";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Checkbox } from "../components/ui/checkbox";
import { Label } from "../components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { CheckCircle, Clock, AlertCircle, Eye, Plus, Edit, Play, FileText, Calendar, Send, MessageSquare, Upload, User, AlertTriangle, BrainCircuit, Mail, Share2, Download, Printer, ExternalLink, X, GripVertical, Copy, ChevronDown, ChevronRight, Paperclip, Contact, Phone, UserPlus, Search, List, Grid, Filter, Pen, Settings, Info, RefreshCw, XCircle, Trash2, Edit2, Heart, Users, Book, Activity, ChevronUp } from 'lucide-react';
import { Input } from "../components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "../components/ui/dialog";
import { Textarea } from "../components/ui/textarea";
import { Switch } from "../components/ui/switch";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import PatientBillingSection from '../components/PatientBillingSection'; // Adjust the path as needed

// Types
type SessionStatus = 'completed' | 'in-progress' | 'not-started' | 'scheduled';

type SessionItem = {
  id: string;
  title: string;
  status: SessionStatus;
  date?: string;
  isTitle?: boolean;
  children?: SessionItem[];
};

type ConsentForm = {
  id: string;
  title: string;
  status: 'completed' | 'not-completed';
  viewed: boolean;
  processed: boolean; // New property for processed status
};

type AssessmentItem = {
  id: string;
  title: string;
  status: 'complete' | 'in-progress' | 'not-started' | 'pending';
};

// Initial data
const initialSessions: SessionItem[] = [
  { id: '0', title: 'Consult Call', status: 'completed', date: '2023-04-15' },
  { id: '1', title: 'Intake Session', status: 'completed', date: '2023-05-01' },
  { 
    id: '2', 
    title: 'Testing Session 1', 
    isTitle: true,
    status: 'in-progress',
    children: [
      { id: '2-1', title: 'ADOS', status: 'not-started' },
      { id: '2-2', title: 'BAILEY', status: 'not-started' },
      { id: '2-3', title: 'KBIT-2', status: 'not-started' },
    ]
  },
  { 
    id: '3', 
    title: 'ASYNC Testing', 
    isTitle: true,
    status: 'in-progress',
    children: [
      { id: '3-1', title: 'ASYNC Test 003', status: 'not-started' },
    ]
  },
  { 
    id: '4', 
    title: 'Ad-Hoc', 
    isTitle: true,
    status: 'not-started',
    children: [
      { id: '4-1', title: 'School Observation', status: 'not-started' },
    ]
  },
  { id: '5', title: 'Feedback Session', status: 'not-started' },
  { id: '6', title: 'Report Delivery', status: 'not-started' },
];

const consentForms: ConsentForm[] = [
  { id: '1', title: 'Basic Information', status: 'completed', viewed: true, processed: true },
  { id: '2', title: 'HIPAA Consent', status: 'completed', viewed: false, processed: true },
  { id: '3', title: 'Release Of Information', status: 'not-completed', viewed: false, processed: false },
  { id: '4', title: 'Billing Information', status: 'completed', viewed: true, processed: true },
  { id: '5', title: 'Guardian Form', status: 'not-completed', viewed: false, processed: false },
  { id: '6', title: 'CONNORS 4 PARENT', status: 'completed', viewed: true, processed: true },
  { id: '7', title: 'CONNORS 4 TEACHER', status: 'completed', viewed: true, processed: true },
  { id: '8', title: 'CONNORS 4 SELF', status: 'not-completed', viewed: false, processed: false },
  { id: '9', title: 'VANDERBILT', status: 'completed', viewed: true, processed: true },
  { id: '10', title: 'RSMAS', status: 'not-completed', viewed: false, processed: false },
];

const assessmentInventory: AssessmentItem[] = [
  { id: '1', title: 'Referral Letter', status: 'complete' },
  { id: '2', title: 'Connorsy Parent', status: 'complete' },
  { id: '3', title: 'Connorsy Teacher', status: 'in-progress' },
  { id: '4', title: 'KBIT-2 Results', status: 'complete' },
  { id: '5', title: 'SCARED Results', status: 'not-started' },
  { id: '6', title: 'VANDERBILT Results', status: 'not-started' },
  { id: '7', title: 'Classroom Observation Notes', status: 'pending' },
];

// Add this new array for AI Insights
const aiInsights = [
  { 
    id: '1', 
    content: "KBIT-2 results show a significant discrepancy between verbal comprehension and processing speed. Consider additional assessments for learning disabilities.",
    type: 'warning'
  },
  {
    id: '2',
    content: "Parent BASC scores are pending and may impact final diagnosis. Ensure completion before feedback session.",
    type: 'alert'
  },
  {
    id: '3',
    content: "Tyler's scores on sustained attention tasks improved with breaks. Consider recommending regular movement breaks in classroom.",
    type: 'info'
  },
];

// Helper components
const StatusIcon = ({ status }) => {
  switch (status) {
    case 'completed':
    case 'complete':
      return <CheckCircle className="h-5 w-5 text-green-500" />;
    case 'in-progress':
      return <Clock className="h-5 w-5 text-blue-500" />;
    case 'scheduled':
    case 'pending':
      return <Calendar className="h-5 w-5 text-yellow-500" />;
    default:
      return <AlertCircle className="h-5 w-5 text-gray-400" />;
  }
};

const ProcessedIcon = ({ processed }) => (
  processed ? <CheckCircle className="h-5 w-5 text-green-500" /> : <XCircle className="h-5 w-5 text-red-500" />
);

const QuickActionButton = ({ icon: Icon, label, onClick }) => (
  <Button 
    variant="outline" 
    className="flex flex-col items-center justify-center h-20 w-full border-2 border-black"
    onClick={onClick}
  >
    <Icon className="h-6 w-6 mb-1" />
    <span className="text-xs">{label}</span>
  </Button>
);

function CurrentAssessment() {
  const [sessions, setSessions] = useState(initialSessions);
  const [collapsedSections, setCollapsedSections] = useState({});

  const toggleSection = (id) => {
    setCollapsedSections(prev => ({...prev, [id]: !prev[id]}));
  };

  return (
    <Card className="mb-4">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-xl font-bold">Current Assessment</CardTitle>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" /> Add Session
        </Button>
      </CardHeader>
      <CardContent className="pt-0">
        <div className="mb-2 flex items-center space-x-2">
          <span className="text-sm font-medium">Overall Progress:</span>
          <Progress value={65} className="w-64" />
          <span className="text-sm font-medium">65%</span>
        </div>
        <div className="space-y-4">
          {sessions.map(session => (
            <div key={session.id} className="bg-white border rounded-lg p-4 shadow-sm">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  {session.isTitle && (
                    <Button variant="ghost" size="sm" onClick={() => toggleSection(session.id)}>
                      {collapsedSections[session.id] ? <ChevronRight className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                    </Button>
                  )}
                  <GripVertical className="h-5 w-5 text-gray-400" />
                  <span className={`font-medium ${session.isTitle ? 'text-lg' : ''}`}>
                    {session.title}
                    {(session.title === 'Consult Call' || session.title === 'Intake Session' || session.title === 'Feedback Session' || session.title === 'Report Delivery') && (
                      <span className="ml-2 text-sm text-gray-500">
                        (Dr. Berens) <Edit className="inline h-3 w-3 ml-1" />
                      </span>
                    )}
                    {session.title === 'Testing Session 1' && (
                      <span className="ml-2 text-sm text-gray-500">
                        (Clinician) <Edit className="inline h-3 w-3 ml-1" />
                      </span>
                    )}
                  </span>
                </div>
                {session.status === 'completed' ? (
                  <div className="flex items-center space-x-2">
                    <StatusIcon status={session.status} />
                    <Button variant="ghost" size="sm">
                      <Plus className="h-4 w-4" />
                    </Button>
                    <Button variant="ghost" size="sm">
                      <Eye className="h-4 w-4" />
                    </Button>
                  </div>
                ) : session.isTitle ? (
                  session.title === 'ASYNC Testing' ? (
                    <Button variant="ghost" size="sm">
                      <Send className="h-4 w-4" />
                    </Button>
                  ) : (
                    <Button variant="ghost" size="sm">
                      <Plus className="h-4 w-4" />
                    </Button>
                  )
                ) : session.title === 'Feedback Session' ? (
                  <div>
                    <Button variant="ghost" size="sm">
                      <Play className="h-4 w-4" />
                    </Button>
                    <Button variant="ghost" size="sm">
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                ) : session.title === 'Report Delivery' ? (
                  <div>
                    <Button variant="ghost" size="sm">
                      <FileText className="h-4 w-4" />
                    </Button>
                    <Button variant="ghost" size="sm">
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ) : (
                  <StatusIcon status={session.status} />
                )}
              </div>
              {session.isTitle && !collapsedSections[session.id] && (
                <div className="ml-7 space-y-2">
                  {session.title === 'Ad-Hoc' ? (
                    <div className="text-sm text-gray-500">
                      Not scheduled
                      <Button variant="ghost" size="sm" className="ml-2">
                        <Calendar className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : session.title === 'ASYNC Testing' ? (
                    <div className="text-sm text-gray-500">
                      Waiting For Patient
                      <Button variant="ghost" size="sm" className="ml-2">
                        <Calendar className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : (
                    <div className="text-sm text-gray-500">
                      Scheduled: June 14th @ 9am
                      <Button variant="ghost" size="sm" className="ml-2">
                        <Calendar className="h-4 w-4" />
                      </Button>
                    </div>
                  )}
                  {session.children?.map(child => (
                    <div key={child.id} className="flex items-center justify-between">
                      <span>{child.title}</span>
                      <div>
                        <Button variant="ghost" size="sm">
                          <Play className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm">
                          <Plus className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

function DocumentPreview() {
  const [reportView, setReportView] = useState('stakeholder');
  const [reportFormat, setReportFormat] = useState('full');

  return (
    <Card className="mb-4 bg-white">
      <CardHeader className="pb-2">
        <CardTitle className="text-xl font-bold">Document Preview</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex space-x-4">
          <div className="w-2/3">
            <div className="bg-gray-100 border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
              <img
                src="/images/Report-Preview.png"
                alt="Report Preview"
                className="w-full h-64 object-cover"
              />
            </div>
            <div className="flex justify-between">
              <Button variant="outline" size="sm">
                <Eye className="h-4 w-4 mr-2" />
                View
              </Button>
              <Button variant="outline" size="sm">
                <Edit className="h-4 w-4 mr-2" />
                Edit
              </Button>
            </div>
          </div>
          <div className="w-1/3 space-y-4">
            <div>
              <Label htmlFor="reportView">Stakeholder</Label>
              <Select value={reportView} onValueChange={setReportView}>
                <SelectTrigger id="reportView">
                  <SelectValue placeholder="Select view" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="stakeholder">Stakeholder</SelectItem>
                  <SelectItem value="parent">Parent</SelectItem>
                  <SelectItem value="child">Child</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div>
              <Label htmlFor="reportFormat">Report Type</Label>
              <Select value={reportFormat} onValueChange={setReportFormat}>
                <SelectTrigger id="reportFormat">
                  <SelectValue placeholder="Select format" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="full">Full Report</SelectItem>
                  <SelectItem value="summary">Summary</SelectItem>
                  <SelectItem value="cover">Cover Letter</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex space-x-2">
              <Button variant="outline" size="sm" className="flex-1">
                <Download className="h-4 w-4 mr-2" />
                Download
              </Button>
              <Button variant="outline" size="sm" className="flex-1">
                <Share2 className="h-4 w-4 mr-2" />
                Share
              </Button>
            </div>
            {/* New box with theme and type information */}
            <div className="border border-black rounded-lg p-4 mt-4">
              <div className="mb-2">
                <span className="font-semibold">Theme:</span> Custom
              </div>
              <div className="mb-4">
                <span className="font-semibold">Type:</span> Diagnostic
              </div>
              <Link to="/settings/myPreferences/myReports">
                <Button variant="outline" size="sm" className="w-full">
                  Report Settings
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function AIInsights() {
  return (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle className="text-xl font-bold">AI Insights</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {aiInsights.map((insight) => (
            <li key={insight.id} className="bg-white dark:bg-gray-700 p-2 rounded-lg shadow-sm border border-gray-200 dark:border-gray-600">
              <div className="flex items-start space-x-2">
                {insight.type === 'info' && <BrainCircuit className="h-5 w-5 text-blue-500 mt-1" />}
                {insight.type === 'warning' && <AlertTriangle className="h-5 w-5 text-yellow-500 mt-1" />}
                {insight.type === 'alert' && <AlertCircle className="h-5 w-5 text-red-500 mt-1" />}
                <p className="text-sm">{insight.content}</p>
              </div>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

function PatientPortal() {
  // Add this new data for Audit Log and Document Activity
  const auditLog = [
    { id: 1, action: "Logged in", timestamp: "2024-05-01 09:30:15" },
    { id: 2, action: "Updated profile information", timestamp: "2024-05-01 09:35:22" },
    { id: 3, action: "Viewed assessment results", timestamp: "2024-05-01 10:15:07" },
    { id: 4, action: "Scheduled appointment", timestamp: "2024-05-01 11:20:33" },
    { id: 5, action: "Downloaded report", timestamp: "2024-05-02 14:45:19" },
  ];

  const documentActivity = [
    { id: 1, document: "Consent Form", action: "Signed", timestamp: "2024-04-30 15:20:11" },
    { id: 2, document: "Intake Questionnaire", action: "Submitted", timestamp: "2024-04-30 16:05:47" },
    { id: 3, document: "Assessment Report", action: "Viewed", timestamp: "2024-05-01 10:15:07" },
    { id: 4, document: "Treatment Plan", action: "Downloaded", timestamp: "2024-05-02 14:45:19" },
    { id: 5, document: "Progress Notes", action: "Accessed", timestamp: "2024-05-03 11:30:22" },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl font-bold">Patient Portal</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="font-medium">Status:</span>
            <span className="text-green-500 font-medium">Active</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-medium">Last Login:</span>
            <span>May 1, 2024</span>
          </div>
          <div className="space-y-2">
            <Button className="w-full">Manage Access</Button>
            <Button variant="outline" className="w-full">Login History</Button>
          </div>
          <div>
            <h4 className="font-medium mb-2">Permissions:</h4>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <Checkbox id="schedule" />
                <Label htmlFor="schedule">Can schedule appointments</Label>
              </li>
              <li className="flex items-center space-x-2">
                <Checkbox id="view-docs" />
                <Label htmlFor="view-docs">Can view documents</Label>
              </li>
              <li className="flex items-center space-x-2">
                <Checkbox id="send-messages" />
                <Label htmlFor="send-messages">Can send messages</Label>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">Audit Log</h3>
            <div className="border rounded-lg overflow-hidden">
              <div className="max-h-60 overflow-y-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {auditLog.map((log) => (
                      <tr key={log.id}>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">{log.action}</td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{log.timestamp}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Document Activity</h3>
            <div className="border rounded-lg overflow-hidden">
              <div className="max-h-60 overflow-y-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Document</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {documentActivity.map((activity) => (
                      <tr key={activity.id}>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">{activity.document}</td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">{activity.action}</td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{activity.timestamp}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function TaskManager() {
  const [tasks, setTasks] = useState([
    { id: 1, text: 'Review patient history', status: 'todo', assignedTo: 'Me', dueDate: '2023-06-10' },
    { id: 2, text: 'Schedule follow-up appointment', status: 'todo', assignedTo: 'Dr. Smith', dueDate: '2023-06-15' },
    { id: 3, text: 'Complete assessment report', status: 'done', assignedTo: 'Me', dueDate: '2023-06-05' },
  ]);
  const [newTask, setNewTask] = useState('');
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [assignedTo, setAssignedTo] = useState('Me');
  const [dueDate, setDueDate] = useState('');

  const addTask = (e) => {
    e.preventDefault();
    if (newTask.trim()) {
      setTasks([...tasks, { 
        id: tasks.length + 1, 
        text: newTask, 
        status: 'todo',
        assignedTo: assignedTo,
        dueDate: dueDate
      }]);
      setNewTask('');
      setAssignedTo('Me');
      setDueDate('');
      setIsAdvancedOpen(false);
    }
  };

  const toggleTaskStatus = (id) => {
    setTasks(tasks.map(task => 
      task.id === id ? { ...task, status: task.status === 'todo' ? 'done' : 'todo' } : task
    ));
  };

  return (
    <Card className="mb-4 bg-white">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Task Manager</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="todo" className="w-full">
          <TabsList>
            <TabsTrigger value="todo">To Do</TabsTrigger>
            <TabsTrigger value="done">Done</TabsTrigger>
          </TabsList>
          <TabsContent value="todo">
            <ul className="space-y-2">
              {tasks.filter(task => task.status === 'todo').map(task => (
                <li key={task.id} className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Checkbox id={`task-${task.id}`} checked={false} onCheckedChange={() => toggleTaskStatus(task.id)} />
                    <Label htmlFor={`task-${task.id}`}>{task.text}</Label>
                  </div>
                  <div className="text-sm text-gray-500">
                    <span>{task.assignedTo}</span>
                    <span className="mx-2">|</span>
                    <span>{task.dueDate}</span>
                  </div>
                </li>
              ))}
            </ul>
          </TabsContent>
          <TabsContent value="done">
            <ul className="space-y-2">
              {tasks.filter(task => task.status === 'done').map(task => (
                <li key={task.id} className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Checkbox id={`task-${task.id}`} checked={true} onCheckedChange={() => toggleTaskStatus(task.id)} />
                    <Label htmlFor={`task-${task.id}`} className="line-through">{task.text}</Label>
                  </div>
                  <div className="text-sm text-gray-500">
                    <span>{task.assignedTo}</span>
                    <span className="mx-2">|</span>
                    <span>{task.dueDate}</span>
                  </div>
                </li>
              ))}
            </ul>
          </TabsContent>
        </Tabs>
        <div className="mt-4">
          <Dialog>
            <DialogTrigger asChild>
              <Button className="w-full">
                <Plus className="mr-2 h-4 w-4" /> Add Task
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add New Task</DialogTitle>
              </DialogHeader>
              <form onSubmit={addTask} className="space-y-4">
                <div className="relative">
                  <Textarea
                    placeholder="Enter task"
                    value={newTask}
                    onChange={(e) => setNewTask(e.target.value)}
                    rows={3}
                    className="pr-10"
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    className="absolute bottom-2 right-2"
                  >
                    <Paperclip className="h-4 w-4" />
                  </Button>
                </div>
                {isAdvancedOpen && (
                  <>
                    <Select value={assignedTo} onValueChange={setAssignedTo}>
                      <SelectTrigger>
                        <SelectValue placeholder="Assigned To" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Me">Me</SelectItem>
                        <SelectItem value="Dr. Smith">Dr. Smith</SelectItem>
                        <SelectItem value="Nurse Johnson">Nurse Johnson</SelectItem>
                      </SelectContent>
                    </Select>
                    <Input
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                    />
                  </>
                )}
                <div className="flex justify-between">
                  <Button type="button" variant="outline" onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}>
                    {isAdvancedOpen ? 'Simple' : 'Advanced'}
                  </Button>
                  <Button type="submit">Add Task</Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  );
}

const ContactModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Contact Information</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <User className="h-5 w-5" />
            <span>Tyler Smith</span>
          </div>
          <div className="flex items-center gap-4">
            <Mail className="h-5 w-5" />
            <span>tyler.smith@example.com</span>
            <Button size="sm">
              Send Message
            </Button>
          </div>
          <div className="flex items-center gap-4">
            <Phone className="h-5 w-5" />
            <span>(555) 123-4567</span>
            <Button size="sm">
              Connect
            </Button>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// New components for Patient Profile and Stakeholders
const PatientProfile = () => {
  const [expandedSections, setExpandedSections] = useState({
    basic: true,
    guardian: true,
    contacts: true,
    education: true,
    medical: true,
    family: true,
    assessments: true
  });

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Component for showing data source
  const DataSource = ({ source, date }) => (
    <div className="inline-flex items-center text-xs text-gray-500 ml-2">
      <Info size={12} className="mr-1" />
      <span>{source}</span>
      {date && <span className="ml-1">({date})</span>}
    </div>
  );

  // Component for editable field
  const EditableField = ({ label, value, source, date }) => (
    <div className="mb-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span className="font-medium text-sm">{label}:</span>
          <DataSource source={source} date={date} />
        </div>
        <button className="p-1 hover:bg-gray-100 rounded">
          <Edit2 size={14} className="text-blue-600" />
        </button>
      </div>
      <div className="mt-1 text-gray-800">{value}</div>
    </div>
  );

  // Section header component
  const SectionHeader = ({ title, section, icon: Icon }) => (
    <div 
      className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50"
      onClick={() => toggleSection(section)}
    >
      <div className="flex items-center">
        <Icon size={20} className="mr-2 text-blue-600" />
        <h3 className="font-semibold">{title}</h3>
      </div>
      {expandedSections[section] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
    </div>
  );

  return (
    <div className="space-y-6 max-w-4xl mx-auto">
      {/* Basic Information */}
      <Card>
        <SectionHeader title="Basic Information" section="basic" icon={User} />
        {expandedSections.basic && (
          <CardContent>
            <div className="grid grid-cols-2 gap-6">
              <EditableField 
                label="Full Name"
                value="John Michael Doe"
                source="Initial Intake Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Date of Birth"
                value="1990-05-15"
                source="Initial Intake Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Preferred Name"
                value="Johnny"
                source="Initial Meeting Notes"
                date="2024-01-20"
              />
              <EditableField 
                label="Pronouns"
                value="He/Him"
                source="Initial Intake Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Primary Phone"
                value="+1 (555) 123-4567"
                source="Initial Intake Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Email"
                value="john.doe@email.com"
                source="Initial Intake Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Communication Preferences"
                value="Email primary, Text for reminders"
                source="Communication Preferences Form"
                date="2024-01-15"
              />
            </div>
          </CardContent>
        )}
      </Card>

      {/* Guardian Information */}
      <Card>
        <SectionHeader title="Guardian Information" section="guardian" icon={Users} />
        {expandedSections.guardian && (
          <CardContent>
            <div className="flex justify-end mb-4">
              <button className="flex items-center text-blue-600 hover:text-blue-700">
                <Plus size={16} className="mr-1" />
                Add Guardian
              </button>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <EditableField 
                label="Primary Guardian"
                value="Sarah Doe"
                source="Guardian Authorization Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Relationship"
                value="Mother"
                source="Guardian Authorization Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Legal Status"
                value="Full Legal Guardian"
                source="Legal Documentation"
                date="2024-01-15"
              />
              <EditableField 
                label="Contact Sharing"
                value="Authorized for all communications"
                source="Communication Preferences Form"
                date="2024-01-15"
              />
            </div>
          </CardContent>
        )}
      </Card>

      {/* Education/Employment */}
      <Card>
        <SectionHeader title="Education/Employment" section="education" icon={Book} />
        {expandedSections.education && (
          <CardContent>
            <div className="grid grid-cols-2 gap-6">
              <EditableField 
                label="Current School"
                value="Lincoln High School"
                source="Educational Background Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Grade Level"
                value="10th Grade"
                source="Educational Background Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Academic Performance"
                value="B Average"
                source="Teacher Assessment Form"
                date="2024-02-01"
              />
              <EditableField 
                label="Special Education Status"
                value="IEP in place"
                source="School Records"
                date="2024-01-20"
              />
            </div>
          </CardContent>
        )}
      </Card>

      {/* Medical History */}
      <Card>
        <SectionHeader title="Medical Information" section="medical" icon={Heart} />
        {expandedSections.medical && (
          <CardContent>
            <div className="grid grid-cols-2 gap-6">
              <EditableField 
                label="Current Medications"
                value="Methylphenidate 20mg daily"
                source="Medication History Form"
                date="2024-03-01"
              />
              <EditableField 
                label="Allergies"
                value="None reported"
                source="Medical History Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Previous Diagnoses"
                value="ADHD (2023)"
                source="Previous Records"
                date="2024-01-15"
              />
              <EditableField 
                label="Primary Care Physician"
                value="Dr. Sarah Johnson"
                source="Medical Release Form"
                date="2024-01-15"
              />
            </div>
          </CardContent>
        )}
      </Card>

      {/* Family Information */}
      <Card>
        <SectionHeader title="Family Information" section="family" icon={Users} />
        {expandedSections.family && (
          <CardContent>
            <div className="grid grid-cols-2 gap-6">
              <EditableField 
                label="Family Structure"
                value="Parents divorced, shared custody"
                source="Family History Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Siblings"
                value="2 (ages 8 and 12)"
                source="Family History Form"
                date="2024-01-15"
              />
              <EditableField 
                label="Living Situation"
                value="Alternates between parents weekly"
                source="Initial Assessment Notes"
                date="2024-01-20"
              />
              <EditableField 
                label="Family Mental Health History"
                value="Mother: Anxiety, Father: None reported"
                source="Family History Form"
                date="2024-01-15"
              />
            </div>
          </CardContent>
        )}
      </Card>

      {/* Assessment History */}
      <Card>
        <SectionHeader title="Assessment History" section="assessments" icon={Activity} />
        {expandedSections.assessments && (
          <CardContent>
            <div className="space-y-4">
              <div className="flex justify-between items-center p-3 bg-gray-50 rounded">
                <div>
                  <h4 className="font-medium">ADHD Assessment</h4>
                  <p className="text-sm text-gray-600">Completed on March 15, 2024</p>
                </div>
                <button className="text-blue-600 hover:text-blue-700">View Details</button>
              </div>
              <div className="flex justify-between items-center p-3 bg-gray-50 rounded">
                <div>
                  <h4 className="font-medium">Anxiety Screening</h4>
                  <p className="text-sm text-gray-600">Completed on February 1, 2024</p>
                </div>
                <button className="text-blue-600 hover:text-blue-700">View Details</button>
              </div>
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

function AddStakeholderModal({ isOpen, onClose }) {
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [quickNotes, setQuickNotes] = useState('');
  const [accessEntireReport, setAccessEntireReport] = useState(false);
  const [accessStakeholderReport, setAccessStakeholderReport] = useState(false);
  const [stakeholderReportType, setStakeholderReportType] = useState('');
  const [releaseOfInformation, setReleaseOfInformation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Add Stakeholder</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Start typing to search or add new..."
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="relationship">Relationship</Label>
            <Select value={relationship} onValueChange={setRelationship}>
              <SelectTrigger id="relationship">
                <SelectValue placeholder="Select relationship" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="parent">Parent</SelectItem>
                <SelectItem value="teacher">Teacher</SelectItem>
                <SelectItem value="physician">Physician</SelectItem>
                <SelectItem value="therapist">Therapist</SelectItem>
                {/* Add more options as needed */}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="phone">Phone</Label>
            <Input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="quickNotes">Quick Notes</Label>
            <Textarea
              id="quickNotes"
              value={quickNotes}
              onChange={(e) => setQuickNotes(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label>Access to Report</Label>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="accessEntireReport"
                checked={accessEntireReport}
                onCheckedChange={setAccessEntireReport}
              />
              <Label htmlFor="accessEntireReport">Entire Report</Label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="accessStakeholderReport"
                checked={accessStakeholderReport}
                onCheckedChange={setAccessStakeholderReport}
              />
              <Label htmlFor="accessStakeholderReport">Stakeholder Report</Label>
            </div>
            {accessStakeholderReport && (
              <Select value={stakeholderReportType} onValueChange={setStakeholderReportType}>
                <SelectTrigger id="stakeholderReportType">
                  <SelectValue placeholder="Select stakeholder report type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="teacher">Teacher</SelectItem>
                  <SelectItem value="physician">Physician</SelectItem>
                  <SelectItem value="therapist">Therapist</SelectItem>
                  {/* Add more options as needed */}
                </SelectContent>
              </Select>
            )}
          </div>
          <Card className="bg-yellow-50 border-yellow-200">
            <CardContent className="p-4">
              <div className="flex items-center space-x-2 mb-2">
                <AlertTriangle className="h-5 w-5 text-yellow-500" />
                <span className="font-semibold">Release of Information</span>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="releaseOfInformation"
                  checked={releaseOfInformation}
                  onCheckedChange={setReleaseOfInformation}
                />
                <Label htmlFor="releaseOfInformation">Release of Information already on file</Label>
              </div>
              {!releaseOfInformation && (
                <p className="text-sm text-yellow-600 mt-2">
                  If not checked, a release will be sent to the patient.
                </p>
              )}
            </CardContent>
          </Card>
          <DialogFooter>
            <Button type="submit">Add Stakeholder</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const Stakeholders = () => {
  // ... (previous state and functions)

  const stakeholders = [
    { id: 1, name: 'John Smith', role: 'School Principal', hasROI: true },
    { id: 2, name: 'Sarah Lee', role: 'Social Worker', hasROI: false },
    { id: 3, name: 'Mike Johnson', role: 'Pediatrician', hasROI: true },
  ];

  const handleViewROI = (stakeholderId) => {
    console.log(`Viewing ROI for stakeholder ${stakeholderId}`);
    // Implement logic to view ROI
  };

  const handleSendROI = (stakeholderId) => {
    console.log(`Sending ROI for stakeholder ${stakeholderId}`);
    // Implement logic to send ROI
  };

  const renderROIStatus = (stakeholder) => (
    <div className="flex items-center space-x-2">
      {stakeholder.hasROI ? (
        <>
          <CheckCircle className="h-5 w-5 text-green-500" />
          <span className="text-green-500">ROI On File</span>
          <Button variant="link" size="sm" onClick={() => handleViewROI(stakeholder.id)}>
            <Eye className="h-4 w-4 mr-1" />
            View
          </Button>
        </>
      ) : (
        <>
          <XCircle className="h-5 w-5 text-red-500" />
          <span className="text-red-500">ROI Not On File</span>
          <Button variant="link" size="sm" onClick={() => handleSendROI(stakeholder.id)}>
            <Send className="h-4 w-4 mr-1" />
            Send
          </Button>
        </>
      )}
    </div>
  );

  return (
    <div className="flex gap-4">
      <div className="w-2/3 space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Stakeholders</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Role</TableHead>
                  <TableHead>ROI Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {stakeholders.map((stakeholder) => (
                  <TableRow key={stakeholder.id}>
                    <TableCell>{stakeholder.name}</TableCell>
                    <TableCell>{stakeholder.role}</TableCell>
                    <TableCell>{renderROIStatus(stakeholder)}</TableCell>
                    <TableCell>
                      <div className="flex space-x-2">
                        <Button variant="ghost" size="sm" onClick={() => handleEdit(stakeholder)}>
                          <Edit className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm" onClick={() => handleDelete(stakeholder.id)}>
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
      {/* ... (rest of the component) */}
    </div>
  );
};

const DocumentsTab = () => {
  const documents = [
    { id: 1, name: 'Intake Form', type: 'Form', status: 'Completed', source: 'Patient', date: '2023-05-01', assessment: 'Initial Assessment' },
    { id: 2, name: 'Medical History', type: 'Upload', status: 'Completed', source: 'Clinician', date: '2023-05-02', assessment: 'Initial Assessment' },
    { id: 3, name: 'Consent Form', type: 'Form', status: 'Waiting', source: 'Patient', date: '2023-05-03', assessment: null },
    { id: 4, name: 'School Report', type: 'Upload', status: 'Completed', source: 'Stakeholder', date: '2023-05-04', assessment: 'ADHD Assessment' },
    { id: 5, name: 'Treatment Plan', type: 'Document', status: 'Completed', source: 'Clinician', date: '2023-05-05', assessment: 'ADHD Assessment' },
  ];

  const auditTrail = [
    { id: 1, action: 'Uploaded', document: 'Medical History', user: 'Dr. Smith', date: '2023-05-02 10:30 AM' },
    { id: 2, action: 'Sent', document: 'Consent Form', user: 'Nurse Johnson', date: '2023-05-03 2:15 PM' },
    { id: 3, action: 'Viewed', document: 'School Report', user: 'Dr. Smith', date: '2023-05-04 11:45 AM' },
    { id: 4, action: 'Created', document: 'Treatment Plan', user: 'Dr. Smith', date: '2023-05-05 3:30 PM' },
  ];

  return (
    <div className="flex gap-4">
      <div className="w-2/3 space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Patient Documents</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Source</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Assessment</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {documents.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableCell>{doc.name}</TableCell>
                    <TableCell>{doc.type}</TableCell>
                    <TableCell>
                      {doc.status === 'Completed' ? (
                        <span className="text-green-500 flex items-center">
                          <CheckCircle className="w-4 h-4 mr-1" /> Completed
                        </span>
                      ) : (
                        <span className="text-yellow-500 flex items-center">
                          <Clock className="w-4 h-4 mr-1" /> Waiting
                        </span>
                      )}
                    </TableCell>
                    <TableCell>{doc.source}</TableCell>
                    <TableCell>{doc.date}</TableCell>
                    <TableCell>{doc.assessment || 'General'}</TableCell>
                    <TableCell>
                      <div className="flex space-x-2">
                        <Button variant="ghost" size="sm">
                          <Eye className="w-4 h-4" />
                        </Button>
                        {doc.status === 'Waiting' && (
                          <>
                            <Button variant="ghost" size="sm">
                              <RefreshCw className="w-4 h-4" />
                            </Button>
                            <Button variant="ghost" size="sm" title="Clicking complete will open this file for you to give to the patient to complete, you will need to enter your passcode to get back in">
                              <Info className="w-4 h-4" />
                            </Button>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
      <div className="w-1/3 space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Quick Actions</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-2">
              <QuickActionButton icon={Plus} label="Add Document" />
              <QuickActionButton icon={Clock} label="Document History" />
              <QuickActionButton icon={Send} label="Send Documents" />
              <QuickActionButton icon={FileText} label="Send Form" />
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Document Audit</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              {auditTrail.map((entry) => (
                <li key={entry.id} className="text-sm">
                  <span className="font-semibold">{entry.action}</span> {entry.document} by {entry.user} on {entry.date}
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

// Add this new component before TaskManager
const DiagnosisBox = () => {
  const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
  const [finalDiagnoses, setFinalDiagnoses] = useState([]);

  const availableDiagnoses = [
    { id: 'adhd-c', label: 'ADHD - Combined Type' },
    { id: 'adhd-i', label: 'ADHD - Inattentive Type' },
    { id: 'adhd-h', label: 'ADHD - Hyperactive Type' },
    { id: 'anxiety', label: 'Generalized Anxiety Disorder' },
    { id: 'depression', label: 'Major Depressive Disorder' },
    { id: 'asd', label: 'Autism Spectrum Disorder' },
    { id: 'odd', label: 'Oppositional Defiant Disorder' },
    { id: 'learning', label: 'Specific Learning Disorder' }
  ];

  return (
    <Card className="mb-4 bg-white">
      <CardHeader className="bg-white">
        <CardTitle className="text-xl font-bold">Diagnosis</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {/* Preliminary Diagnosis (changed from Reason) */}
          <div>
            <label className="text-sm font-medium text-gray-500">Preliminary Diagnosis</label>
            <div className="flex items-center justify-between mt-1 p-3 bg-gray-50 rounded-lg">
              <span className="text-gray-900">ADHD General</span>
              <div className="flex items-center text-xs text-gray-500">
                <Clock size={14} className="mr-1" />
                Initial Assessment
              </div>
            </div>
          </div>

          {/* Final Rendered Diagnosis */}
          <div>
            <div className="flex items-center justify-between mb-1">
              <label className="text-sm font-medium text-gray-500">Final Rendered Diagnosis</label>
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={() => setShowDiagnosisModal(true)}
                className="text-blue-600"
              >
                <Edit2 size={14} className="mr-1" />
                Edit
              </Button>
            </div>
            <div className="p-3 bg-gray-50 rounded-lg min-h-[60px]">
              {finalDiagnoses.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {finalDiagnoses.map(diagnosis => (
                    <span 
                      key={diagnosis.id} 
                      className="px-2 py-1 bg-blue-100 text-blue-700 rounded-full text-sm"
                    >
                      {diagnosis.label}
                    </span>
                  ))}
                </div>
              ) : (
                <div className="text-gray-400 text-sm italic">No final diagnosis rendered</div>
              )}
            </div>
          </div>
        </div>

        {/* Diagnosis Selection Modal */}
        <Dialog open={showDiagnosisModal} onOpenChange={setShowDiagnosisModal}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Select Final Diagnosis</DialogTitle>
            </DialogHeader>
            <div className="py-4">
              <div className="space-y-2">
                {availableDiagnoses.map(diagnosis => (
                  <div key={diagnosis.id} className="flex items-center space-x-2">
                    <Checkbox
                      id={diagnosis.id}
                      checked={finalDiagnoses.some(d => d.id === diagnosis.id)}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          setFinalDiagnoses([...finalDiagnoses, diagnosis]);
                        } else {
                          setFinalDiagnoses(finalDiagnoses.filter(d => d.id !== diagnosis.id));
                        }
                      }}
                    />
                    <Label htmlFor={diagnosis.id}>{diagnosis.label}</Label>
                  </div>
                ))}
              </div>
            </div>
            <DialogFooter>
              <Button onClick={() => setShowDiagnosisModal(false)}>Done</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default function PatientRecord() {
  const [activeTab, setActiveTab] = useState('profile');
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const menuItems = [
    { id: "active-assessment", label: "Active Assessment" },
    { id: "patient-portal", label: "Patient Portal" },
    { id: "documents", label: "Documents" },
    { id: "patient-profile", label: "Patient Profile" },
    { id: "stakeholders", label: "Stakeholders" },
    { id: "messaging", label: "Messaging" },
    { id: "billing", label: "Billing" },
    { id: "audit", label: "Audit" },
  ];

  const [hasReleaseOfInformation, setHasReleaseOfInformation] = useState(false);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
                Tyler Smith
                <User className="h-5 w-5 text-gray-500 ml-2" />
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsContactModalOpen(true)}
                  className="ml-2"
                >
                  <Contact className="h-5 w-5" />
                </Button>
              </h1>
              <span className="text-sm text-gray-500">
                [Acct#: 1077544] <Copy className="inline h-4 w-4 cursor-pointer" />
              </span>
            </div>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-500 dark:text-gray-400">Age: 9</p>
            <p className="text-sm font-medium">[Active]ADHD Assessment (Child)</p>
            <p className={`text-sm ${hasReleaseOfInformation ? 'text-green-500' : 'text-red-500'}`}>
              {hasReleaseOfInformation ? 'Release of Information on File' : 'No Release of Information'}
            </p>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="mb-6">
          <nav className="flex space-x-4">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  activeTab === item.id
                    ? "bg-blue-500 text-white"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
              >
                {item.label}
              </button>
            ))}
          </nav>
        </div>

        {activeTab === "active-assessment" && (
          <div className="flex gap-4">
            <div className="w-2/3 space-y-4">
              <Card className="bg-white">
                <CardContent className="p-4">
                  <CurrentAssessment />
                </CardContent>
              </Card>
              <DocumentPreview />
              <Card className="bg-white">
                <CardContent className="p-4">
                  <AIInsights />
                </CardContent>
              </Card>
            </div>
            <div className="w-1/3 space-y-4">
              <Card className="mb-4 bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Quick Actions</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-2">
                    <QuickActionButton icon={Plus} label="Add Service" />
                    <QuickActionButton icon={Send} label="Send Form" />
                    <QuickActionButton icon={MessageSquare} label="Message" />
                    <QuickActionButton icon={Calendar} label="Schedule" />
                    <QuickActionButton icon={Upload} label="Upload" />
                    <QuickActionButton icon={FileText} label="Notes" />
                    <QuickActionButton icon={Pen} label="Start Charting" />
                    <QuickActionButton icon={Settings} label="Report Builder" />
                  </div>
                </CardContent>
              </Card>
              <DiagnosisBox />
              <TaskManager />
              <Card className="mb-4 bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Consent and Intake Forms</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {consentForms.map((form) => (
                      <li key={form.id} className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <StatusIcon status={form.status} />
                          <span>{form.title}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <Button variant="ghost" size="sm">
                            <Eye className={`h-4 w-4 ${form.viewed ? 'text-green-500' : 'text-gray-400'}`} />
                          </Button>
                          <ProcessedIcon processed={form.processed} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
              <Card className="bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Assessment Assets</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {assessmentInventory.map((item) => (
                      <li key={item.id} className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <StatusIcon status={item.status} />
                          <span>{item.title}</span>
                        </div>
                        <Button variant="ghost" size="sm">
                          <FileText className="h-4 w-4" />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </div>
          </div>
        )}

        {activeTab === "patient-portal" && (
          <Card className="bg-white">
            <CardContent className="p-4">
              <PatientPortal />
            </CardContent>
          </Card>
        )}

        {activeTab === "documents" && <DocumentsTab />}

        {activeTab === "patient-profile" && (
          <div className="flex gap-4">
            <div className="w-2/3 space-y-4">
              <PatientProfile />
            </div>
            <div className="w-1/3 space-y-4">
              <Card className="mb-4 bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Quick Actions</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-2">
                    <QuickActionButton icon={Edit} label="Edit Profile" />
                    <QuickActionButton icon={Upload} label="Upload Document" />
                    <QuickActionButton icon={MessageSquare} label="Send Message" />
                    <QuickActionButton icon={Calendar} label="Schedule Appointment" />
                  </div>
                </CardContent>
              </Card>
              <Card className="mb-4 bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Sharing</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 gap-2">
                    <QuickActionButton icon={Share2} label="Share Patient File" />
                    <QuickActionButton icon={FileText} label="Transfer Patient File" />
                  </div>
                </CardContent>
              </Card>
              <TaskManager />
            </div>
          </div>
        )}

        {activeTab === "stakeholders" && (
          <Stakeholders />
        )}

        {activeTab === "messaging" && (
          <Card className="bg-white">
            <CardContent className="p-4">
              <h2 className="text-xl font-bold mb-4">Messaging</h2>
              <p>Messaging content goes here.</p>
            </CardContent>
          </Card>
        )}

        {activeTab === "billing" && (
          <PatientBillingSection />
        )}

        {activeTab === "audit" && (
          <Card className="bg-white">
            <CardContent className="p-4">
              <h2 className="text-xl font-bold mb-4">Audit</h2>
              <p>Audit content goes here.</p>
            </CardContent>
          </Card>
        )}
      </main>

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
    </div>
  );
}