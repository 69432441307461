import React, { useState, useEffect } from 'react';
import { 
  Clock, FileText, Play, Pause, Check, X, ChevronRight, 
  ChevronLeft, Plus, Mic, AlertCircle, Eye, Upload, 
  Calendar, ClipboardList, MessageSquare, UserCheck,
  PanelLeftClose, PanelLeftOpen, Timer, AlertTriangle,
  Save, Send, Download, CheckSquare
} from 'lucide-react';

function SessionInterface() {
  const [activeTab, setActiveTab] = useState('assessment');

  useEffect(() => {
    console.log('SessionInterface mounted');
  }, []);

  return (
    <div className="p-4 bg-white">
      <h1 className="text-2xl font-bold mb-4">Session Interface</h1>
      <div className="flex gap-4">
        <button 
          onClick={() => setActiveTab('assessment')}
          className={`px-4 py-2 rounded ${
            activeTab === 'assessment' ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          Assessment
        </button>
        <button 
          onClick={() => setActiveTab('behavioral')}
          className={`px-4 py-2 rounded ${
            activeTab === 'behavioral' ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          Behavioral
        </button>
        <button 
          onClick={() => setActiveTab('notes')}
          className={`px-4 py-2 rounded ${
            activeTab === 'notes' ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          Notes
        </button>
      </div>
      <div className="mt-4 p-4 border rounded">
        <p>Active Tab: {activeTab}</p>
        <p>This is the Session Interface component</p>
      </div>
    </div>
  );
}

export default SessionInterface;