import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { ScrollArea } from "../components/ui/scroll-area";
import { 
  Search, Plus, FileText, ExternalLink, 
  Info, Download, ShoppingCart, Eye,
  ChevronLeft, ChevronRight
} from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../components/ui/dialog";

const measures = [
  {
    id: 1,
    name: 'ADHD Rating Scale-5',
    publisher: 'Guilford Press',
    dateAdded: '2024-01-15',
    forms: ['Home Version', 'School Version', 'Scoring Sheet'],
    hasPublicDomain: true,
    price: '$165.00',
    description: 'Comprehensive ADHD assessment tool for children and adolescents',
    assignedServices: ['ADHD Assessment', 'School Consultation'],
    usageCount: 47,
    ageRange: '5-17 years'
  },
  {
    id: 2,
    name: 'Conners 4',
    publisher: 'MHS Assessments',
    dateAdded: '2023-12-01',
    forms: ['Parent Form', 'Teacher Form', 'Self-Report', 'Scoring Guide'],
    hasPublicDomain: false,
    price: '$295.00',
    description: 'Multi-informant assessment of ADHD and comorbid disorders',
    assignedServices: ['ADHD Assessment', 'Treatment Monitoring'],
    usageCount: 32,
    ageRange: '6-18 years'
  },
  // Add more measures as needed
];

const newReleases = [
  {
    id: 101,
    name: 'Executive Function Scale - 2nd Edition',
    publisher: 'PAR',
    releaseDate: '2024-03-01',
    price: '$225.00',
    description: 'Updated comprehensive assessment of executive functioning'
  },
  // Add more new releases
];

export default function MeasuresLibrary() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDiagnosis, setSelectedDiagnosis] = useState('all');
  const [searchBy, setSearchBy] = useState('name');
  const [selectedMeasure, setSelectedMeasure] = useState(null);
  const [showMeasureModal, setShowMeasureModal] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [measureIntent, setMeasureIntent] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Add diagnostic options
  const diagnosticCategories = [
    { value: 'all', label: 'All Diagnoses' },
    { value: 'adhd', label: 'ADHD' },
    { value: 'anxiety', label: 'Anxiety' },
    { value: 'depression', label: 'Depression' },
    { value: 'autism', label: 'Autism' },
    { value: 'learning', label: 'Learning Disorders' }
  ];

  // Add search categories
  const searchCategories = [
    { value: 'name', label: 'Name' },
    { value: 'domain', label: 'Domain' },
    { value: 'function', label: 'Function' }
  ];

  // Add this helper data
  const measurementSuggestions = [
    'attention span',
    'hyperactivity',
    'executive functioning',
    'anxiety levels',
    'depression severity',
    'social skills',
    'behavioral problems',
    'emotional regulation',
    'cognitive processing',
    'learning difficulties'
  ];

  const handlePrevSlide = () => {
    setCarouselIndex(prev => Math.max(0, prev - 1));
  };

  const handleNextSlide = () => {
    setCarouselIndex(prev => Math.min(newReleases.length - 3, prev + 1));
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Measures Library</h1>

      {/* New Releases Carousel - Moved to top */}
      <Card className="mb-6">
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle>New Releases</CardTitle>
            <div className="flex space-x-2">
              <Button 
                variant="outline" 
                size="sm" 
                onClick={handlePrevSlide}
                disabled={carouselIndex === 0}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={handleNextSlide}
                disabled={carouselIndex >= newReleases.length - 3}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex space-x-4">
            {newReleases.slice(carouselIndex, carouselIndex + 3).map((release) => (
              <Card key={release.id} className="flex-1">
                <CardContent className="p-4">
                  <h3 className="font-semibold mb-2">{release.name}</h3>
                  <p className="text-sm text-gray-600 mb-4">{release.description}</p>
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium">{release.price}</span>
                    <Button size="sm">Learn More</Button>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Your Measures Section */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Your Measures</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            {measures.map((measure) => (
              <div key={measure.id} className="border rounded-lg p-4 hover:bg-gray-50">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-semibold flex items-center">
                      {measure.name}
                      {measure.hasPublicDomain && (
                        <Button variant="ghost" size="sm" className="ml-2">
                          <Eye className="h-4 w-4 mr-1" />
                          View Public Domain Version
                        </Button>
                      )}
                    </h3>
                    <p className="text-sm text-gray-600">{measure.description}</p>
                    <div className="mt-2 space-y-1">
                      <p className="text-sm"><span className="font-medium">Publisher:</span> {measure.publisher}</p>
                      <p className="text-sm"><span className="font-medium">Added:</span> {measure.dateAdded}</p>
                      <div className="flex items-center">
                        <span className="text-sm font-medium mr-2">Forms:</span>
                        <div className="flex flex-wrap gap-2">
                          {measure.forms.map((form, index) => (
                            <span 
                              key={index}
                              className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded"
                            >
                              {form}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="flex items-center mt-2">
                        <span className="text-sm font-medium mr-2">Services:</span>
                        <div className="flex flex-wrap gap-2">
                          {measure.assignedServices.map((service, index) => (
                            <span 
                              key={index}
                              className="text-xs bg-purple-100 text-purple-800 px-2 py-1 rounded"
                            >
                              {service}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="flex items-center space-x-4 mt-2">
                        <span className="text-sm bg-green-100 text-green-800 px-2 py-1 rounded">
                          Used: {measure.usageCount} times
                        </span>
                        <span className="text-sm bg-orange-100 text-orange-800 px-2 py-1 rounded">
                          Age Range: {measure.ageRange}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Button variant="outline">
                      <ShoppingCart className="h-4 w-4 mr-2" />
                      Buy More
                    </Button>
                    <Button variant="ghost" className="text-gray-600">
                      <FileText className="h-4 w-4 mr-2" />
                      Replace
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Search and Filter Section */}
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <div className="flex items-center space-x-4">
          <div className="flex-1">
            <label className="text-sm font-medium text-gray-700 mb-1 block">
              What are you trying to measure?
            </label>
            <div className="relative">
              <Input
                type="text"
                placeholder="e.g., attention span, anxiety levels..."
                value={measureIntent}
                onChange={(e) => {
                  setMeasureIntent(e.target.value);
                  if (e.target.value) {
                    const filtered = measurementSuggestions.filter(suggestion =>
                      suggestion.toLowerCase().includes(e.target.value.toLowerCase())
                    );
                    setSuggestions(filtered);
                  } else {
                    setSuggestions([]);
                  }
                }}
                className="w-full"
              />
              {/* Suggestions dropdown */}
              {suggestions.length > 0 && measureIntent && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                      onClick={() => {
                        setMeasureIntent(suggestion);
                        setSuggestions([]);
                      }}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex-1">
            <label className="text-sm font-medium text-gray-700 mb-1 block">
              Search by name
            </label>
            <div className="relative">
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <Input
                type="text"
                placeholder="Search measures..."
                className="pl-8"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Measures Grid */}
      <div className="grid grid-cols-3 gap-4">
        {measures.map((measure) => (
          <Card key={measure.id} className="hover:shadow-md transition-shadow">
            <CardContent className="p-4">
              <h3 className="font-semibold mb-2">{measure.name}</h3>
              <p className="text-sm text-gray-600 mb-4">{measure.description}</p>
              <div className="space-y-2 mb-4">
                <div className="flex items-center justify-between text-sm">
                  <span>Cost:</span>
                  <span className="font-medium">{measure.price}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span>Type:</span>
                  <span className={`px-2 py-1 rounded text-xs ${
                    measure.hasPublicDomain 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-blue-100 text-blue-800'
                  }`}>
                    {measure.hasPublicDomain ? 'Open Source' : 'Licensed'}
                  </span>
                </div>
              </div>
              <Button 
                className="w-full"
                onClick={() => {
                  setSelectedMeasure(measure);
                  setShowMeasureModal(true);
                }}
              >
                Learn More
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Measure Detail Modal */}
      <Dialog open={showMeasureModal} onOpenChange={setShowMeasureModal}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>{selectedMeasure?.name}</DialogTitle>
            <DialogDescription>
              Detailed information about this measure
            </DialogDescription>
          </DialogHeader>
          {selectedMeasure && (
            <div className="space-y-4">
              <p className="text-sm text-gray-600">{selectedMeasure.description}</p>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="font-medium mb-2">Details</h4>
                  <div className="space-y-2 text-sm">
                    <p><span className="font-medium">Publisher:</span> {selectedMeasure.publisher}</p>
                    <p><span className="font-medium">Age Range:</span> {selectedMeasure.ageRange}</p>
                    <p><span className="font-medium">Price:</span> {selectedMeasure.price}</p>
                  </div>
                </div>
                
                <div>
                  <h4 className="font-medium mb-2">Usage</h4>
                  <div className="space-y-2 text-sm">
                    <p><span className="font-medium">Times Used:</span> {selectedMeasure.usageCount}</p>
                    <div className="flex flex-wrap gap-2">
                      {selectedMeasure.assignedServices.map((service, index) => (
                        <span 
                          key={index}
                          className="text-xs bg-purple-100 text-purple-800 px-2 py-1 rounded"
                        >
                          {service}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="font-medium mb-2">Included Forms</h4>
                <div className="flex flex-wrap gap-2">
                  {selectedMeasure.forms.map((form, index) => (
                    <span 
                      key={index}
                      className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded"
                    >
                      {form}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
} 