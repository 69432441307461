import React from 'react';
import { X, Upload, Scan, Mail, Edit, Mic, Plus } from "lucide-react"
import { Button } from "../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog"

function DataOption({ icon: Icon, label }) {
  return (
    <Button variant="outline" className="h-24 flex flex-col items-center justify-center gap-2 bg-white text-gray-800 hover:bg-gray-100">
      <Icon className="h-8 w-8" />
      <span>{label}</span>
    </Button>
  )
}

export default function AddDataModal() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="bg-white text-gray-800 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700">
          <Plus className="mr-2 h-4 w-4" /> Add Data
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-white">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-gray-800">Add Data</DialogTitle>
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            onClick={() => document.querySelector('dialog')?.close()}
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>
        <div className="grid grid-cols-3 gap-4">
          <DataOption icon={Upload} label="Upload" />
          <DataOption icon={Scan} label="Scan" />
          <DataOption icon={Mail} label="Email In" />
          <DataOption icon={Edit} label="Manually Enter" />
          <DataOption icon={Mic} label="Add Audio" />
        </div>
      </DialogContent>
    </Dialog>
  )
}