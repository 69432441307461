import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Label } from "../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Textarea } from "../components/ui/textarea";
import { Download, Trash2, Eye, FileText, ChevronLeft, ChevronRight } from 'lucide-react';

const initialDocuments = [
  { id: '1', fileName: 'patient_record.pdf', dateTime: '2023-09-15 10:30', uploader: 'Dr. Smith', source: 'Email', status: 'processing' },
  { id: '2', fileName: 'lab_results.docx', dateTime: '2023-09-14 15:45', uploader: 'Nurse Johnson', source: 'Portal Upload', status: 'processing' },
  { id: '3', fileName: 'prescription.pdf', dateTime: '2023-09-13 09:00', uploader: 'Dr. Brown', source: 'Fax', status: 'completed' },
];

export default function DocumentManager() {
  const [documents, setDocuments] = useState(initialDocuments);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const [notes, setNotes] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [assignment, setAssignment] = useState('');

  const handleDownload = (document) => {
    console.log(`Downloading ${document.fileName}`);
  };

  const handleDelete = (documentId) => {
    setDocuments(documents.filter(doc => doc.id !== documentId));
  };

  const handlePreview = (document) => {
    setSelectedDocument(document);
    setIsPreviewOpen(true);
  };

  const handleAssign = (document) => {
    setSelectedDocument(document);
    setIsAssignOpen(true);
    setNotes('');
    setDocumentType('');
    setAssignment('');
  };

  const handleAssignSubmit = (e) => {
    e.preventDefault();
    console.log('Assigning document:', selectedDocument, { notes, documentType, assignment });
    if (selectedDocument) {
      setDocuments(documents.map(doc => 
        doc.id === selectedDocument.id ? { ...doc, status: 'completed' } : doc
      ));
    }
    setIsAssignOpen(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Document Manager</h1>
      
      <Tabs defaultValue="processing">
        <TabsList>
          <TabsTrigger value="processing">Processing</TabsTrigger>
          <TabsTrigger value="completed">Completed</TabsTrigger>
        </TabsList>
        
        <TabsContent value="processing">
          <DocumentTable 
            documents={documents.filter(doc => doc.status === 'processing')}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onPreview={handlePreview}
            onAssign={handleAssign}
          />
        </TabsContent>
        
        <TabsContent value="completed">
          <DocumentTable 
            documents={documents.filter(doc => doc.status === 'completed')}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onPreview={handlePreview}
            onAssign={handleAssign}
          />
        </TabsContent>
      </Tabs>

      <Dialog open={isPreviewOpen} onOpenChange={setIsPreviewOpen}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Document Preview</DialogTitle>
          </DialogHeader>
          {selectedDocument && (
            <div className="space-y-4">
              <div className="relative">
                <img src="https://g-j4msto34d9m.vusercontent.net/placeholder.svg?height=400&width=600" alt="Document preview" className="w-full h-auto" />
                <div className="absolute inset-0 flex items-center justify-between px-4">
                  <Button variant="ghost" size="icon" className="bg-white/80 hover:bg-white">
                    <ChevronLeft className="h-6 w-6" />
                  </Button>
                  <Button variant="ghost" size="icon" className="bg-white/80 hover:bg-white">
                    <ChevronRight className="h-6 w-6" />
                  </Button>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p><strong>File Name:</strong> {selectedDocument.fileName}</p>
                  <p><strong>Date & Time:</strong> {selectedDocument.dateTime}</p>
                </div>
                <div>
                  <p><strong>Uploader:</strong> {selectedDocument.uploader}</p>
                  <p><strong>Source:</strong> {selectedDocument.source}</p>
                </div>
              </div>
              <div className="flex justify-end">
                <Button onClick={() => handleDownload(selectedDocument)}>
                  <Download className="mr-2 h-4 w-4" /> Download
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={isAssignOpen} onOpenChange={setIsAssignOpen}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Assign Document</DialogTitle>
          </DialogHeader>
          {selectedDocument && (
            <form onSubmit={handleAssignSubmit} className="space-y-4">
              <div className="relative">
                <img src="https://g-j4msto34d9m.vusercontent.net/placeholder.svg?height=300&width=500" alt="Document preview" className="w-full h-auto" />
                <div className="absolute inset-0 flex items-center justify-between px-4">
                  <Button variant="ghost" size="icon" className="bg-white/80 hover:bg-white">
                    <ChevronLeft className="h-6 w-6" />
                  </Button>
                  <Button variant="ghost" size="icon" className="bg-white/80 hover:bg-white">
                    <ChevronRight className="h-6 w-6" />
                  </Button>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="patient">Assign to Patient</Label>
                  <Select>
                    <SelectTrigger id="patient">
                      <SelectValue placeholder="Select patient" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="patient1">John Doe</SelectItem>
                      <SelectItem value="patient2">Jane Smith</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="service">Assign to Service</Label>
                  <Select>
                    <SelectTrigger id="service">
                      <SelectValue placeholder="Select service" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="service1">Consultation</SelectItem>
                      <SelectItem value="service2">Lab Test</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="documentType">Document Type</Label>
                  <Select value={documentType} onValueChange={setDocumentType}>
                    <SelectTrigger id="documentType">
                      <SelectValue placeholder="Select document type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="medical_record">Medical Record</SelectItem>
                      <SelectItem value="lab_result">Lab Result</SelectItem>
                      <SelectItem value="prescription">Prescription</SelectItem>
                      <SelectItem value="insurance">Insurance Document</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="assignment">Assign to</Label>
                  <Select value={assignment} onValueChange={setAssignment}>
                    <SelectTrigger id="assignment">
                      <SelectValue placeholder="Select assignment" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="doctor">Doctor</SelectItem>
                      <SelectItem value="nurse">Nurse</SelectItem>
                      <SelectItem value="admin">Admin Staff</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div>
                <Label htmlFor="notes">Notes</Label>
                <Textarea 
                  id="notes" 
                  placeholder="Add any additional notes here..." 
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
              <div className="flex justify-end">
                <Button type="submit">Submit</Button>
              </div>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

function DocumentTable({ documents, onDownload, onDelete, onPreview, onAssign }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>File Name</TableHead>
          <TableHead>Date & Time</TableHead>
          <TableHead>Uploader</TableHead>
          <TableHead>Source</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {documents.map((doc) => (
          <TableRow key={doc.id}>
            <TableCell>{doc.fileName}</TableCell>
            <TableCell>{doc.dateTime}</TableCell>
            <TableCell>{doc.uploader}</TableCell>
            <TableCell>{doc.source}</TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <Button variant="ghost" size="icon" onClick={() => onDownload(doc)}>
                  <Download className="h-4 w-4" />
                </Button>
                <Button variant="ghost" size="icon" onClick={() => onDelete(doc.id)}>
                  <Trash2 className="h-4 w-4" />
                </Button>
                <Button variant="ghost" size="icon" onClick={() => onPreview(doc)}>
                  <Eye className="h-4 w-4" />
                </Button>
                <Button variant="ghost" size="icon" onClick={() => onAssign(doc)}>
                  <FileText className="h-4 w-4" />
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}