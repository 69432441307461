import React, { useState } from 'react';
import { User, Users, ChevronRight, Mail, Clock, Check, FileText } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";

const ComprehensiveFormSendingModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState('choose-recipient');
  const [recipientType, setRecipientType] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [thirdPartyInfo, setThirdPartyInfo] = useState(null);

  const patients = [
    { id: 1, name: 'John Doe', age: 10 },
    { id: 2, name: 'Jane Smith', age: 12 },
    { id: 3, name: 'Alex Johnson', age: 8 },
  ];

  const forms = [
    { id: 1, name: 'ADHD Assessment Form' },
    { id: 2, name: 'Anxiety Screening Questionnaire' },
    { id: 3, name: 'Behavioral Observation Form' },
  ];

  const renderPatientSelection = () => (
    <div>
      <h2 className="text-lg font-semibold mb-4">Select Patient</h2>
      <div className="space-y-2">
        {patients.map((patient) => (
          <button
            key={patient.id}
            className={`w-full p-3 text-left rounded-lg border ${
              selectedPatient?.id === patient.id ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
            onClick={() => setSelectedPatient(patient)}
          >
            <div className="flex items-center">
              <User className="mr-3" />
              <div>
                <p className="font-medium">{patient.name}</p>
                <p className="text-sm text-gray-500">Age: {patient.age}</p>
              </div>
            </div>
          </button>
        ))}
      </div>
      <Button
        className="mt-4"
        onClick={() => setStep(recipientType === 'patient' ? 'select-form' : 'send-consent')}
        disabled={!selectedPatient}
      >
        Next
      </Button>
    </div>
  );

  const renderFormSelection = () => (
    <div>
      <h2 className="text-lg font-semibold mb-4">Select Form to Send</h2>
      <div className="space-y-2">
        {forms.map((form) => (
          <button
            key={form.id}
            className={`w-full p-3 text-left rounded-lg border ${
              selectedForm?.id === form.id ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
            onClick={() => setSelectedForm(form)}
          >
            <div className="flex items-center">
              <FileText className="mr-3" />
              <p className="font-medium">{form.name}</p>
            </div>
          </button>
        ))}
      </div>
      <Button
        className="mt-4"
        onClick={() => setStep('send-confirmation')}
        disabled={!selectedForm}
      >
        Send Form
      </Button>
    </div>
  );

  const renderContent = () => {
    switch (step) {
      case 'choose-recipient':
        return (
          <div>
            <h2 className="text-lg font-semibold mb-4">Choose Recipient Type</h2>
            <div className="space-y-4">
              <button
                className="w-full p-4 text-left rounded-lg border border-gray-300 hover:border-blue-500 hover:bg-blue-50 transition-colors"
                onClick={() => {
                  setRecipientType('patient');
                  setStep('select-patient');
                }}
              >
                <div className="flex items-center">
                  <User className="mr-4 text-blue-500" size={24} />
                  <div>
                    <p className="font-medium">Send to Patient</p>
                    <p className="text-sm text-gray-500">Directly send a form to the patient or their guardian</p>
                  </div>
                  <ChevronRight className="ml-auto text-gray-400" />
                </div>
              </button>
              <button
                className="w-full p-4 text-left rounded-lg border border-gray-300 hover:border-blue-500 hover:bg-blue-50 transition-colors"
                onClick={() => {
                  setRecipientType('third-party');
                  setStep('select-patient');
                }}
              >
                <div className="flex items-center">
                  <Users className="mr-4 text-green-500" size={24} />
                  <div>
                    <p className="font-medium">Send to Third Party (Requires Consent)</p>
                    <p className="text-sm text-gray-500">Send a form to a teacher, doctor, or other third party</p>
                  </div>
                  <ChevronRight className="ml-auto text-gray-400" />
                </div>
              </button>
            </div>
          </div>
        );
      case 'select-patient':
        return renderPatientSelection();
      case 'send-consent':
        return (
          <div>
            <h2 className="text-lg font-semibold mb-4">Send Consent Form</h2>
            <p className="mb-4">
              Send a consent form to {selectedPatient.name}'s guardian to specify the third party and grant permission.
            </p>
            <Button
              onClick={() => {
                // Here you would trigger the consent form sending process
                alert('Consent form sent to patient\'s guardian');
                setStep('waiting-for-consent');
              }}
            >
              <Mail className="mr-2" />
              Send Consent Form
            </Button>
          </div>
        );
      case 'waiting-for-consent':
        return (
          <div>
            <h2 className="text-lg font-semibold mb-4">Waiting for Consent</h2>
            <p className="mb-4">
              We're waiting for {selectedPatient.name}'s guardian to provide consent and specify the third party.
            </p>
            <div className="flex items-center text-yellow-500">
              <Clock className="mr-2" />
              <span>Awaiting Response</span>
            </div>
            {/* This button is for demonstration purposes */}
            <Button
              className="mt-4"
              onClick={() => {
                setThirdPartyInfo({
                  name: 'Mrs. Thompson',
                  role: 'Teacher',
                  email: 'thompson@school.edu'
                });
                setStep('consent-received');
              }}
            >
              Simulate Consent Received
            </Button>
          </div>
        );
      case 'consent-received':
        return (
          <div>
            <h2 className="text-lg font-semibold mb-4">Consent Received</h2>
            <p className="mb-4">
              Consent received for {selectedPatient.name}. The specified third party is:
            </p>
            <div className="bg-gray-100 p-4 rounded mb-4">
              <p><strong>Name:</strong> {thirdPartyInfo.name}</p>
              <p><strong>Role:</strong> {thirdPartyInfo.role}</p>
              <p><strong>Email:</strong> {thirdPartyInfo.email}</p>
            </div>
            <Button
              onClick={() => setStep('select-form')}
            >
              Proceed to Form Selection
            </Button>
          </div>
        );
      case 'select-form':
        return renderFormSelection();
      case 'send-confirmation':
        return (
          <div>
            <h2 className="text-lg font-semibold mb-4">Form Sent Successfully</h2>
            <p className="mb-4">
              The {selectedForm.name} has been sent to {recipientType === 'patient' ? selectedPatient.name : thirdPartyInfo.name}.
            </p>
            <div className="flex items-center text-green-500 mb-4">
              <Check className="mr-2" />
              <span>Process Complete</span>
            </div>
            <Button
              onClick={() => {
                setStep('choose-recipient');
                setSelectedPatient(null);
                setSelectedForm(null);
                setThirdPartyInfo(null);
                setRecipientType(null);
              }}
            >
              Send Another Form
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Send Form</DialogTitle>
        </DialogHeader>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default ComprehensiveFormSendingModal;
