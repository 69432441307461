import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Switch } from "../components/ui/switch";

const initialTemplates = [
  {
    id: 1,
    name: "Patient Email 1",
    subject: "Welcome to Our Portal",
    body: "Hello [Patient Name],\n\nWelcome to our portal! Please click the button below to set your portal password.\n\n[Set Password Button]\n\nBest regards,\n[Your Clinic Name]",
    sms: "Welcome to our portal! Set your password here: [Link]",
    logo: "/path/to/logo.png",
    sender: "no-reply@clinic.com"
  },
  {
    id: 2,
    name: "Documents Waiting For Your Completion",
    subject: "Action Required: Complete Your Documents",
    body: "Hello [Patient Name],\n\nYou have documents waiting for your completion. Please log in to your portal to complete them.\n\n[Login Button]\n\nBest regards,\n[Your Clinic Name]",
    sms: "You have documents to complete. Log in here: [Link]",
    logo: "/path/to/logo.png",
    sender: "no-reply@clinic.com"
  },
  // Add more templates as needed
];

const lockedTemplates = [
  {
    id: 1,
    name: "Reset Password",
    subject: "Reset Your Password",
    body: "Hello [User],\n\nClick the link below to reset your password.\n\n[Reset Password Link]\n\nBest regards,\n[Your Clinic Name]",
    sms: "Reset your password here: [Link]",
    logo: "/path/to/logo.png",
    sender: "no-reply@clinic.com"
  },
  {
    id: 2,
    name: "Delete Account",
    subject: "Account Deletion Confirmation",
    body: "Hello [User],\n\nYour account has been successfully deleted.\n\nBest regards,\n[Your Clinic Name]",
    sms: "Your account has been deleted.",
    logo: "/path/to/logo.png",
    sender: "no-reply@clinic.com"
  },
];

export default function EmailTemplates() {
  const [templates, setTemplates] = useState(initialTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [originalBody, setOriginalBody] = useState('');

  const handleEdit = (template) => {
    setSelectedTemplate(template);
    setOriginalBody(template.body);
    setIsEditOpen(true);
  };

  const handleSave = () => {
    setTemplates(templates.map(t => 
      t.id === selectedTemplate.id ? selectedTemplate : t
    ));
    setIsEditOpen(false);
  };

  const handleRevert = () => {
    setSelectedTemplate({ ...selectedTemplate, body: originalBody });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Email Templates</h1>
      <div className="grid grid-cols-1 gap-4">
        {templates.map(template => (
          <Card key={template.id}>
            <CardHeader>
              <CardTitle>{template.name}</CardTitle>
            </CardHeader>
            <CardContent>
              <p><strong>Subject:</strong> {template.subject}</p>
              <p><strong>Sender:</strong> {template.sender}</p>
              <Button variant="outline" onClick={() => handleEdit(template)}>Edit</Button>
            </CardContent>
          </Card>
        ))}
      </div>

      <h2 className="text-xl font-bold mt-8 mb-4">Locked Email Templates</h2>
      <div className="grid grid-cols-1 gap-4">
        {lockedTemplates.map(template => (
          <Card key={template.id}>
            <CardHeader>
              <CardTitle>{template.name}</CardTitle>
            </CardHeader>
            <CardContent>
              <p><strong>Subject:</strong> {template.subject}</p>
              <p><strong>Sender:</strong> {template.sender}</p>
              <Button variant="outline" disabled>Edit</Button>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog open={isEditOpen} onOpenChange={setIsEditOpen}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Edit Email Template</DialogTitle>
          </DialogHeader>
          {selectedTemplate && (
            <div className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="logo">Logo</label>
                <Select onValueChange={(value) => setSelectedTemplate({ ...selectedTemplate, logo: value })}>
                  <SelectTrigger id="logo">
                    <SelectValue placeholder="Select or enter URL" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="/path/to/logo1.png">Logo 1</SelectItem>
                    <SelectItem value="/path/to/logo2.png">Logo 2</SelectItem>
                    <SelectItem value={selectedTemplate.logo}>Current Logo</SelectItem>
                  </SelectContent>
                </Select>
                <Input
                  id="logo-url"
                  placeholder="Enter logo URL"
                  value={selectedTemplate.logo}
                  onChange={(e) => setSelectedTemplate({ ...selectedTemplate, logo: e.target.value })}
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="subject">Subject</label>
                <Input
                  id="subject"
                  value={selectedTemplate.subject}
                  onChange={(e) => setSelectedTemplate({ ...selectedTemplate, subject: e.target.value })}
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="sender">Sender Email</label>
                <Switch
                  checked={selectedTemplate.sender === "no-reply@clinic.com"}
                  onCheckedChange={(checked) => setSelectedTemplate({ ...selectedTemplate, sender: checked ? "no-reply@clinic.com" : "info@clinic.com" })}
                />
                <span>{selectedTemplate.sender}</span>
              </div>
              <div className="space-y-2">
                <label htmlFor="sms">SMS Version</label>
                <Textarea
                  id="sms"
                  rows={3}
                  value={selectedTemplate.sms}
                  onChange={(e) => setSelectedTemplate({ ...selectedTemplate, sms: e.target.value })}
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="body">Email Body</label>
                <Textarea
                  id="body"
                  rows={10}
                  value={selectedTemplate.body}
                  onChange={(e) => setSelectedTemplate({ ...selectedTemplate, body: e.target.value })}
                />
                <Button variant="outline" onClick={handleRevert}>Revert</Button>
              </div>
              <div className="flex justify-end">
                <Button onClick={handleSave}>Save</Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
