import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SideNav from './components/SideNav';
import SessionInterface from './pages/SessionInterface';
import DocumentManager from './pages/DocumentManager';
import Dashboard from './pages/Dashboard';
import PatientRecordsCRM from './pages/PatientRecordsCRM';
import PatientRecord from './pages/PatientRecord';
import Settings from './pages/Settings';
import FormsLibrary from './pages/FormsLibrary';
import FormEditor from './pages/FormEditor';
import ReportWriter from './pages/ReportWriter';
import EmailTemplates from './pages/EmailTemplates';
import Resources from './pages/Resources';
import Messaging from './pages/Messaging';
import MeasuresLibrary from './pages/MeasuresLibrary';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SideNav />}>
        <Route index element={<Dashboard />} />
        <Route path="session" element={<SessionInterface />} />
          <Route path="document-manager" element={<DocumentManager />} />
          <Route path="test" element={<div className="p-4">Test Route</div>} />
          <Route path="patient-records-crm" element={<PatientRecordsCRM />} />
          <Route path="patient-record/:id" element={<PatientRecord />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/forms-library" element={<FormsLibrary />} />
          <Route path="/form-editor" element={<FormEditor />} />
          <Route path="/form-editor/:formId" element={<FormEditor />} />
          <Route path="report-writer" element={<ReportWriter />} />
          <Route path="email-templates" element={<EmailTemplates />} />
          <Route path="resources" element={<Resources />} />
          <Route path="messaging" element={<Messaging />} />
          <Route path="measures" element={<MeasuresLibrary />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
