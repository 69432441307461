import React, { useState } from 'react';
import { Button } from '../components/ui/button';
import { Checkbox } from '../components/ui/checkbox';
import { Label } from '../components/ui/label';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../components/ui/dialog';
import { 
  Layout, FileText, BarChart2, User, List, Table, Grid,
  Image, PieChart, LineChart, FileSpreadsheet, Plus,
  Settings, Eye, AlertCircle, Brain, Users, GraduationCap,
  Heart, MessageSquare, Sparkles, MessageCircle, ArrowRight,
  Info, Save, Download, Share, Lock, Copy, Undo, Redo, 
  Check, Edit3, Book, AlertTriangle, Mail, PresentationIcon,
  FileOutput, ChevronDown, Send, Edit2, Printer, Link, 
  CheckCircle2, Clock, UserPlus, Trash2, Star
} from 'lucide-react';

export default function ReportWriter() {
  const [activeTab, setActiveTab] = useState('report');
  const [isDraft, setIsDraft] = useState(true);
  const [showAIModal, setShowAIModal] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [showContextualAI, setShowContextualAI] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [selectedStakeholder, setSelectedStakeholder] = useState('physician');
  const [selectedDocument, setSelectedDocument] = useState('report');
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLink, setShareLink] = useState('https://app.psychaccess.ai/report/abc123');
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [showPatientSnapshot, setShowPatientSnapshot] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [assessmentComplete, setAssessmentComplete] = useState(true);
  const [wizardStep, setWizardStep] = useState(0);
  const [wizardAnswers, setWizardAnswers] = useState({
    diagnoses: {},
    followup: '',
    focus: '',
    additional: ''
  });
  const [showAddDiagnosisDialog, setShowAddDiagnosisDialog] = useState(false);

  // Add pageTypes definition
  const pageTypes = [
    { id: 'cover', icon: <Book />, label: 'Cover Page' },
    { id: 'report', icon: <FileText />, label: 'Results/Report' },
    { id: 'dataviz', icon: <BarChart2 />, label: 'DataViz' },
    { id: 'resources', icon: <FileSpreadsheet />, label: 'Resources' },
  ];

  // Content blocks definition
  const contentBlocks = [
    { 
      id: 'patient-background',
      type: 'content',
      icon: <User />,
      title: 'Patient Background',
      description: 'Auto-generated background from patient records'
    },
    {
      id: 'assessment-methods',
      type: 'content',
      icon: <List />,
      title: 'Assessment Methods',
      description: 'List of tests and procedures used'
    },
    {
      id: 'test-results',
      type: 'content',
      icon: <FileSpreadsheet />,
      title: 'Test Results Summary',
      description: 'Compiled results from all assessments'
    }
  ];

  // Visualizations definition
  const visualizations = [
    {
      id: 'cognitive-profile',
      type: 'visualization',
      icon: <BarChart2 />,
      title: 'Cognitive Profile',
      description: 'Bar chart showing cognitive test scores'
    },
    {
      id: 'score-comparison',
      type: 'visualization',
      icon: <LineChart />,
      title: 'Score Comparison',
      description: 'Compare scores across different assessments'
    },
    {
      id: 'behavioral-chart',
      type: 'visualization',
      icon: <PieChart />,
      title: 'Behavioral Analysis',
      description: 'Distribution of behavioral observations'
    }
  ];

  // Layout elements definition
  const layoutElements = [
    {
      id: 'section-header',
      type: 'layout',
      icon: <Layout />,
      title: 'Section Header',
      description: 'Add a new section with header'
    },
    {
      id: 'two-column',
      type: 'layout',
      icon: <Grid />,
      title: 'Two Column Layout',
      description: 'Split content into two columns'
    },
    {
      id: 'data-table',
      type: 'layout',
      icon: <Table />,
      title: 'Data Table',
      description: 'Insert a formatted data table'
    }
  ];

  // AI Insights definition
  const insights = [
    {
      id: 'family-dynamics',
      type: 'insight',
      icon: <Users />,
      title: 'Family Dynamics Concern',
      description: 'Evidence of family conflict affecting behavior',
      severity: 'moderate',
      confidence: 0.85,
      detail: 'Analysis suggests ongoing family dynamics may be contributing to patient\'s current presentation.'
    },
    {
      id: 'academic-pattern',
      type: 'insight',
      icon: <GraduationCap />,
      title: 'Processing Speed Pattern',
      description: 'Significant discrepancy in processing speed',
      severity: 'high',
      confidence: 0.92,
      detail: 'Test results indicate processing speed falls two standard deviations below other cognitive measures.'
    },
    {
      id: 'emotional-pattern',
      type: 'insight',
      icon: <Heart />,
      title: 'Anxiety Pattern Detected',
      description: 'Recurring anxiety in social situations',
      severity: 'moderate',
      confidence: 0.88,
      detail: 'Multiple assessment measures indicate consistent patterns of anxiety in social situations.'
    }
  ];

  const stakeholders = {
    physician: 'Referring Physician',
    school: 'School District',
    parent: 'Parent/Guardian',
    specialist: 'Specialist'
  };

  // Add this after the stakeholders definition and before the GlobalAIAssistant component
  const patientSnapshot = {
    name: "John Doe",
    age: "13 years, 2 months",
    referralSource: "Dr. Smith, MD",
    service: "Neuropsychological Assessment",
    serviceDates: "03/15/2023 - 03/20/2023",
    prelimDiagnoses: ["Attention Deficit Hyperactivity Disorder", "Anxiety Disorder NOS"],
    finalDiagnosis: "ADHD - Combined Type",
    referralReason: "Academic difficulties, attention concerns in school and home settings"
  };

  // Global AI Assistant Modal Component
  const GlobalAIAssistant = () => (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${showAIModal ? '' : 'hidden'} z-50`}>
      <div className="bg-white rounded-lg p-6 w-1/2 max-w-2xl">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold flex items-center">
            <Sparkles className="mr-2 text-blue-500" />
            AI Assistant
          </h3>
          <button onClick={() => setShowAIModal(false)} className="text-gray-500">×</button>
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="What would you like me to help you with?"
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div className="bg-gray-50 rounded-lg p-4 mb-4">
          <p className="text-sm text-gray-600">Examples:</p>
          <ul className="text-sm text-gray-500 list-disc list-inside">
            <li>"Generate a visualization comparing WISC-V scores"</li>
            <li>"Write a summary of the cognitive assessment results"</li>
            <li>"Create a recommendations section based on findings"</li>
          </ul>
        </div>
        <div className="flex justify-end space-x-2">
          <button 
            className="px-4 py-2 border rounded hover:bg-gray-50"
            onClick={() => setShowAIModal(false)}
          >
            Cancel
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Generate
          </button>
        </div>
      </div>
    </div>
  );

  // Contextual AI Menu Component
  const ContextualAIMenu = () => (
    <div 
      className={`absolute bg-white rounded-lg shadow-lg p-2 w-48 ${showContextualAI ? '' : 'hidden'}`}
      style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
    >
      <button className="w-full text-left px-3 py-2 hover:bg-blue-50 rounded flex items-center">
        <MessageSquare size={16} className="mr-2" />
        Insert AI Content Here
      </button>
      <button className="w-full text-left px-3 py-2 hover:bg-blue-50 rounded flex items-center">
        <BarChart2 size={16} className="mr-2" />
        Add Visualization Here
      </button>
    </div>
  );

  const handleContextMenu = (e) => {
    e.preventDefault();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setShowContextualAI(true);
  };

  // Add FinalizeModal component
  const FinalizeModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-1/2 max-w-2xl">
        <h3 className="text-xl font-bold mb-4">Finalize Report</h3>
        <div className="bg-yellow-50 p-4 rounded-lg mb-4">
          <div className="flex items-center mb-2">
            <AlertTriangle className="text-yellow-500 mr-2" />
            <span className="font-semibold">Important Notice</span>
          </div>
          <p className="text-sm text-gray-600">
            Once finalized, this report will be locked and any changes will create a new version.
          </p>
        </div>
        <div className="space-y-4 mb-6">
          <div className="flex items-center">
            <input type="checkbox" className="mr-2" id="check1" />
            <label htmlFor="check1">I have thoroughly reviewed all content for accuracy</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" className="mr-2" id="check2" />
            <label htmlFor="check2">I confirm this report meets professional standards</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" className="mr-2" id="check3" />
            <label htmlFor="check3">I take responsibility for this report's content</label>
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium mb-2">Type patient's name [John Smith] to confirm</label>
            <input 
              type="text" 
              className="w-full p-2 border rounded" 
              placeholder="Patient Name"
            />
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <button 
            className="px-4 py-2 border rounded hover:bg-gray-50"
            onClick={() => setShowFinalizeModal(false)}
          >
            Cancel
          </button>
          <button 
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => {
              setIsDraft(false);
              setShowFinalizeModal(false);
            }}
          >
            Finalize Report
          </button>
        </div>
      </div>
    </div>
  );

  // Share Modal Component
  const ShareModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-[600px] max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold">Share Report</h3>
          <button onClick={() => setShowShareModal(false)} className="text-gray-500">×</button>
        </div>

        {/* Quick Share Link */}
        <div className="mb-6">
          <h4 className="text-sm font-medium mb-2">Quick Share Link</h4>
          <div className="flex items-center space-x-2">
            <input 
              type="text" 
              value={shareLink} 
              readOnly 
              className="flex-1 p-2 border rounded bg-gray-50"
            />
            <button 
              onClick={() => navigator.clipboard.writeText(shareLink)}
              className="p-2 text-blue-600 hover:bg-blue-50 rounded"
            >
              <Copy className="h-5 w-5" />
            </button>
          </div>
          <p className="text-sm text-gray-500 mt-1">Link expires in 7 days</p>
        </div>

        {/* Share with Stakeholders */}
        <div className="mb-6">
          <h4 className="text-sm font-medium mb-2">Share with Stakeholders</h4>
          <div className="space-y-3">
            {Object.entries(stakeholders).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between p-3 border rounded hover:bg-gray-50">
                <div className="flex items-center">
                  <input 
                    type="checkbox"
                    id={`share-${key}`}
                    className="mr-3"
                    checked={selectedRecipients.includes(key)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRecipients([...selectedRecipients, key]);
                      } else {
                        setSelectedRecipients(selectedRecipients.filter(r => r !== key));
                      }
                    }}
                  />
                  <label htmlFor={`share-${key}`}>
                    <div className="font-medium">{value}</div>
                    <div className="text-sm text-gray-500">Will receive: Report, Cover Letter</div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Custom Recipients */}
        <div className="mb-6">
          <h4 className="text-sm font-medium mb-2">Add Custom Recipient</h4>
          <div className="flex items-center space-x-2">
            <input 
              type="email" 
              placeholder="Enter email address"
              className="flex-1 p-2 border rounded"
            />
            <button className="p-2 text-blue-600 hover:bg-blue-50 rounded">
              <UserPlus className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Share Settings */}
        <div className="mb-6">
          <h4 className="text-sm font-medium mb-2">Share Settings</h4>
          <div className="space-y-2">
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Require recipient authentication
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Allow recipients to download
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Track document views
            </label>
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <button 
            className="px-4 py-2 border rounded hover:bg-gray-50"
            onClick={() => setShowShareModal(false)}
          >
            Cancel
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Share Documents
          </button>
        </div>
      </div>
    </div>
  );

  // Enhanced document previews
  const DocumentPreview = ({ type, stakeholder }) => {
    switch (type) {
      case 'report':
        return (
          <div className="space-y-6">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-bold">Psychological Assessment Report</h1>
              <div className="flex items-center text-sm text-gray-500">
                <Clock className="h-4 w-4 mr-1" />
                Finalized on {new Date().toLocaleDateString()}
              </div>
            </div>
            <div className="p-4 bg-blue-50 rounded-lg">
              <h2 className="font-medium mb-2">Patient Information</h2>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <p><span className="font-medium">Name:</span> John Doe</p>
                  <p><span className="font-medium">DOB:</span> 01/15/2010</p>
                  <p><span className="font-medium">Age:</span> 13 years, 2 months</p>
                </div>
                <div>
                  <p><span className="font-medium">Date of Assessment:</span> 03/20/2023</p>
                  <p><span className="font-medium">Referral Source:</span> Dr. Smith</p>
                  <p><span className="font-medium">Reason for Referral:</span> ADHD Assessment</p>
                </div>
              </div>
            </div>
            {/* Add more report sections */}
          </div>
        );

      case 'cover-letter':
        return (
          <div className="space-y-6">
            <h1 className="text-2xl font-bold">Cover Letter - {stakeholders[stakeholder]}</h1>
            <div className="space-y-4">
              <p className="text-right">{new Date().toLocaleDateString()}</p>
              <p>Dear {stakeholders[stakeholder]},</p>
              <p className="text-gray-700">
                Please find attached the comprehensive psychological assessment report for John Doe.
                This report contains detailed findings from our recent evaluation...
              </p>
              {/* Add more letter content */}
            </div>
          </div>
        );

      case 'presentation':
        return (
          <div className="space-y-6">
            <h1 className="text-2xl font-bold">Feedback Session Presentation</h1>
            <div className="aspect-[16/9] bg-gray-100 rounded-lg p-8 flex items-center justify-center">
              <div className="text-center">
                <PresentationIcon className="h-16 w-16 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-600">Interactive presentation for feedback session</p>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  // Update FinalizedView to use new DocumentPreview
  const FinalizedView = () => (
    <div className="flex h-full">
      {/* Document Selection Sidebar */}
      <div className="w-64 bg-white border-r">
        <div className="p-4">
          <h2 className="text-lg font-semibold mb-4">Output Documents</h2>
          
          {/* Main Report */}
          <button 
            onClick={() => setSelectedDocument('report')}
            className={`w-full text-left p-3 rounded-lg mb-2 flex items-center ${
              selectedDocument === 'report' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50'
            }`}
          >
            <FileText className="mr-2 h-5 w-5" />
            <span>Full Report</span>
          </button>

          {/* Stakeholder Selection */}
          <div className="mb-4">
            <div className="flex items-center justify-between p-2 text-sm text-gray-600">
              <span>Stakeholder Documents</span>
              <select 
                className="border rounded p-1"
                value={selectedStakeholder}
                onChange={(e) => setSelectedStakeholder(e.target.value)}
              >
                {Object.entries(stakeholders).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>

            {/* Cover Letter */}
            <button 
              onClick={() => setSelectedDocument('cover-letter')}
              className={`w-full text-left p-3 rounded-lg mb-2 flex items-center ${
                selectedDocument === 'cover-letter' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50'
              }`}
            >
              <Mail className="mr-2 h-5 w-5" />
              <span>Cover Letter</span>
            </button>
          </div>

          {/* Presentation */}
          <button 
            onClick={() => setSelectedDocument('presentation')}
            className={`w-full text-left p-3 rounded-lg mb-2 flex items-center ${
              selectedDocument === 'presentation' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50'
            }`}
          >
            <PresentationIcon className="mr-2 h-5 w-5" />
            <span>Feedback Presentation</span>
          </button>
        </div>

        {/* Document Actions */}
        <div className="border-t p-4">
          <h3 className="text-sm font-medium mb-3">Actions</h3>
          <div className="space-y-2">
            <button className="w-full flex items-center p-2 text-sm hover:bg-gray-50 rounded">
              <Send className="h-4 w-4 mr-2" />
              Share Document
            </button>
            <button className="w-full flex items-center p-2 text-sm hover:bg-gray-50 rounded">
              <Printer className="h-4 w-4 mr-2" />
              Print
            </button>
            <button className="w-full flex items-center p-2 text-sm hover:bg-gray-50 rounded">
              <Download className="h-4 w-4 mr-2" />
              Download
            </button>
            <button 
              onClick={() => setIsDraft(true)}
              className="w-full flex items-center p-2 text-sm hover:bg-gray-50 rounded text-blue-600"
            >
              <Edit2 className="h-4 w-4 mr-2" />
              Edit Report
            </button>
          </div>
        </div>
      </div>

      {/* Updated Document Preview */}
      <div className="flex-1 bg-gray-50 p-6">
        <div className="bg-white rounded-lg shadow-lg p-8 min-h-full">
          <DocumentPreview 
            type={selectedDocument} 
            stakeholder={selectedStakeholder} 
          />
        </div>
      </div>
    </div>
  );

  // Update the share button click handler
  const handleShareClick = () => {
    setShowShareModal(true);
  };

  // Add this new component after the ContextualAIMenu component
  const PatientSnapshotModal = ({ isOpen, onClose }) => (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${isOpen ? '' : 'hidden'}`}>
      <div className="bg-white rounded-lg p-6 w-[400px] max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold flex items-center">
            <User className="mr-2 text-blue-500" />
            Patient Snapshot
          </h3>
          <button onClick={onClose} className="text-gray-500">×</button>
        </div>

        <div className="space-y-4">
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <h4 className="font-bold text-lg">{patientSnapshot.name}</h4>
                <p className="text-gray-600">{patientSnapshot.age}</p>
              </div>
              <span className="px-2 py-1 bg-blue-100 text-blue-700 rounded-full text-xs">
                Active Case
              </span>
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <label className="text-sm font-medium text-gray-500">Referral Source</label>
              <p className="text-gray-700">{patientSnapshot.referralSource}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Service & Dates</label>
              <p className="text-gray-700">{patientSnapshot.service}</p>
              <p className="text-sm text-gray-500">{patientSnapshot.serviceDates}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Preliminary Diagnoses</label>
              <ul className="list-disc list-inside text-gray-700">
                {patientSnapshot.prelimDiagnoses.map((diagnosis, index) => (
                  <li key={index}>{diagnosis}</li>
                ))}
              </ul>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Final Diagnosis</label>
              <p className="text-gray-700">{patientSnapshot.finalDiagnosis}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Reason for Referral</label>
              <p className="text-gray-700">{patientSnapshot.referralReason}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Update the selectedComponent handler
  const handleComponentSelect = (component) => {
    if (component === 'patient-snapshot') {
      setShowPatientSnapshot(true);
    }
    setSelectedComponent(component);
  };

  // Add this new component after the PatientSnapshotModal
  const AIInferenceExample = () => (
    <div className="border border-gray-200 rounded-lg p-6 mb-6">
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center">
          <Brain className="text-purple-500 mr-2" size={24} />
          <h3 className="text-lg font-semibold">AI Inference: Family Dynamic Concerns</h3>
        </div>
        <div className="flex items-center space-x-2">
          <span className="px-3 py-1 bg-yellow-100 text-yellow-700 rounded-full text-sm">
            Requires Validation
          </span>
          <button className="p-2 hover:bg-gray-100 rounded-full">
            <Info size={18} className="text-gray-500" />
          </button>
        </div>
      </div>

      <div className="mb-6">
        <p className="text-gray-700 mb-2">
          Analysis suggests significant family dynamic challenges affecting patient's presentation,
          particularly in relation to academic performance and emotional regulation.
        </p>
        
        {/* Evidence Timeline */}
        <div className="relative mt-8 border-l-2 border-gray-200 ml-4">
          {/* Evidence Point 1 */}
          <div className="mb-8 ml-6">
            <div className="absolute -left-3 bg-blue-500 rounded-full w-4 h-4"></div>
            <div className="bg-blue-50 rounded-lg p-4">
              <div className="flex justify-between items-start mb-2">
                <span className="text-sm font-semibold text-blue-700">Parent Interview - 03/15/2023</span>
                <span className="text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded-full">
                  Direct Source
                </span>
              </div>
              <p className="text-sm text-gray-600">
                "Parents reported frequent arguments about homework completion and academic expectations.
                Mother and father expressed differing views on appropriate academic support."
              </p>
            </div>
          </div>

          {/* Evidence Point 2 */}
          <div className="mb-8 ml-6">
            <div className="absolute -left-3 bg-green-500 rounded-full w-4 h-4"></div>
            <div className="bg-green-50 rounded-lg p-4">
              <div className="flex justify-between items-start mb-2">
                <span className="text-sm font-semibold text-green-700">BASC-3 Results - 03/16/2023</span>
                <span className="text-xs bg-green-100 text-green-700 px-2 py-1 rounded-full">
                  Assessment Data
                </span>
              </div>
              <p className="text-sm text-gray-600">
                Elevated scores on Family Relations scale (T=68) and Emotional Control (T=72)
              </p>
              <div className="mt-2 flex items-center">
                <BarChart2 className="text-green-600 mr-2" size={16} />
                <span className="text-xs text-green-700">View Full Results</span>
              </div>
            </div>
          </div>

          {/* Evidence Point 3 */}
          <div className="ml-6">
            <div className="absolute -left-3 bg-orange-500 rounded-full w-4 h-4"></div>
            <div className="bg-orange-50 rounded-lg p-4">
              <div className="flex justify-between items-start mb-2">
                <span className="text-sm font-semibold text-orange-700">Clinical Observation - 03/17/2023</span>
                <span className="text-xs bg-orange-100 text-orange-700 px-2 py-1 rounded-full">
                  Direct Observation
                </span>
              </div>
              <p className="text-sm text-gray-600">
                "Patient expressed anxiety about parental reactions to test performance and showed
                visible distress when discussing home study environment."
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Validation Controls */}
      <div className="border-t pt-4 mt-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center">
              <CheckCircle2 className="mr-2" size={16} />
              Validate Inference
            </button>
            <button className="px-4 py-2 border border-red-500 text-red-500 rounded-lg hover:bg-red-50 flex items-center">
              <AlertTriangle className="mr-2" size={16} />
              Flag for Review
            </button>
          </div>
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <Brain size={16} />
            <span>Confidence Score: 85%</span>
          </div>
        </div>
      </div>
    </div>
  );

  // Update the suggestedDiagnoses structure inside DiagnosisWizard
  const suggestedDiagnoses = [
    {
      id: 'adhd',
      name: 'ADHD - Combined Type',
      supporting: [
        'Elevated scores on ADHD indices (T>65)',
        'Parent and teacher reports consistent with diagnostic criteria',
        'Behavioral observations during testing'
      ],
      convergent: [
        'Poor academic performance in subjects requiring sustained attention',
        'Similar patterns observed across multiple settings',
        'Family history of ADHD'
      ]
    },
    {
      id: 'anxiety',
      name: 'Generalized Anxiety Disorder',
      supporting: [
        'Elevated anxiety scales on BASC-3',
        'Clinical interview findings',
        'Observed anxiety during testing'
      ],
      convergent: [
        'Parent reports of sleep difficulties',
        'Somatic complaints during school hours',
        'Avoidance of social situations'
      ]
    }
  ];

  // Add this new dialog component for adding diagnoses
  const AddDiagnosisDialog = ({ isOpen, onClose }) => {
    const availableDiagnoses = [
      'ADHD - Combined Type',
      'ADHD - Inattentive Type',
      'ADHD - Hyperactive Type',
      'Generalized Anxiety Disorder',
      'Major Depressive Disorder',
      'Autism Spectrum Disorder',
      'Specific Learning Disorder',
      'Oppositional Defiant Disorder'
    ];

    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Diagnosis</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <div className="space-y-2">
              {availableDiagnoses.map((diagnosis, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <Checkbox id={`diagnosis-${index}`} />
                  <Label htmlFor={`diagnosis-${index}`}>{diagnosis}</Label>
                </div>
              ))}
            </div>
          </div>
          <DialogFooter>
            <Button onClick={onClose}>Add Selected</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  // Update the steps array in DiagnosisWizard
  const steps = [
    {
      id: 'diagnoses',
      title: 'Confirm Diagnoses',
      description: 'Please validate each AI-suggested diagnosis'
    },
    {
      id: 'insights',
      title: 'AI Insights',
      description: 'Review and select insights for inclusion'
    },
    {
      id: 'followup',
      title: 'Follow-up Information',
      description: 'Additional information since last visit'
    },
    {
      id: 'additional-config',
      title: 'Additional Config',
      description: 'Configure additional report elements'
    },
    {
      id: 'generate',
      title: 'Generate Report',
      description: 'Review and generate final report'
    }
  ];

  // Add this data structure for AI insights
  const aiSignals = [
    {
      id: 'attention-pattern',
      signal: 'Significant attention variability across tasks',
      finding: 'Performance significantly improves with structure and breaks',
      inference: 'May benefit from structured breaks and task segmentation',
      supporting: [
        'CPT-3 shows 20% performance improvement post-breaks',
        'Teacher reports better focus in structured activities',
        'Parent notes improved homework completion with timed segments'
      ],
      convergent: [
        'Similar patterns observed in classroom observation',
        'Consistent across multiple testing sessions',
        'Aligns with parent and teacher BRIEF ratings'
      ],
      sources: [
        { type: 'assessment', label: 'CPT-3', icon: <FileSpreadsheet className="text-blue-500" /> },
        { type: 'observation', label: 'Clinical Notes', icon: <Eye className="text-green-500" /> },
        { type: 'rating', label: 'BRIEF', icon: <BarChart2 className="text-purple-500" /> }
      ],
      selected: false
    },
    {
      id: 'processing-speed',
      signal: 'Processing speed inconsistency',
      finding: 'Significant variability in processing speed tasks',
      inference: 'Processing speed deficits may be context-dependent',
      supporting: [
        'WISC-V PSI score two standard deviations below mean',
        'Slower performance on timed academic tasks',
        'Increased errors under time pressure'
      ],
      convergent: [
        'Teacher reports slow task completion',
        'Similar patterns in previous testing',
        'Impact observed across multiple subjects'
      ],
      sources: [
        { type: 'assessment', label: 'WISC-V', icon: <FileSpreadsheet className="text-blue-500" /> },
        { type: 'document', label: 'School Records', icon: <FileText className="text-orange-500" /> },
        { type: 'observation', label: 'Teacher Input', icon: <MessageSquare className="text-green-500" /> }
      ],
      selected: false
    }
  ];

  // Add this new configuration section
  const additionalConfigs = [
    {
      id: 'visualizations',
      label: 'Insert visualizations where appropriate',
      description: 'Include data visualizations, charts, and graphs to illustrate assessment results',
      defaultChecked: true
    },
    {
      id: 'family-dynamics',
      label: 'Include Family Dynamics',
      description: 'Add section on family structure, relationships, and support system',
      defaultChecked: false,
      conditional: 'if applicable'
    },
    {
      id: 'educational-history',
      label: 'Educational History & Performance',
      description: 'Include academic background, achievements, and challenges',
      defaultChecked: true
    },
    {
      id: 'treatment-history',
      label: 'Previous Treatment History',
      description: 'Document past interventions, medications, and their outcomes',
      defaultChecked: true
    },
    {
      id: 'environmental-factors',
      label: 'Environmental Considerations',
      description: 'Include relevant environmental factors affecting presentation',
      defaultChecked: false
    },
    {
      id: 'strengths-resources',
      label: 'Patient Strengths & Resources',
      description: 'Highlight positive attributes and available support systems',
      defaultChecked: true
    },
    {
      id: 'cultural-factors',
      label: 'Cultural Considerations',
      description: 'Include relevant cultural factors and their impact',
      defaultChecked: false,
      conditional: 'if applicable'
    }
  ];

  // Add this new component after AIInferenceExample
  const DiagnosisWizard = ({ isOpen, onClose }) => {
    const handleNext = () => {
      if (wizardStep < steps.length - 1) {
        setWizardStep(wizardStep + 1);
      } else {
        // Generate report
        handleGenerateReport();
      }
    };

    const handleBack = () => {
      if (wizardStep > 0) {
        setWizardStep(wizardStep - 1);
      }
    };

    const handleGenerateReport = () => {
      // Here you would process all wizard answers and generate the report
      setShowWizard(false);
      setWizardStep(0);
      // You could also set isDraft to false here if the report is complete
    };

    return (
      <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${isOpen ? '' : 'hidden'}`}>
        <div className="bg-white rounded-lg w-[800px] max-h-[80vh] overflow-y-auto">
          {/* Wizard Header */}
          <div className="border-b p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold flex items-center">
                <Brain className="mr-2 text-blue-500" />
                Report Generation Wizard
              </h2>
              <button onClick={onClose} className="text-gray-500">×</button>
            </div>
            <div className="flex justify-between">
              {steps.map((step, index) => (
                <div 
                  key={step.id}
                  className={`flex items-center ${index < steps.length - 1 ? 'flex-1' : ''}`}
                >
                  <div className={`flex items-center ${index < wizardStep ? 'text-blue-500' : 'text-gray-500'}`}>
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 
                      ${index <= wizardStep ? 'border-blue-500 text-blue-500' : 'border-gray-300'}`}>
                      {index < wizardStep ? <Check size={16} /> : index + 1}
                    </div>
                    <span className="ml-2">{step.title}</span>
                  </div>
                  {index < steps.length - 1 && (
                    <div className={`flex-1 h-px mx-4 mt-4 ${
                      index < wizardStep ? 'bg-blue-500' : 'bg-gray-300'
                    }`} />
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Wizard Content */}
          <div className="p-6">
            {wizardStep === 0 && (
              <div className="space-y-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium">Selected Diagnoses</h3>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => {
                      // Open dialog to add new diagnosis
                      // You'll need to implement this dialog
                      setShowAddDiagnosisDialog(true);
                    }}
                  >
                    <Plus className="h-4 w-4 mr-1" />
                    Add Diagnosis
                  </Button>
                </div>
                
                {suggestedDiagnoses.map((diagnosis) => (
                  <div key={diagnosis.id} className="border rounded-lg p-4">
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center space-x-4">
                        <h3 className="font-semibold text-lg">{diagnosis.name}</h3>
                        <Button 
                          variant="ghost" 
                          size="sm"
                          className="text-red-500 hover:text-red-600"
                          onClick={() => {
                            // Implement remove diagnosis logic
                            setSuggestedDiagnoses(prev => 
                              prev.filter(d => d.id !== diagnosis.id)
                            );
                          }}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                      <div className="flex items-center space-x-2">
                        <Button 
                          variant="outline"
                          className="border-red-500 text-red-500 hover:bg-red-50"
                          onClick={() => {/* Implement reject logic */}}
                        >
                          Reject
                        </Button>
                        <Button 
                          className="bg-green-500 text-white hover:bg-green-600"
                          onClick={() => {/* Implement confirm logic */}}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      {/* Supporting Evidence Column */}
                      <div className="bg-gray-50 p-3 rounded">
                        <h4 className="font-medium mb-2 text-blue-600">Supporting Evidence</h4>
                        <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                          {diagnosis.supporting.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      
                      {/* Convergent Evidence Column */}
                      <div className="bg-gray-50 p-3 rounded">
                        <h4 className="font-medium mb-2 text-green-600">Convergent Evidence</h4>
                        <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                          {diagnosis.convergent.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {wizardStep === 1 && (
              <div className="space-y-6">
                <div className="bg-blue-50 p-4 rounded-lg mb-4">
                  <div className="flex items-center">
                    <Brain className="text-blue-500 mr-2" />
                    <h3 className="font-semibold">AI-Detected Patterns and Insights</h3>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    Review each insight and select which ones to include in the report.
                  </p>
                </div>

                {aiSignals.map((signal) => (
                  <div key={signal.id} className="border rounded-lg p-4">
                    <div className="flex items-center justify-between mb-4">
                      <div>
                        <h3 className="font-semibold text-lg">{signal.signal}</h3>
                        <p className="text-gray-600">{signal.inference}</p>
                      </div>
                      <Checkbox
                        id={`include-${signal.id}`}
                        checked={signal.selected}
                        onCheckedChange={(checked) => {
                          // Update selection logic
                        }}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div className="bg-gray-50 p-3 rounded">
                        <h4 className="font-medium mb-2 text-blue-600">Supporting Evidence</h4>
                        <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                          {signal.supporting.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      
                      <div className="bg-gray-50 p-3 rounded">
                        <h4 className="font-medium mb-2 text-green-600">Convergent Evidence</h4>
                        <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                          {signal.convergent.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="flex items-center space-x-4 mt-2">
                      <span className="text-sm font-medium text-gray-500">Data Sources:</span>
                      <div className="flex items-center space-x-3">
                        {signal.sources.map((source, index) => (
                          <div 
                            key={index}
                            className="flex items-center bg-gray-100 px-2 py-1 rounded"
                            title={source.label}
                          >
                            {source.icon}
                            <span className="text-xs ml-1">{source.label}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {wizardStep === 2 && (
              <div className="space-y-6">
                <div className="space-y-4">
                  <div>
                    <label className="block font-medium mb-2">
                      Changes/Updates Since Last Visit
                    </label>
                    <textarea 
                      className="w-full p-3 border rounded-lg"
                      rows={4}
                      placeholder="Enter any relevant changes or updates..."
                      value={wizardAnswers.followup}
                      onChange={(e) => setWizardAnswers({
                        ...wizardAnswers,
                        followup: e.target.value
                      })}
                    />
                  </div>
                  <div>
                    <label className="block font-medium mb-2">
                      Areas to Focus On
                    </label>
                    <textarea 
                      className="w-full p-3 border rounded-lg"
                      rows={4}
                      placeholder="Enter key areas requiring attention..."
                      value={wizardAnswers.focus}
                      onChange={(e) => setWizardAnswers({
                        ...wizardAnswers,
                        focus: e.target.value
                      })}
                    />
                  </div>
                  <div>
                    <label className="block font-medium mb-2">
                      Additional Notes
                    </label>
                    <textarea 
                      className="w-full p-3 border rounded-lg"
                      rows={4}
                      placeholder="Any other relevant information..."
                      value={wizardAnswers.additional}
                      onChange={(e) => setWizardAnswers({
                        ...wizardAnswers,
                        additional: e.target.value
                      })}
                    />
                  </div>
                </div>
              </div>
            )}

            {wizardStep === 3 && (
              <div className="space-y-6">
                <div className="bg-blue-50 p-4 rounded-lg">
                  <div className="flex items-center mb-2">
                    <Settings className="text-blue-500 mr-2" />
                    <h3 className="font-semibold">Additional Report Configuration</h3>
                  </div>
                  <p className="text-sm text-gray-600">
                    Select additional elements to include in your report. These will be intelligently incorporated where relevant.
                  </p>
                </div>

                <div className="grid gap-4">
                  {additionalConfigs.map((config) => (
                    <div 
                      key={config.id}
                      className="flex items-start space-x-3 p-4 border rounded-lg hover:bg-gray-50"
                    >
                      <Checkbox 
                        id={config.id}
                        defaultChecked={config.defaultChecked}
                        className="mt-1"
                      />
                      <div className="flex-1">
                        <label 
                          htmlFor={config.id} 
                          className="flex items-center text-sm font-medium cursor-pointer"
                        >
                          {config.label}
                          {config.conditional && (
                            <span className="ml-2 text-xs text-gray-500">({config.conditional})</span>
                          )}
                        </label>
                        <p className="text-sm text-gray-500 mt-1">
                          {config.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="bg-yellow-50 p-4 rounded-lg mt-6">
                  <div className="flex items-center text-yellow-800">
                    <Info className="h-4 w-4 mr-2" />
                    <span className="text-sm font-medium">AI Assistance</span>
                  </div>
                  <p className="text-sm text-yellow-700 mt-1">
                    The AI will automatically determine the most appropriate placement and content for these additional elements based on the available data and clinical context.
                  </p>
                </div>
              </div>
            )}

            {wizardStep === 4 && (
              <div className="space-y-6">
                <div className="bg-blue-50 p-4 rounded-lg">
                  <div className="flex items-center mb-2">
                    <Info className="text-blue-500 mr-2" />
                    <h3 className="font-semibold">Report Configuration Review</h3>
                  </div>
                  <p className="text-sm text-gray-600">
                    Review and customize the following sections before generating the final report.
                  </p>
                </div>

                {/* Considerations and Recommendations Grid */}
                <div className="grid grid-cols-2 gap-4">
                  {/* Considerations Section */}
                  <div className="border rounded-lg p-4">
                    <h4 className="font-medium flex items-center mb-3">
                      <Brain className="h-4 w-4 mr-2 text-purple-500" />
                      Considerations
                    </h4>
                    <div className="space-y-2">
                      {[
                        'Environmental Factors',
                        'Cultural Considerations',
                        'Family Dynamics',
                        'Educational Setting',
                        'Social Context',
                        'Developmental Stage'
                      ].map((item, index) => (
                        <div key={index} className="flex items-start space-x-2">
                          <Checkbox id={`consideration-${index}`} defaultChecked />
                          <label 
                            htmlFor={`consideration-${index}`}
                            className="text-sm text-gray-700 leading-tight cursor-pointer"
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Recommendations Section */}
                  <div className="border rounded-lg p-4">
                    <h4 className="font-medium flex items-center mb-3">
                      <FileText className="h-4 w-4 mr-2 text-blue-500" />
                      Recommendations
                    </h4>
                    <div className="space-y-2">
                      {[
                        'Academic Accommodations',
                        'Behavioral Interventions',
                        'Therapeutic Support',
                        'Parent Training/Support',
                        'Medication Management',
                        'Follow-up Assessment',
                        'School-Based Services'
                      ].map((item, index) => (
                        <div key={index} className="flex items-start space-x-2">
                          <Checkbox id={`recommendation-${index}`} defaultChecked />
                          <label 
                            htmlFor={`recommendation-${index}`}
                            className="text-sm text-gray-700 leading-tight cursor-pointer"
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Strengths & Weaknesses Grid */}
                <div className="grid grid-cols-2 gap-4">
                  {/* Strengths Section */}
                  <div className="border rounded-lg p-4">
                    <h4 className="font-medium flex items-center mb-3">
                      <Star className="h-4 w-4 mr-2 text-yellow-500" />
                      Strengths
                    </h4>
                    <div className="space-y-2">
                      {[
                        'Cognitive Abilities',
                        'Social Skills',
                        'Creative Expression',
                        'Problem-Solving',
                        'Emotional Intelligence',
                        'Physical Capabilities'
                      ].map((item, index) => (
                        <div key={index} className="flex items-start space-x-2">
                          <Checkbox id={`strength-${index}`} defaultChecked />
                          <label 
                            htmlFor={`strength-${index}`}
                            className="text-sm text-gray-700 leading-tight cursor-pointer"
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Weaknesses Section */}
                  <div className="border rounded-lg p-4">
                    <h4 className="font-medium flex items-center mb-3">
                      <AlertTriangle className="h-4 w-4 mr-2 text-red-500" />
                      Areas for Development
                    </h4>
                    <div className="space-y-2">
                      {[
                        'Executive Functioning',
                        'Attention/Focus',
                        'Academic Skills',
                        'Emotional Regulation',
                        'Social Challenges',
                        'Behavioral Control'
                      ].map((item, index) => (
                        <div key={index} className="flex items-start space-x-2">
                          <Checkbox id={`weakness-${index}`} defaultChecked />
                          <label 
                            htmlFor={`weakness-${index}`}
                            className="text-sm text-gray-700 leading-tight cursor-pointer"
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Custom Content Section */}
                <div className="border rounded-lg p-4">
                  <h4 className="font-medium flex items-center mb-3">
                    <Settings className="h-4 w-4 mr-2 text-gray-500" />
                    Custom Content
                  </h4>
                  <p className="text-xs text-gray-500 mb-4">
                    These are headings you have used in other reports
                  </p>
                  <div className="grid grid-cols-2 gap-4">
                    {[
                      'Background Information',
                      'Current Functioning',
                      'Medical History',
                      'Previous Evaluations',
                      'Behavioral Observations',
                      'Test Results and Interpretation',
                      'Clinical Impressions',
                      'Impact on Daily Life',
                      'Treatment Progress',
                      'Future Considerations'
                    ].map((item, index) => (
                      <div key={index} className="flex items-start space-x-2">
                        <Checkbox id={`custom-${index}`} />
                        <label 
                          htmlFor={`custom-${index}`}
                          className="text-sm text-gray-700 leading-tight cursor-pointer"
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* AI Summary Note */}
                <div className="bg-yellow-50 p-4 rounded-lg">
                  <div className="flex items-center text-yellow-800">
                    <Brain className="h-4 w-4 mr-2" />
                    <span className="text-sm font-medium">AI-Assisted Organization</span>
                  </div>
                  <p className="text-sm text-yellow-700 mt-1">
                    The AI will organize these elements into a cohesive narrative, ensuring appropriate emphasis 
                    and context for each selected component. Sections will be arranged to tell a clear, 
                    professional story about the patient's presentation, needs, and recommended path forward.
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Wizard Footer */}
          <div className="border-t p-6 flex justify-between">
            <button
              onClick={handleBack}
              className={`px-4 py-2 border rounded ${wizardStep === 0 ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-50'}`}
              disabled={wizardStep === 0}
            >
              Back
            </button>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {wizardStep === steps.length - 1 ? 'Generate Report' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleInsertContent = (sectionId, position) => {
    // Open a menu with options:
    // - New text block
    // - Visualization
    // - AI-generated content
    // - Template
  };

  const handleContentChange = (sectionId, blockId, content) => {
    // Save changes to the content
  };

  const handleAddBlock = (sectionId, type) => {
    // Add a new block of specified type to the section
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Top Controls */}
      <div className="bg-white border-b p-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <select className="p-2 border rounded">
              <option value="">Select Template</option>
              <option value="template1">ADHD Assessment Template</option>
              <option value="template2">Learning Disability Template</option>
            </select>
            <button className="p-2 text-blue-500 hover:bg-blue-50 rounded-lg flex items-center">
              <Save size={18} className="mr-1" />
              Save as Template
            </button>
          </div>

          <div className="flex items-center space-x-2">
            {assessmentComplete && (
              <button 
                className="px-4 py-2 border rounded-lg hover:bg-gray-50 text-purple-500"
                onClick={() => setShowWizard(true)}
              >
                <Brain className="inline-block mr-2" size={18} />
                Report Wizard
              </button>
            )}
            <button 
              className="px-4 py-2 border rounded-lg hover:bg-gray-50 text-blue-500"
              onClick={() => setShowAIModal(true)}
            >
              <MessageSquare className="inline-block mr-2" size={18} />
              Ask AI
            </button>
            {isDraft ? (
              <button 
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center"
                onClick={() => setShowFinalizeModal(true)}
              >
                <Check size={18} className="mr-1" />
                Finalize
              </button>
            ) : (
              <div className="flex items-center text-gray-500 px-4 py-2">
                <Lock size={18} className="mr-1" />
                Finalized
              </div>
            )}
            <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
              Save Report
            </button>
          </div>
        </div>

        {/* Page Type Tabs */}
        <div className="mt-4 flex border-b">
          {pageTypes.map((page) => (
            <button
              key={page.id}
              className={`flex items-center px-4 py-2 border-b-2 ${
                activeTab === page.id 
                  ? 'border-blue-500 text-blue-600' 
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab(page.id)}
            >
              {page.icon}
              <span className="ml-2">{page.label}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Conditional rendering based on isDraft */}
      {isDraft ? (
        // Original editor view
        <div className="flex flex-1 overflow-hidden">
          {/* Left Sidebar with Components */}
          <div className="w-64 bg-white shadow-md p-4 overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">Components</h2>
            
            {/* Add Patient Snapshot Section */}
            <div className="mb-6">
              <button 
                onClick={() => handleComponentSelect('patient-snapshot')}
                className="w-full text-left p-3 bg-gray-50 rounded-lg hover:bg-blue-50 transition-colors group"
              >
                <div className="flex items-center justify-between mb-1">
                  <span className="text-sm font-semibold text-gray-500 flex items-center">
                    <User className="mr-1" size={14} />
                    PATIENT SNAPSHOT
                  </span>
                  <ChevronDown size={14} className="text-gray-400" />
                </div>
                <div className="text-xs text-gray-500">
                  Click to view patient overview
                </div>
              </button>
            </div>

            {/* AI Insights Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold text-gray-500 mb-2 flex items-center">
                <AlertCircle className="mr-1" size={14} />
                AI INSIGHTS
              </h3>
              <div className="space-y-2">
                {insights.map((insight) => (
                  <div 
                    key={insight.id}
                    className="p-3 bg-gray-50 rounded-lg cursor-move hover:bg-blue-50 transition-colors group relative"
                  >
                    <div className="flex items-center">
                      <div className="mr-3 text-blue-500">{insight.icon}</div>
                      <div>
                        <p className="font-medium">{insight.title}</p>
                        <p className="text-xs text-gray-500">{insight.description}</p>
                      </div>
                    </div>
                    {/* Hover tooltip */}
                    <div className="hidden group-hover:block absolute left-full ml-2 w-64 p-3 bg-white rounded-lg shadow-lg border z-10">
                      <div className="flex items-center justify-between mb-2">
                        <span className="font-semibold">{insight.title}</span>
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          insight.severity === 'high' ? 'bg-red-100 text-red-700' : 'bg-yellow-100 text-yellow-700'
                        }`}>
                          {insight.severity}
                        </span>
                      </div>
                      <p className="text-sm text-gray-600 mb-2">{insight.detail}</p>
                      <p className="text-xs text-gray-500">Confidence: {(insight.confidence * 100).toFixed(0)}%</p>
                      <div className="mt-2 text-sm text-blue-500 flex items-center">
                        <ArrowRight size={14} className="mr-1" />
                        Drag to add to report
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Content Blocks Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold text-gray-500 mb-2">CONTENT BLOCKS</h3>
              <div className="space-y-2">
                {contentBlocks.map((block) => (
                  <div 
                    key={block.id}
                    className="p-3 bg-gray-50 rounded-lg cursor-move hover:bg-blue-50 transition-colors"
                  >
                    <div className="flex items-center">
                      <div className="mr-3 text-blue-500">{block.icon}</div>
                      <div>
                        <p className="font-medium">{block.title}</p>
                        <p className="text-xs text-gray-500">{block.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Visualizations Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold text-gray-500 mb-2">VISUALIZATIONS</h3>
              <div className="space-y-2">
                {visualizations.map((viz) => (
                  <div 
                    key={viz.id}
                    className="p-3 bg-gray-50 rounded-lg cursor-move hover:bg-blue-50 transition-colors"
                  >
                    <div className="flex items-center">
                      <div className="mr-3 text-green-500">{viz.icon}</div>
                      <div>
                        <p className="font-medium">{viz.title}</p>
                        <p className="text-xs text-gray-500">{viz.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Layout Elements Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold text-gray-500 mb-2">LAYOUT ELEMENTS</h3>
              <div className="space-y-2">
                {layoutElements.map((element) => (
                  <div 
                    key={element.id}
                    className="p-3 bg-gray-50 rounded-lg cursor-move hover:bg-blue-50 transition-colors"
                  >
                    <div className="flex items-center">
                      <div className="mr-3 text-purple-500">{element.icon}</div>
                      <div>
                        <p className="font-medium">{element.title}</p>
                        <p className="text-xs text-gray-500">{element.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Main Editor Area */}
          <div className="flex-1 p-6 overflow-y-auto">
            <div className="bg-white rounded-lg shadow-md min-h-[calc(100vh-12rem)] p-8">
              {/* Drop Zone Banner */}
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-4 mb-6 flex flex-col items-center justify-center text-gray-400">
                <Plus size={48} className="mb-2" />
                <p className="text-lg">Drag and drop report components here</p>
                <p className="text-sm">Or right-click to use AI Assistant</p>
              </div>
              
              {/* Report Sections */}
              <div className="space-y-8" onContextMenu={handleContextMenu}>
                {/* Section 1 */}
                <div className="report-section group relative">
                  <h2 className="text-xl font-bold text-gray-800 mb-4 hover:bg-blue-50 rounded p-2">
                    1. Demographic Information and Referral Source
                  </h2>
                  <div className="pl-4 space-y-4">
                    {/* Empty section shows plus button */}
                    <div className="min-h-[100px] relative border border-dashed border-gray-200 rounded-lg hover:border-blue-300 transition-colors">
                      <div className="absolute inset-0 flex items-center justify-center text-gray-400 opacity-0 group-hover:opacity-100">
                        <button className="p-2 hover:bg-blue-50 rounded-full">
                          <Plus size={24} />
                        </button>
                      </div>
                      {/* Contenteditable div for text input */}
                      <div 
                        className="p-4 focus:outline-none focus:ring-2 focus:ring-blue-200 rounded-lg min-h-[100px]"
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        placeholder="Click to start typing or click + to insert content..."
                      />
                    </div>
                  </div>
                </div>

                {/* Example of a section with content */}
                <div className="report-section group relative">
                  <h2 className="text-xl font-bold text-gray-800 mb-4 hover:bg-blue-50 rounded p-2">
                    2. Presenting Concerns and Background
                  </h2>
                  <div className="pl-4 space-y-4">
                    {/* Text block with content */}
                    <div className="relative">
                      <div 
                        className="p-4 focus:outline-none focus:ring-2 focus:ring-blue-200 rounded-lg"
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                      >
                        Patient presents with concerns regarding attention and concentration...
                      </div>
                      {/* Floating toolbar appears on hover */}
                      <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        <div className="flex items-center space-x-2 bg-white shadow-lg rounded-lg p-2">
                          <button className="p-1 hover:bg-gray-100 rounded" title="Add content block">
                            <Plus size={16} />
                          </button>
                          <button className="p-1 hover:bg-gray-100 rounded" title="Add visualization">
                            <BarChart2 size={16} />
                          </button>
                          <button className="p-1 hover:bg-gray-100 rounded" title="Ask AI">
                            <Brain size={16} />
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    {/* Insert point appears between blocks */}
                    <div className="h-8 relative group">
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100">
                        <button className="p-1 hover:bg-blue-50 rounded-full">
                          <Plus size={16} className="text-blue-500" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Continue with other sections following the same pattern... */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Finalized view with document selection
        <FinalizedView />
      )}

      {/* Modals and Menus */}
      <GlobalAIAssistant />
      <ContextualAIMenu />
      {showFinalizeModal && <FinalizeModal />}
      {showShareModal && <ShareModal />}
      <PatientSnapshotModal 
        isOpen={showPatientSnapshot} 
        onClose={() => setShowPatientSnapshot(false)} 
      />
      <DiagnosisWizard 
        isOpen={showWizard}
        onClose={() => {
          setShowWizard(false);
          setWizardStep(0);
        }}
      />
      <AddDiagnosisDialog 
        isOpen={showAddDiagnosisDialog} 
        onClose={() => setShowAddDiagnosisDialog(false)} 
      />

      {/* Component Settings Panel */}
      {selectedComponent && (
        <div className="w-64 bg-white shadow-md p-4">
          <h2 className="text-lg font-bold mb-4">Component Settings</h2>
          {/* Component-specific settings would go here */}
        </div>
      )}
    </div>
  );
} 