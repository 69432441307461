import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";

function DashboardTile({ title, icon, children }) {
  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-gray-100 dark:bg-gray-700 p-0">
        <div className="flex items-center">
          <div className="flex-grow">
            <CardTitle className="text-lg p-4 text-left">{title}</CardTitle>
          </div>
          {icon && (
            <div className="bg-gray-200 dark:bg-gray-600 p-4 flex items-center justify-center h-full">
              {icon}
            </div>
          )}
        </div>
      </CardHeader>
      <CardContent className="bg-white dark:bg-gray-800 p-4">
        {children}
      </CardContent>
    </Card>
  );
}

export default DashboardTile;