import React, { useState } from 'react';
import {
  CreditCard,
  DollarSign,
  Mail,
  AlertCircle,
  Clock,
  ChevronDown,
  ChevronUp,
  FileText,
  Calendar,
  Plus,
  Shield,
  CheckCircle,
  XCircle,
  Printer,
  Send
} from 'lucide-react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent
} from '../components/ui/card';

const PatientBillingSection = () => {
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  // Sample data
  const billingOverview = {
    totalOwed: 850.00,
    totalPaid: 1250.00,
    lastPaymentDate: '2024-04-15',
    lastPaymentAmount: 150.00,
    nextPaymentDue: '2024-05-01'
  };

  const insuranceInfo = {
    provider: 'Blue Cross Blue Shield',
    status: 'Verified',
    lastVerified: '2024-04-23',
    copay: 25.00,
    coinsurance: '20%',
    deductible: {
      individual: 1500.00,
      met: 750.00
    }
  };

  const billingHistory = [
    {
      id: 1,
      date: '2024-04-15',
      type: 'Payment',
      amount: 150.00,
      method: 'Credit Card',
      status: 'Completed'
    },
    {
      id: 2,
      date: '2024-04-01',
      type: 'Insurance Payment',
      amount: 425.00,
      method: 'BCBS',
      status: 'Processed'
    },
    {
      id: 3,
      date: '2024-03-15',
      type: 'Statement',
      amount: 575.00,
      method: 'Email',
      status: 'Sent'
    }
  ];

  return (
    <div className="space-y-6">
      {/* Billing Overview Card */}
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle className="text-xl font-semibold">Billing Overview</CardTitle>
            <div className="space-x-2">
              <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center inline-flex">
                <Send size={16} className="mr-2" />
                Send Reminder
              </button>
              <button className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 flex items-center inline-flex">
                <DollarSign size={16} className="mr-2" />
                Take Payment
              </button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Financial Summary */}
            <div className="bg-gray-50 p-4 rounded">
              <h3 className="font-medium mb-4">Balance</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>Total Owed:</span>
                  <span className="font-medium text-red-600">
                    ${billingOverview.totalOwed.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Total Paid:</span>
                  <span className="font-medium text-green-600">
                    ${billingOverview.totalPaid.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Last Payment:</span>
                  <span className="font-medium">
                    ${billingOverview.lastPaymentAmount.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Next Due:</span>
                  <span className="font-medium">{billingOverview.nextPaymentDue}</span>
                </div>
              </div>
            </div>

            {/* Insurance Status */}
            <div className="bg-gray-50 p-4 rounded">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">Insurance Status</h3>
                <button className="text-blue-600 hover:text-blue-700 text-sm">
                  Verify Now
                </button>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>Provider:</span>
                  <span className="font-medium">{insuranceInfo.provider}</span>
                </div>
                <div className="flex justify-between">
                  <span>Status:</span>
                  <span className="flex items-center text-green-600">
                    <CheckCircle size={16} className="mr-1" />
                    {insuranceInfo.status}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Copay:</span>
                  <span className="font-medium">${insuranceInfo.copay}</span>
                </div>
                <div className="flex justify-between">
                  <span>Deductible Met:</span>
                  <span className="font-medium">
                    ${insuranceInfo.deductible.met} / ${insuranceInfo.deductible.individual}
                  </span>
                </div>
              </div>
            </div>

            {/* Payment Actions */}
            <div className="bg-gray-50 p-4 rounded">
              <h3 className="font-medium mb-4">Payment Method</h3>
              <div className="space-y-4">
                <select 
                  className="w-full border rounded p-2"
                  onChange={(e) => setSelectedPaymentType(e.target.value)}
                  value={selectedPaymentType || ''}
                >
                  <option value="">Select Payment Type</option>
                  <option value="credit">Credit Card</option>
                  <option value="cash">Cash</option>
                  <option value="insurance">Insurance</option>
                </select>
                {selectedPaymentType === 'credit' && (
                  <button className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
                    Enter Card Details
                  </button>
                )}
                {selectedPaymentType === 'cash' && (
                  <button className="w-full bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700">
                    Record Cash Payment
                  </button>
                )}
                {selectedPaymentType === 'insurance' && (
                  <button className="w-full bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700">
                    Submit to Insurance
                  </button>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Billing History Card */}
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle className="text-xl font-semibold">Billing History</CardTitle>
            <div className="space-x-2">
              <button className="text-blue-600 hover:text-blue-700 flex items-center">
                <Printer size={16} className="mr-1" />
                Print Statement
              </button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {billingHistory.map((transaction) => (
              <div 
                key={transaction.id} 
                className="flex justify-between items-center p-3 hover:bg-gray-50 rounded"
              >
                <div className="flex items-center space-x-4">
                  {transaction.type === 'Payment' && (
                    <DollarSign size={20} className="text-green-500" />
                  )}
                  {transaction.type === 'Insurance Payment' && (
                    <Shield size={20} className="text-blue-500" />
                  )}
                  {transaction.type === 'Statement' && (
                    <FileText size={20} className="text-gray-500" />
                  )}
                  <div>
                    <p className="font-medium">{transaction.type}</p>
                    <p className="text-sm text-gray-600">
                      {transaction.date} - {transaction.method}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <p className={`font-medium ${
                    transaction.type.includes('Payment') ? 'text-green-600' : ''
                  }`}>
                    ${transaction.amount.toFixed(2)}
                  </p>
                  <p className="text-sm text-gray-600">{transaction.status}</p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PatientBillingSection;
