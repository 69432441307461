import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Search, User, Users, Clock, Star, Phone, Mail, Plus, Filter, List, Grid, CheckCircle, XCircle, Contact } from "lucide-react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import QuickLaunchBar from '../components/QuickLaunchBar';

// Updated mock data
const mockData = [
  { id: 1, name: "Tyler Smith", type: "Patient", lastContact: "2023-05-15", status: "Active" },
  { id: 2, name: "Emma Johnson", type: "Patient", lastContact: "2023-05-14", status: "Inactive" },
  { id: 3, name: "Dr. Sarah Lee", type: "Clinician", lastContact: "2023-05-13", specialty: "Pediatrics", associatedPatients: ["Tyler Smith", "John Doe"] },
  { id: 4, name: "John Doe", type: "Patient", lastContact: "2023-05-12", status: "Active" },
  { id: 5, name: "Ms. Thompson", type: "Stakeholder", lastContact: "2023-05-11", role: "Teacher", associatedPatients: ["Emma Johnson"] },
];

const RecentContact = ({ contact }) => (
  <Card className="mb-2">
    <CardContent className="p-4 flex items-center justify-between">
      <div className="flex items-center">
        <User className="h-8 w-8 text-gray-400 mr-2" />
        <div>
          <h3 className="font-semibold">{contact.name}</h3>
          <p className="text-sm text-gray-500">{contact.type}</p>
        </div>
      </div>
      <div className="flex space-x-2">
        <Button variant="ghost" size="sm"><Phone className="h-4 w-4" /></Button>
        <Button variant="ghost" size="sm"><Mail className="h-4 w-4" /></Button>
        <Button variant="ghost" size="sm"><Star className="h-4 w-4" /></Button>
      </div>
    </CardContent>
  </Card>
);

const ContactInfoModal = ({ isOpen, onClose, contact }) => {
  if (!contact) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{contact.name}</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <p><strong>Type:</strong> {contact.type}</p>
          <p><strong>Last Contact:</strong> {contact.lastContact}</p>
          {contact.status && <p><strong>Status:</strong> {contact.status}</p>}
          {contact.specialty && <p><strong>Specialty:</strong> {contact.specialty}</p>}
          {contact.role && <p><strong>Role:</strong> {contact.role}</p>}
          {contact.associatedPatients && (
            <p><strong>Associated Patients:</strong> {contact.associatedPatients.join(', ')}</p>
          )}
          {/* Add more contact details as needed */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const ContactCard = ({ contact }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <Card className="mb-4">
      <CardContent className="p-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <User className="h-8 w-8 text-gray-400 mr-2" />
            <div>
              <Link to={`/patient-record/${contact.id}`} className="font-semibold hover:underline">
                {contact.name}
              </Link>
              <p className="text-sm text-gray-500">{contact.type}</p>
            </div>
          </div>
          <div className="flex space-x-2">
            <Button variant="ghost" size="sm"><Phone className="h-4 w-4" /></Button>
            <Button variant="ghost" size="sm"><Mail className="h-4 w-4" /></Button>
            <Button variant="ghost" size="sm"><Star className="h-4 w-4" /></Button>
            <Button variant="ghost" size="sm" onClick={() => setIsInfoModalOpen(true)}>
              <Contact className="h-4 w-4" />
            </Button>
          </div>
        </div>
        <div className="text-sm">
          <p>Last Contact: {contact.lastContact}</p>
          {contact.status && (
            <p className="flex items-center">
              Status: 
              {contact.status === 'Active' ? (
                <span className="text-green-500 flex items-center ml-1">
                  <CheckCircle className="h-4 w-4 mr-1" /> Active
                </span>
              ) : (
                <span className="text-red-500 flex items-center ml-1">
                  <XCircle className="h-4 w-4 mr-1" /> Inactive
                </span>
              )}
            </p>
          )}
          {contact.specialty && <p>Specialty: {contact.specialty}</p>}
          {contact.role && <p>Role: {contact.role}</p>}
          {contact.associatedPatients && (
            <p>Associated Patients: {contact.associatedPatients.join(', ')}</p>
          )}
        </div>
      </CardContent>
      <ContactInfoModal 
        isOpen={isInfoModalOpen} 
        onClose={() => setIsInfoModalOpen(false)} 
        contact={contact} 
      />
    </Card>
  );
};

function PatientRecordsCRM() {
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [activeTab, setActiveTab] = useState('all');

  const filteredContacts = mockData.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (activeTab === 'all' || contact.type.toLowerCase() === activeTab)
  );

  return (
    <div className="container mx-auto p-4">
      <QuickLaunchBar />
      
      <div className="mb-6">
        <h1 className="text-3xl font-bold mb-4">Patient Records CRM</h1>
        <div className="flex space-x-4 mb-4">
          <div className="flex-grow">
            <Input
              type="text"
              placeholder="Search patients, stakeholders, or clinicians..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full"
              icon={<Search className="h-5 w-5 text-gray-400" />}
            />
          </div>
          <Button>
            <Plus className="h-5 w-5 mr-2" /> Add New
          </Button>
          <Button variant="outline">
            <Filter className="h-5 w-5 mr-2" /> Filter
          </Button>
          <Button variant="outline" onClick={() => setViewMode('list')}>
            <List className="h-5 w-5" />
          </Button>
          <Button variant="outline" onClick={() => setViewMode('grid')}>
            <Grid className="h-5 w-5" />
          </Button>
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-3/4">
          <Tabs defaultValue="all" className="w-full mb-4">
            <TabsList>
              <TabsTrigger value="all" onClick={() => setActiveTab('all')}>All</TabsTrigger>
              <TabsTrigger value="patient" onClick={() => setActiveTab('patient')}>Patients</TabsTrigger>
              <TabsTrigger value="clinician" onClick={() => setActiveTab('clinician')}>Clinicians</TabsTrigger>
              <TabsTrigger value="stakeholder" onClick={() => setActiveTab('stakeholder')}>Stakeholders</TabsTrigger>
            </TabsList>
          </Tabs>
          <div className={viewMode === 'grid' ? 'grid grid-cols-2 gap-4' : ''}>
            {filteredContacts.map(contact => (
              <ContactCard key={contact.id} contact={contact} />
            ))}
          </div>
        </div>
        
        <div className="w-1/4">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Clock className="h-5 w-5 mr-2" /> Recent Contacts
              </CardTitle>
            </CardHeader>
            <CardContent>
              {mockData.slice(0, 5).map(contact => (
                <RecentContact key={contact.id} contact={contact} />
              ))}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PatientRecordsCRM;
